import React from "react";
import { useOnline } from "../../../core/hooks/useOnline";
import styled from "styled-components";
import { Button } from "@coworker/components";
import { Trans, useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { usePickUpTask } from "../../../hooks/TaskActions/usePickUpTask";
import {
  useAcceptPQReport,
  useCompleteTask,
} from "../../../hooks/TaskActions/useCompleteTask";
import { usePickTask } from "../../../hooks/TaskActions/usePickTask";
import { useInputPopup } from "../../InputPopup";
import { useRejectPQReport } from "../../../hooks/TaskActions/useCloseTask";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { Reasons } from "../../RoomSettings/constants";
import { useUpdateArticle } from "../../RoomSettings/hooks/useUpdateArticle";
import { mapArticleInfoToArticle } from "../../RoomSettings/types/mappers";
import trackerHelper from "../../../helpers/tracker";
import useFlag, { FLAGS } from "../../../hooks/useFlag";
import { useDeleteArticlesByIds } from "../../RoomSettings/hooks/useDeleteArticlesByIds";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { fetchItemsInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemsInfo";

const StyledButton = styled(Button)`
  margin-top: 18px;
`;

function PickUpTaskComponent({ task, taskId }) {
  const { isOnline } = useOnline();
  const { call: pickUpTask } = usePickUpTask(task, taskId, {});

  return (
    <Button
      onClick={pickUpTask}
      primary
      dark
      fullWidth
      text={
        isOnline ? <Trans>pickUpString</Trans> : <Trans>goOnline2String</Trans>
      }
      disabled={!isOnline}
      data-testid="pickupButton"
    />
  );
}

function CompleteTaskComponent({ task, taskId }) {
  const { push } = useWorkspacesAction();
  const { isOnline } = useOnline();
  const { t } = useTranslation();
  const { toggleModal } = React.useContext(ModalContext);
  const isMFAQTask = task.task_type === taskTypeOptions.MFAQ_FOLLOW_UP;
  const isRoomSettingsTask = task.task_type === taskTypeOptions.ROOM_SETTINGS;
  const isReasonRemoveArticle = task.reason === Reasons.REMOVE_ARTICLE;
  const isReasonReplaceArticle = task.reason === Reasons.REPLACE_PRODUCT;
  const requireProofOfCompletion = useFlag(FLAGS.REQUIRE_PROOF_OF_COMPLETION);

  const deleteArticlesMutation = useDeleteArticlesByIds(task.room_id);
  const updateArticle = useUpdateArticle(task.article_id);

  const { call: completeTask } = useCompleteTask(task, taskId, {
    undo: true,
    afterUndo: () =>
      toggleModal({
        type: ModalType.TaskDetail,
        payload: { taskId: taskId },
      }),
  });

  const deleteArticle = () => {
    try {
      deleteArticlesMutation.mutate([task.article_id]);
    } catch (error) {
      alert(t("searchErrorString"));
    }
    push(`/roomsettings/${task.room_id}`);
  };

  const replaceArticle = async () => {
    const articlesInfo = await fetchItemsInfo(task.new_product_article_id);

    if (articlesInfo.length > 0) {
      try {
        await updateArticle.mutateAsync({
          ...mapArticleInfoToArticle(articlesInfo[0]),
        });
      } catch (error) {
        console.log("Error while updating article: ", error);
      }
    }
  };

  return (
    <Button
      onClick={async () => {
        await completeTask();
        trackerHelper.trackTaskCompletion("complete", false);

        // Prevents displaying of question with obsolete data as task
        // completion takes few seconds to be processed.
        // Should redirect user back to his open tasks.

        toggleModal({ type: ModalType.TaskDetail });

        if (isRoomSettingsTask && isReasonRemoveArticle) {
          deleteArticle();
        }

        if (isRoomSettingsTask && isReasonReplaceArticle) {
          replaceArticle();
        }
      }}
      primary
      dark
      fullWidth
      text={
        isOnline ? (
          <Trans>complete2String</Trans>
        ) : (
          <Trans>goOnline3String</Trans>
        )
      }
      disabled={
        !isOnline ||
        (isMFAQTask && !task?.finished_summary) ||
        !isValidTestbuyTask(task) ||
        !isValidRoomSettingsTask(task, requireProofOfCompletion)
      }
      data-testid="confirmButton2"
    />
  );
}

function isValidTestbuyTask(task) {
  if (task.task_type !== taskTypeOptions.TESTBUY_FOLLOW_UP) return true;
  return task.isDone;
}

function isValidRoomSettingsTask(task, requireProofOfCompletion) {
  const proofOfCompletion = task.isDone
    ? task.isDone
    : requireProofOfCompletion
    ? task.proof_of_completion
    : true;

  if (task.task_type !== taskTypeOptions.ROOM_SETTINGS) return true;
  if (!proofOfCompletion) return false;
  return true;
}

function AcceptPQReportComponent({ task, taskId }) {
  const { pop } = useWorkspacesAction();
  const acceptPQRTask = useAcceptPQReport(task, taskId);
  const { isOnline } = useOnline();
  const { getInput } = useInputPopup();
  return (
    <Button
      onClick={() =>
        getInput("completeWithText", {
          titleTranslationKey: "acceptIssueString",
          actionTranslationKey: "acceptIssueString",
          placeholderTranslationKey: "feedbackToSenderString",
        }).then((text) => {
          if (text) acceptPQRTask(text).then(pop);
        })
      }
      primary
      dark
      fullWidth
      text={
        isOnline ? (
          <Trans>acceptActionString</Trans>
        ) : (
          <Trans>goOnline3String</Trans>
        )
      }
      disabled={!isOnline}
      data-testid="closeButton2"
    />
  );
}

function RejectPQReportComponent({ task, taskId }) {
  const { pop } = useWorkspacesAction();
  const rejectPQRtask = useRejectPQReport(task, taskId);
  const { isOnline } = useOnline();
  const { getInput } = useInputPopup();
  return (
    <Button
      onClick={() =>
        getInput("completeWithText", {
          titleTranslationKey: "closeIssueString",
          actionTranslationKey: "closeIssueString",
          placeholderTranslationKey: "feedbackToSenderString",
        }).then((text) => {
          if (text) rejectPQRtask(text).then(pop);
        })
      }
      tertiary
      light
      fullWidth
      topMargin={18}
      text={
        isOnline ? <Trans>declineString</Trans> : <Trans>goOnline3String</Trans>
      }
      disabled={!isOnline}
      data-testid="closeButton2"
    />
  );
}

function CompletePickingComponent({ task, taskId }) {
  const { pop } = useWorkspacesAction();
  const { isOnline } = useOnline();
  const { toggleModal } = React.useContext(ModalContext);

  const { call: pickTask } = usePickTask(task, taskId, {
    undo: true,
    afterUndo: () =>
      toggleModal({
        type: ModalType.TaskDetail,
        payload: { taskId: taskId },
      }),
  });

  return (
    <Button
      onClick={() =>
        pickTask().then((handoff) => {
          if (handoff) pop();
        })
      }
      primary
      dark
      fullWidth
      text={
        isOnline ? (
          <Trans>completePickingButtonString</Trans>
        ) : (
          <Trans>goOnline3String</Trans>
        )
      }
      disabled={!isOnline}
      data-testid="completePickingButton"
    />
  );
}

const generateWithChanges =
  (title, picking) =>
  ({ taskId }) => {
    const { push } = useWorkspacesAction();
    const { modalState, toggleModal } = React.useContext(ModalContext);

    return (
      <StyledButton
        customMargin
        data-testid="completeWithChangesButton"
        onClick={() => {
          if (modalState.isTaskDetailSheetOpen) {
            toggleModal({ type: ModalType.TaskDetail });
          }
          push(`/task/${taskId}/changes`, { picking });
          trackerHelper.trackTaskCompletion("complete_with_changes", true);
        }}
        secondary
        dark
        fullWidth
        text={title}
      />
    );
  };

export {
  PickUpTaskComponent,
  CompleteTaskComponent,
  CompletePickingComponent,
  RejectPQReportComponent,
  AcceptPQReportComponent,
  generateWithChanges,
};
