import React from "react";
import styled from "styled-components";
import Button from "@ingka/button";
import trashcan from "@ingka/ssr-icon/paths/trash-can";
import QuantityStepper from "@ingka/quantity-stepper";
import { useTranslation } from "@coworker/locales";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ActionRowWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  margin-left: 68px;
`;

interface ArticleListItemActionRowProps {
  nbrArticles: number;
  onQuantityChange: (value: number) => void;
  selectArticleIdsMode: boolean;
  onDeleteArticle: () => void;
  doUseQuantityStepper?: boolean;
}

export const ArticleListItemActionRow: React.FC<
  ArticleListItemActionRowProps
> = ({
  nbrArticles,
  onQuantityChange,
  selectArticleIdsMode,
  onDeleteArticle,
  doUseQuantityStepper = true,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation(); // This stops the click event from propagating to parent elements
  };
  const { t } = useTranslation();

  if (selectArticleIdsMode) {
    return <></>;
  }

  return (
    <ActionRowWrapper
      onClick={(e) => {
        handleClick(e);
      }}
    >
      {doUseQuantityStepper ? (
        <QuantityStepper
          small={true}
          ariaDescribedBy="articleQuantityStepper"
          ariaDescribedById="articleQuantityStepper"
          minVal={1}
          maxVal={99}
          defaultValue={nbrArticles}
          onQuantityChange={(value) => onQuantityChange(Number(value))}
        />
      ) : (
        <Button
          size="small"
          text={t("editQuantityString")}
          onClick={() => onQuantityChange(nbrArticles)}
        />
      )}
      {!selectArticleIdsMode && (
        <>
          <ButtonWrapper>
            <Button
              size="small"
              iconOnly={true}
              ssrIcon={trashcan}
              onClick={onDeleteArticle}
            />
          </ButtonWrapper>
        </>
      )}
    </ActionRowWrapper>
  );
};
