import React from "react";
import { useParams } from "react-router";
import { useRoomById } from "../hooks/useRoomById";
import { useTranslation } from "@coworker/locales";
import { RoomsetSettingsList } from "../Organisms/RoomsetSettingsList";
import { SettingsViewSkeleton } from "../Skeletons/SettingsViewSkeleton";
import { TitleHeader } from "../../TestBuy/CommonComponents/TitleHeader/TitleHeader";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

export function SettingsView() {
  const { t } = useTranslation();
  const { roomId } = useParams();
  const { data: room, isLoading } = useRoomById(roomId || "");

  const roomHeader = `${room?.name} ${t("roomSettingsString")}`;

  if (isLoading)
    return (
      <>
        <TitleHeader title={roomHeader} />
        <SettingsViewSkeleton />
      </>
    );

  return (
    <>
      {room && (
        <FullScreenPopup
          appBarConfig={{
            title: roomHeader,
            actions: [],
          }}
          noPadding
        >
          <RoomsetSettingsList room={room} />
        </FullScreenPopup>
      )}
    </>
  );
}
