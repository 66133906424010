import React from "react";
import styled from "styled-components";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import tracker from "../../helpers/tracker";
import { useCleanProductTitle } from "../../hooks/useProductsMap";
import { ProductNumber } from "@coworker/reusable/components/ProductNumber";

const Preview = styled.div`
  font-size: 12px;
  font-weight: bold;
  min-width: 76px;
  max-width: 76px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 13px;
  margin: 24px 0;
`;

const Image = styled.img`
  width: 100%;
  min-height: 76px;
`;

const ProductTitle = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  min-height: 16px;
`;

const SkeletonImage = styled.div`
  min-height: 76px;
  min-width: 76px;
  background-color: var(--grey200);
  border-radius: 5px;
`;
const SkeletonTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
  background-color: var(--grey200);
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
  min-height: 16px;
`;

const SkeletonRow = styled.div`
  height: 16px;
  width: 35px;
  background-color: var(--grey200);
  border-radius: 5px;
`;

export function InsightsProduct({
  fullId,
  title,
  image,
  value,
  onChangeInsightsParams = () => null,
  type = "turnover",
  order = "most",
  loading,
}) {
  const cleanTitle = useCleanProductTitle(fullId, title);
  const { push } = useWorkspacesAction();
  const onClick = () => {
    tracker.insights.store.trackSeeProduct(order, type, fullId);
    onChangeInsightsParams({
      order_by: type,
      order_by_asc: order === "least",
    });
    push(`/insights/product/${fullId}`);
  };

  return cleanTitle && value && !loading ? (
    <Preview data-testid="insightsProduct" onClick={onClick}>
      <Image src={image} />
      <ProductTitle>{cleanTitle}</ProductTitle>
      <ProductNumber fullId={fullId} />
      <div>{value}</div>
    </Preview>
  ) : (
    <Preview data-testid="productLoader">
      <SkeletonImage />
      <SkeletonTitle />
      <SkeletonRow />
    </Preview>
  );
}
