import React from "react";
import { RoomsViewMobile } from "./RoomsViewMobile";
import { FixaWindow } from "@coworker/reusable";
import { RoomsViewDesktop } from "./RoomsViewDesktop";
import { DesktopOnly } from "@coworker/apprestructured/src/shared/components/DesktopOnly";
import { MobileOnly } from "@coworker/apprestructured/src/shared/components/MobileOnly";

export function RoomsView() {
  if ((window as FixaWindow).editableRoomSettingsSettings) {
    return (
      <>
        <MobileOnly>
          <RoomsViewMobile />
        </MobileOnly>
        <DesktopOnly>
          <RoomsViewDesktop />
        </DesktopOnly>
      </>
    );
  } else {
    return <RoomsViewMobile />;
  }
}
