import React from "react";
import Skeleton from "@ingka/skeleton";

import Home from "./Home";
import styles from "./Linkbar.module.css";

function LinkbarLoading({
  "data-testid": dataTestId,
}: {
  "data-testid"?: string | undefined;
}) {
  return (
    <div data-testid={dataTestId} className={styles["linkbar-loading"]}>
      <Home />
      <Skeleton />
    </div>
  );
}

export default LinkbarLoading;
