import ListView, { ListViewItem } from "@ingka/list-view";
import React from "react";
import { User } from "@coworker/types";
import styles from "./users-list-view.module.css";
import { useTranslation } from "@coworker/locales";
import { SimpleToast } from "../../../SimpleToast/SimpleToast";

interface UsersListViewProps {
  users: User[];
  id: string;
}
export const UsersListView: React.FC<UsersListViewProps> = ({ users, id }) => {
  const [toastVisible, setToastVisible] = React.useState(false);
  const [copiedEmail, setCopiedEmail] = React.useState("");
  const { t } = useTranslation();
  const copyUserEmail = (email: string) => {
    navigator.clipboard.writeText(email);
    setCopiedEmail(email);
    setToastVisible(true);
  };
  return (
    <div>
      <SimpleToast
        text={`${t("copiedString")} ${copiedEmail}!`}
        isOpen={toastVisible}
        onCloseRequest={() => {
          setToastVisible(false);
        }}
        ariaLabelCloseBtn="Dismiss notification"
      />
      <ListView
        key={id}
        id={id}
        className={styles["users-list-view"]}
        size="medium"
      >
        {users.map((user: User, index: number) => {
          return (
            <ListViewItem
              className={styles["users-list-view-item"] || ""}
              name={user?.id ?? `user_${index}`}
              control={"navigational"}
              controlIcon={"copy"}
              onClick={() => copyUserEmail(user?.email)}
              title={user?.name}
              description={user?.email}
            ></ListViewItem>
          );
        })}
      </ListView>
    </div>
  );
};
