import React from "react";
import styled, { css } from "styled-components";
import { useWorkspacesAction } from "../hooks/useWorkspaces";

export function WorkspaceLink(
  { to, children, className, onClick, testId, style },
  ref
) {
  const { push } = useWorkspacesAction();
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      data-testid={testId}
      onClick={(e) => {
        to && push(to);
        onClick && onClick(e);
      }}
      ref={ref}
      style={style}
      className={className}
    >
      {children}
    </a>
  );
}

export const Link = React.forwardRef(WorkspaceLink);
export const BlueLink = styled(Link)`
  display: block;
  margin-top: 20px;
  color: var(--related-blue);
  ${({ addon }) =>
    addon &&
    css`
      color: var(--blue-medium);
    `}
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  display: flex;
  align-items: center;
  & > span {
    margin-right: 10px;
  }
  ${({ css }) => css}
`;
