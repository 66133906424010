import { getFocusTile } from "../../hooks/API/core.service";
import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "../../constants/clientTime";
import type { FocusTile } from "@coworker/types/lib/focusTile";

interface Result {
  focusTiles: FocusTile[];
}

export function useFocusTile(userLanguage: string) {
  const result = useQuery<Result>({
    queryKey: ["focusTile"],
    queryFn: async () => await getFocusTile(userLanguage),
    staleTime: 1 * INTERVAL.HOUR,
    enabled: !!userLanguage,
  });
  return result?.data?.focusTiles[0];
}
