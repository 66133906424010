import React from "react";
import styled, { AnyStyledComponent } from "styled-components";
import { ReactComponent as OpenIcon } from "../../assets/svg/arrow-small-forward.svg";
import { Link } from "../../components/Link";
const Image = styled.img`
  width: 73px;
  height: 73px;
  min-width: 73px;
  min-height: 73px;
  margin-right: 20px;
`;

const Content = styled(Link as AnyStyledComponent)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
  background: var(--white);
  height: 110px;
  padding: 0px 36px 0px 28px;
  border-top: 1px solid var(--grey150);
`;

const TextRows = styled.div`
  min-height: 43px;
`;

const MainContent = styled.div`
  display: flex;
  align-items: center;
`;

const WrappedOpenIcon = styled(OpenIcon)`
  margin-right: -8px;
`;

interface PreviewLinkProps {
  image?: string;
  children: React.ReactNode;
  to: string;
}

export function PreviewLink({ image, children }: PreviewLinkProps) {
  return (
    <Content>
      <MainContent data-testid="productInformation">
        {image && <Image src={image} />}
        <TextRows>{children}</TextRows>
      </MainContent>
      <WrappedOpenIcon />
    </Content>
  );
}

const ContentHeader: React.FC = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
  background: var(--white);
  height: 110px;
  padding: 0px 36px 0px 28px;
  border-top: 1px solid var(--grey150);
`;

interface PreviewHeaderProps {
  image?: string;
  children: React.ReactNode;
}

export function PreviewHeader({ image, children }: PreviewHeaderProps) {
  return (
    <ContentHeader>
      <MainContent data-testid="productInformation">
        {image && <Image src={image} />}
        <TextRows>{children}</TextRows>
      </MainContent>
    </ContentHeader>
  );
}

const PreviewContainer = styled.div`
  overflow: visible;
`;

const SectionBar = styled.div`
  min-height: 60px;
  background: var(--grey100);
  font-size: 18px;
  color: var(--grey900);
  line-height: 60px;
  padding: 16px 24px 0 24px;
  font-weight: 700;
`;

const SectionBottom = styled.div`
  min-height: 24px;
  background: var(--grey100);
`;

interface PreviewSectionProps {
  sectionTitle: React.ReactNode;
  children: React.ReactNode;
}

export function PreviewSection({
  sectionTitle,
  children,
}: PreviewSectionProps) {
  return (
    <>
      <SectionBar>{sectionTitle}</SectionBar>
      <PreviewContainer>{children}</PreviewContainer>
      <SectionBottom />
    </>
  );
}
