import React from "react";
import { RoomListItemDesktop } from "../Molecules/RoomListItemDesktop";
import { RoomListItemOngoingTasksDesktop } from "../Molecules/RoomListItemOngoingTasksDesktop";
import { TaskLinks } from "../types/taskLink";
import { AlertResponse, Room, Rooms } from "../types/room";
import { RoomListItemSkeletonDesktop } from "../Molecules/RoomListItemSkeletonDesktop";
import { RoomListDesktop } from "../styles/styles";

interface RoomsViewListDesktopProps {
  rooms: Rooms | undefined;
  alerts: AlertResponse[] | undefined;
  taskLinks: TaskLinks;
  onSaveRoom: (room: Room) => void;
  onOngoingTasksClick: () => void;
  showOngoingTasks?: boolean;
}
export function RoomsViewRoomsListDesktop({
  rooms,
  alerts,
  taskLinks,
  onSaveRoom,
  onOngoingTasksClick,
  showOngoingTasks = true,
}: RoomsViewListDesktopProps) {
  if (!rooms) {
    return (
      <RoomListDesktop id="room-view-list">
        <RoomListItemSkeletonDesktop key="rooms-view-list-0" />
        <RoomListItemSkeletonDesktop key="rooms-view-list-1" />
        <RoomListItemSkeletonDesktop key="rooms-view-list-2" />
        <RoomListItemSkeletonDesktop key="rooms-view-list-3" />
      </RoomListDesktop>
    );
  } else {
    return (
      <RoomListDesktop id="room-view-list">
        {showOngoingTasks && (
          <RoomListItemOngoingTasksDesktop
            key="rooms-view-list-0"
            tasks={taskLinks}
            onClick={onOngoingTasksClick}
          />
        )}
        {rooms &&
          rooms
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((room, idx) => (
              <RoomListItemDesktop
                key={`rooms-view-list-${idx + 1}`}
                room={room}
                alert={alerts?.find(({ roomId }) => roomId === room.id)}
                tasks={taskLinks.filter(({ roomId }) => roomId === room.id)}
                onSaveRoom={onSaveRoom}
              />
            ))}
      </RoomListDesktop>
    );
  }
}
