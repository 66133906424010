import React from "react";
import EmptyScreen from "../EmptyScreen";
import { Trans } from "@coworker/locales";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

const TaskError = () => {
  return (
    <FullScreenPopup
      appBarConfig={{
        title: <Trans>taskErrorHeadingTitle</Trans>,
      }}
      noPadding
    >
      <EmptyScreen
        title={<Trans>taskErrorContentTitle</Trans>}
        subTitle={<Trans>taskErrorContentSubTitle</Trans>}
      />
    </FullScreenPopup>
  );
};

export default TaskError;
