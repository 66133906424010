import React from "react";
import styled from "styled-components";
import { useInputPopup } from "../../InputPopup";
import { ReactComponent as WidgetBoxes } from "../../../assets/svg/article-card-skeleton.svg";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import { CustomTestBuyProduct, TestBuyProduct } from "@coworker/types";
import {
  callInternal,
  TESTBUY_CUSTOM_ARTICLE,
} from "../../../hooks/API/useCallInternal";
import { SkapaPlusButton } from "../CommonComponents/Skapa/SkapaButtons";
import { FixaWindow } from "@coworker/reusable";
import { postToTestbuyService } from "../../../hooks/API/testbuy.service";
import { useMyStore } from "../../../hooks/useMyStore";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import { SimpleToast } from "@coworker/apprestructured/src/shared/components/SimpleToast/SimpleToast";

interface CustomArticleWidgetProps {
  articles: TestBuyProduct[];
  setArticles: React.Dispatch<React.SetStateAction<TestBuyProduct[]>>;
}

interface NewArticleProps {
  product: string;
  supplierNumber: string;
  type: string;
}

interface Response {
  data?: CustomTestBuyProduct;
}

const WidgetRowRapper = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 1px var(--grey200);
`;

const StyledWidgetBoxes = styled(WidgetBoxes)`
  position: absolute;
  width: 280px;
  height: 100px;
  left: 20px;
  top: 0px;
`;

export const CustomArticleWidget = ({
  articles,
  setArticles,
}: CustomArticleWidgetProps) => {
  const { getInput } = useInputPopup();
  const storeId: string = useStoreId();
  const allArticleNumbers = articles.map((article) => article?.shortId);
  const [toastVisible, setToastVisible] = React.useState(false);
  const store = useMyStore();
  const storeConfiguration = store?.configuration || {};
  const [userLanguage] = useUserPreference(
    profilePreferences.LANGUAGE,
    storeConfiguration?.locale?.primary_locale
  );

  // const locale = useLanguage();
  const disableFirebase = (window as FixaWindow).callTestbuyService;
  const sprTestbuy = (window as FixaWindow).sprTestbuy;
  let res: Response = {};

  return (
    <WidgetRowRapper>
      <StyledWidgetBoxes />
      <SkapaPlusButton
        onClick={async () => {
          const newArticle: NewArticleProps = await getInput("scansearch", {
            start: Date.now(),
            withHistory: false,
            hideSPR: !!sprTestbuy ? false : true,
            forbiddenArticles: allArticleNumbers,
            onForbiddenArticle: () => {
              setToastVisible(true);
            },
          });
          if (newArticle) {
            if (disableFirebase) {
              await postToTestbuyService(
                "testbuy/custom_article",
                {
                  articleId: newArticle.product,
                  storeId,
                  //TODO: Will remove the hardcoded locale value when useLanguage is working in China environment
                  locale: userLanguage,
                },
                (data: CustomTestBuyProduct) => (res.data = data)
              );
            } else {
              res = await callInternal(TESTBUY_CUSTOM_ARTICLE, {
                articleId: newArticle.product,
                storeId,
                locale: userLanguage,
              });
            }
            if (res.data) {
              await setArticles([...articles, res.data] as TestBuyProduct[]);
            } else {
              setToastVisible(true);
            }
          }
        }}
      />
      {toastVisible && (
        <SimpleToast
          text={"couldNotUseThisArticleString"}
          isOpen={toastVisible}
          onCloseRequest={() => {
            setToastVisible(false);
          }}
        />
      )}
    </WidgetRowRapper>
  );
};
