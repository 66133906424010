import React from "react";
import styled from "styled-components";
import { StockInfoPills } from "../StockInfoPills";
import { RelatedTasksLink } from "../RelatedTasksLink";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { RelatedLinkContainer } from "./CommonComponents";
import { parseProduct } from "../../services/products.service";

const Container = styled.div`
  div {
    padding: 0px;
  }
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export function StockInfoPillsWithRelatedTasks({
  productFullId,
  productId,
  navigateToTaskForm,
  onClose,
  relatedTasksCount,
  ofWhichClosed,
  stockInfo,
}) {
  const { push } = useWorkspacesAction();

  const handleNavigationToTaskForm = function () {
    push("/task/new/addon", {
      product: { product: productId },
      showAddOnFlow: false,
    });
  };

  const [short_id, type] = parseProduct(productId);
  const onClick = navigateToTaskForm ? handleNavigationToTaskForm : onClose;
  return (
    <Container onClick={onClick}>
      <StockInfoPills
        stockInfo={stockInfo}
        type={type}
        short_id={short_id}
        noBorder
      />
      <RelatedLinkContainer>
        <RelatedTasksLink
          relatedTasksCount={relatedTasksCount}
          ofWhichClosed={ofWhichClosed}
          productId={productId}
        />
      </RelatedLinkContainer>
    </Container>
  );
}
