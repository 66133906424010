import React from "react";
import Button from "@ingka/button";
import arrowLeft from "@ingka/ssr-icon/paths/arrow-left";
import FullScreenPopup from "../../../layout/components/FullScreenPopup/FullScreenPopup";
import styles from "./error.module.css";
import { SimpleHeader } from "../../../layout/components/SimpleHeader/SimpleHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FixaText } from "../../../shared/wrappers/FixaText/FixaText";

interface ErrorProps {
  isAccessIssue: boolean;
}
export const Error: React.FC<ErrorProps> = ({ isAccessIssue }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleBackToLoginClick = () => {
    navigate("/login");
  };

  return (
    <FullScreenPopup noPadding noAppBar>
      <SimpleHeader displayAllScreens></SimpleHeader>
      <div className={styles["error-container"]}>
        <Button
          size="small"
          type="secondary"
          onClick={() => handleBackToLoginClick()}
          text={t("backToLoginString")}
          iconPosition="leading"
          ssrIcon={arrowLeft}
        />
        <FixaText
          tagName="h2"
          headingSize="s"
          className={styles["error-message"] || ""}
        >
          {isAccessIssue ? t("noAccessMessageString") : t("loginFailureString")}
        </FixaText>
        <FixaText tagName="p" bodySize="m">
          {isAccessIssue
            ? t("noAccessDescriptionString")
            : t("loginFailureDescriptionString")}
        </FixaText>
      </div>
    </FullScreenPopup>
  );
};
