import React from "react";
import SSRIcon from "@ingka/ssr-icon";
import homeIcon from "@ingka/ssr-icon/paths/home";

import FixaLink from "../../wrappers/FixaLink/FixaLink";
import styles from "./Linkbar.module.css";

function Home({
  "data-testid": dataTestId,
}: {
  "data-testid"?: string | undefined;
}) {
  return (
    <FixaLink
      data-testid={dataTestId}
      to="/"
      className={`${styles["clickable"]} ${styles["home"]}`}
      aria-label="Home"
    >
      <SSRIcon paths={homeIcon} />
    </FixaLink>
  );
}

export default Home;
