import React from "react";
import { LoginView } from "./login/components/LoginView/LoginView";
import { Route, Routes } from "react-router-dom";
import { PRIVACY_PATH } from "@coworker/app/src/paths";
import PrivacyStatement from "@coworker/app/src/components/Privacy/PrivacyStatement";
import { Error } from "@coworker/apprestructured/src/login/components/Error/Error";
import { ErrorType } from "./shared/constants/errorTypes";
import { WorkspacesProvider } from "@coworker/app/src/hooks/useWorkspaces";
import { useTranslation } from "react-i18next";
import { SimpleLoadingBall } from "./shared/components/SimpleLoading/SimpleLoading";

interface MainUnauthenticatedProps {
  authInProgress: boolean;
  authError: string;
}

export const MainUnauthenticated: React.FC<MainUnauthenticatedProps> = ({
  authInProgress,
  authError,
}) => {
  const isAccessIssue = authError === ErrorType.FORBIDDEN;
  const { t } = useTranslation();

  return authInProgress ? (
    <SimpleLoadingBall text={t("loadingEllipsisString")} size="medium" />
  ) : (
    <WorkspacesProvider>
      <Routes>
        <Route path={PRIVACY_PATH} element={<PrivacyStatement />} />
        <Route path="/login" element={<LoginView />} />
        {authError && (
          <Route path="/" element={<Error isAccessIssue={isAccessIssue} />} />
        )}
      </Routes>
    </WorkspacesProvider>
  );
};
