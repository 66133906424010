import React from "react";
import SkapaButton from "@ingka/button";
import { OverflowCarousel } from "@ingka/carousel";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import { QuickActionTypes } from "./QuickActionsTypes";
import addOnIcon from "@ingka/ssr-icon/paths/add-on";
import mfaqQuestionIcon from "@ingka/ssr-icon/paths/speech-bubble";
import productQualityReportIcon from "@ingka/ssr-icon/paths/box-cancel";
import productIssueIcon from "@ingka/ssr-icon/paths/chair";
import customTaskIcon from "@ingka/ssr-icon/paths/pencil";
import { Trans } from "@coworker/locales";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { useInputPopup } from "@coworker/app/src/components/InputPopup";
import { useProductQualityTeamId } from "@coworker/app/src/hooks/useProductQualityTeamId";
import { TaskTypesRoutes } from "../../constants/taskTypesRoutes";
import trackerHlper from "../../../../../coworker-app/src/helpers/tracker";
import { QuickActionsTitle } from "./QuickActionsTitle";
import "./quick-actions.css";
import { FixaWindow } from "@coworker/reusable";

function buttonTypeTrimmer(buttonType: string) {
  return buttonType.substring(0, buttonType.length - 6);
}

interface QuickActionsProps {
  id: string;
}

export const QuickActions: React.FC<QuickActionsProps> = ({ id }) => {
  const { push, setNavigationState } = useWorkspacesAction();
  const { getInput } = useInputPopup();
  const qualityTeamId = useProductQualityTeamId();

  const hideForGermanyUsers = (window as FixaWindow).hideForGermanyUsers;

  const handleClick = async (type: string, updateLocationState: boolean) => {
    switch (type) {
      case TaskTypesRoutes.CUSTOM:
        push(`/task/new/custom`);
        break;
      case TaskTypesRoutes.ADDON:
      case TaskTypesRoutes.PRODUCT_QUALITY:
      case TaskTypesRoutes.PRODUCT_ISSUE:
        if (updateLocationState) {
          setNavigationState({
            search: true,
            type,
          });
        }
        const product = await getInput("scansearch", { start: new Date() });
        if (product) {
          push(`/task/new/${type}`, { product, qualityTeamId });
        }
        break;
    }
  };

  const quickActions = [
    {
      text: <Trans>{QuickActionTypes.ADDON}</Trans>,
      icon: addOnIcon,
      onClick: async () => {
        trackerHlper.trackQuickActionsButton(
          buttonTypeTrimmer(QuickActionTypes.ADDON)
        );
        await handleClick(TaskTypesRoutes.ADDON, true);
      },
    },
    {
      text: <Trans>{QuickActionTypes.MFAQ_FOLLOW_UP}</Trans>,
      icon: mfaqQuestionIcon,
      onClick: () => {
        trackerHlper.trackQuickActionsButton(
          buttonTypeTrimmer(QuickActionTypes.MFAQ_FOLLOW_UP)
        );
        push("/customerquestions");
      },
    },
    !hideForGermanyUsers && {
      text: <Trans>{QuickActionTypes.PRODUCT_QUALITY}</Trans>,
      icon: productQualityReportIcon,
      onClick: async () => {
        trackerHlper.trackQuickActionsButton(
          buttonTypeTrimmer(QuickActionTypes.PRODUCT_QUALITY)
        );
        await handleClick(TaskTypesRoutes.PRODUCT_QUALITY, true);
      },
    },
    {
      text: <Trans>{QuickActionTypes.PRODUCT_ISSUE}</Trans>,
      icon: productIssueIcon,
      onClick: async () => {
        trackerHlper.trackQuickActionsButton(
          buttonTypeTrimmer(QuickActionTypes.PRODUCT_ISSUE)
        );
        await handleClick(TaskTypesRoutes.PRODUCT_ISSUE, true);
      },
    },
    {
      text: <Trans>{QuickActionTypes.CUSTOM}</Trans>,
      icon: customTaskIcon,
      onClick: async () => {
        trackerHlper.trackQuickActionsButton(
          buttonTypeTrimmer(QuickActionTypes.CUSTOM)
        );
        await handleClick(TaskTypesRoutes.CUSTOM, false);
      },
    },
  ].filter(Boolean);

  const quickActionItems: any = quickActions.map((quickaction: any) => {
    return (
      <SkapaButton
        type="primary"
        key={quickaction.text}
        text={quickaction.text}
        onClick={quickaction.onClick}
        size="small"
        ssrIcon={quickaction.icon}
        className="quick-actions-button"
      />
    );
  });

  return (
    <div>
      <QuickActionsTitle />
      <OverflowCarousel id={id} controlBtnType="primary">
        {quickActionItems.map((item: any, i: any) => {
          return React.cloneElement(item, { key: i });
        })}
      </OverflowCarousel>
    </div>
  );
};
