import React from "react";
import SSRIcon from "@ingka/ssr-icon";
import chevronRightIcon from "@ingka/ssr-icon/paths/chevron-right-small";

import FixaLink from "../../wrappers/FixaLink/FixaLink";
import styles from "./Linkbar.module.css";
import { LinkbarItem } from "./Linkbar";

export type ParentProps = LinkbarItem;

function Parent({
  label,
  to,
  "data-testid": dataTestId,
}: ParentProps & { "data-testid"?: string | undefined }) {
  return (
    <FixaLink
      data-testid={dataTestId}
      className={`${styles["clickable"]} ${styles["breadcrumb"]}`}
      to={to}
    >
      {label}
      <SSRIcon paths={chevronRightIcon} />
    </FixaLink>
  );
}

export default Parent;
