import React from "react";
import ListView from "@ingka/list-view";
import { Article, ArticleWithRoomCount } from "../types/article";
import { ArticleListItemCardDesktop, RoomListDesktop } from "../styles/styles";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { RoomsViewSearchListItemSkeleton } from "../Molecules/RoomsViewSearchListItemSkeleton";
import { TaskLinks } from "../types/taskLink";
import { hasOngoingTask } from "../Molecules/RoomArticles.helper";
import { useEDSData } from "../hooks/useEDSData";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import { joinProductArticleIds } from "../tools";
import { useSalesStopData } from "../hooks/useSalesStopData";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

interface RoomsViewArticleListDesktopProps {
  articles: ArticleWithRoomCount[] | undefined;
  taskLinks: TaskLinks;
  storeId: string;
}

export const RoomsViewArticleListDesktop = ({
  articles,
  taskLinks,
  storeId,
}: RoomsViewArticleListDesktopProps) => {
  const countryId = useCountryId();
  const articleNumbers = joinProductArticleIds(articles ?? []);
  const { data: edsData } = useEDSData(countryId, articleNumbers);
  const { data: salesStopData } = useSalesStopData(storeId, articleNumbers);
  const { push } = useWorkspacesAction();
  const onArticleClick = (article: Article) => {
    push(`/roomsettings/articles/search/${article.productArticleId}`);
  };

  if (!articles) {
    return (
      <ListView id="articles-list">
        <RoomsViewSearchListItemSkeleton key="rooms-view-article-0" />
        <RoomsViewSearchListItemSkeleton key="rooms-view-article-1" />
        <RoomsViewSearchListItemSkeleton key="rooms-view-article-2" />
        <RoomsViewSearchListItemSkeleton key="rooms-view-article-3" />
      </ListView>
    );
  } else {
    return (
      <RoomListDesktop>
        {articles &&
          articles.map((article, index) => (
            <ArticleListItemCardDesktop key={`${article.id}-${index}`}>
              <ArticleListItem
                article={article}
                isMainProduct={() => false}
                isBackupForProduct={() => false}
                hasOngoingTask={() => hasOngoingTask(article.id, taskLinks)}
                edsData={
                  edsData?.find(
                    (eds: { productArticleId: string }) =>
                      eds.productArticleId === article.productArticleId
                  ) ?? null
                }
                salesStopData={
                  salesStopData?.find(
                    (salesStop) =>
                      salesStop.productArticleId === article.productArticleId
                  ) ?? null
                }
                selectArticleIdsMode={false}
                isSelected={false}
                showActionRow={false}
                hideChevron={true}
                onClick={onArticleClick}
              />
            </ArticleListItemCardDesktop>
          ))}
      </RoomListDesktop>
    );
  }
};
