import React from "react";
import { AlertResponse, Room, Rooms } from "../types/room";
import { TaskLinks } from "../types/taskLink";
import { RoomsViewRoomsListDesktop } from "./RoomsViewRoomsListDesktop";
import { RoomsViewNoSearchResult } from "../Atoms/RoomsViewNoSearchResult";
import { RoomsViewArticleListDesktop } from "./RoomsViewArticleListDesktop";
import { ArticleWithRoomCount } from "../types/article";
import { useTranslation } from "@coworker/locales";
import styled from "styled-components";

const SectionHeaderText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 0px;
`;

const ArticleSectionHeaderText = styled(SectionHeaderText)`
  padding: 32px 0px 16px 0px;
`;

interface RoomsViewSearchListDesktopProps {
  rooms: Rooms | undefined;
  alerts: AlertResponse[] | undefined;
  taskLinks: TaskLinks;
  onSaveRoom: (room: Room) => void;
  onOngoingTasksClick: () => void;

  query: string;
  storeId: string;
  articles: ArticleWithRoomCount[] | undefined;
}

export function RoomsViewSearchListDesktop({
  rooms,
  alerts,
  taskLinks,
  onSaveRoom,
  onOngoingTasksClick,
  query,
  storeId,
  articles,
}: RoomsViewSearchListDesktopProps) {
  const { t } = useTranslation();
  return (
    <>
      {!!rooms && rooms.length === 0 && !!articles && articles.length === 0 && (
        <RoomsViewNoSearchResult query={query ?? ""} />
      )}

      {!!rooms && rooms.length > 0 && (
        <SectionHeaderText>
          {t("countRoomSettingsString", { count: rooms?.length ?? 0 })}
        </SectionHeaderText>
      )}
      <RoomsViewRoomsListDesktop
        rooms={rooms}
        alerts={alerts}
        taskLinks={taskLinks}
        onSaveRoom={onSaveRoom}
        onOngoingTasksClick={onOngoingTasksClick}
        showOngoingTasks={false}
      />

      {!!articles && articles.length > 0 && (
        <ArticleSectionHeaderText>
          {t("countArticlesString", { count: articles?.length ?? 0 })}
        </ArticleSectionHeaderText>
      )}
      <RoomsViewArticleListDesktop
        articles={articles}
        taskLinks={taskLinks}
        storeId={storeId}
      />
    </>
  );
}
