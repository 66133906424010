import { markEventRead } from "../services/events.service";
import React from "react";
import { useWorkspacesAction, useWorkspacesState } from "./useWorkspaces";
import { useCurtainNotification } from "../components/CurtainNotification";
import tracker from "../helpers/tracker";
import { useSearchParams } from "react-router-dom";

function usePushMessage() {
  const { push } = useWorkspacesAction();
  const { navigationPath } = useWorkspacesState();
  const { showCurtain } = useCurtainNotification();
  const [searchParams] = useSearchParams();
  const pushMessage = searchParams.get("pushMessage");
  const instanceId = searchParams.get("instanceId");
  const type = searchParams.get("type");
  const eventType = searchParams.get("eventType");

  React.useEffect(() => {
    if (pushMessage && instanceId && type && eventType) {
      const documentName = `${type}#${instanceId}`;
      markEventRead(documentName).then(() => {
        tracker.trackNotificationCTAClick(eventType);
      });
    }
  }, [eventType, instanceId, pushMessage, type]);

  const openToast = React.useCallback(
    (event) => {
      const {
        options: {
          body,
          data: { url, text, instanceId, type, eventType },
        },
      } = event.data;
      const documentName = `${type}#${instanceId}`;
      if (navigationPath !== "/tasks/activity/notifications") {
        showCurtain(body, text, () => {
          tracker.trackNotificationCTAClick(eventType);
          markEventRead(documentName);
          push(url);
        });
      }
    },
    [showCurtain, push, navigationPath]
  );

  React.useEffect(() => {
    if (!("serviceWorker" in navigator)) return;
    navigator.serviceWorker.addEventListener("message", openToast);

    return () => {
      if (!("serviceWorker" in navigator)) return;
      navigator.serviceWorker.removeEventListener("message", openToast);
    };
  }, [openToast]);
}

export default usePushMessage;
