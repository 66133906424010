import React from "react";
import styled, { css } from "styled-components";
import { SkapaSwitch } from "../SkapaSwitch";
import useFormatScheduled from "../../hooks/useFormatScheduled";
import useFormatter from "../../hooks/useFormatter";
import { Trans } from "@coworker/locales";
import { SORTED_WEEKDAYS } from "@coworker/functions/src/consts/isoWeek";
import tracker from "../../helpers/tracker";
import Button from "@ingka/button";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import SSRIcon from "@ingka/ssr-icon";
import CrossIcon from "@ingka/ssr-icon/paths/cross";

const FormContainer = styled.div`
  padding: 0 28px;
  border-bottom: 1px solid var(--grey100);
  flex-grow: 1;
  overflow-y: auto;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const DateOfWeekRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const DowButton = styled.button`
  outline: none;
  position: relative;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin: 5px;

  background-color: var(--grey300);
  ${({ checked }) =>
    checked &&
    css`
      background-color: var(--grey900);
    `}
  & > span {
    color: var(--white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: bold;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 17px 0;
  & > input {
    font-size: 16px;
    border-bottom: 1px solid var(--grey500);
    background: var(--white);
    width: 100%;
  }
  &:first-child {
    margin-top: 0;
  }
`;

const HorizontalInputBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  & > *:nth-child(2) {
    margin-left: 17px;
  }
`;

const Label = styled.label`
  font-size: 12px;
  line-height: 18px;
  color: var(--grey700);
  margin: 4px 0;
`;

const SmallInput = styled.input`
  outline: none;
  border-bottom: 1px solid var(--grey500);
  padding: 4px;
  width: 26px;
  font-size: 16px;
  text-align: center;
  background: var(--white);
`;

const TimeUnit = styled.div`
  color: var(--grey900);
  font-size: 16px;
`;

const Summary = styled.div`
  margin-bottom: 32px;
  & > label {
    font-size: 14px;
    font-weight: bold;
    color: var(--grey718);
  }
  & > p {
    font-size: 14px;
    color: var(--grey718);
  }
`;

function formatYYYYMMDD(date_or_string) {
  return !date_or_string.setHours
    ? date_or_string.slice(0, 10) // Got an ISO date string, slice it!
    : formatYYYYMMDD(date_or_string.toISOString()); // Make it a string first.
}

function tomorrow() {
  const date = new Date();
  date.setHours(26); // 02.00 tomorrow morning
  return formatYYYYMMDD(date);
}

function sixDaysLater(base) {
  const date = new Date(base);
  date.setDate(date.getDate() + 6);
  return formatYYYYMMDD(date);
}

function nextMonth(base) {
  const date = new Date(base);
  date.setMonth(date.getMonth() + 1);
  return formatYYYYMMDD(date);
}

const today = formatYYYYMMDD(new Date());
const defaultStart = tomorrow();
function suggestEnd(start) {
  return nextMonth(start);
}

const Schedule = ({
  onSubmit,
  onClose,
  days_of_week,
  scheduled_start,
  scheduled_end,
  every_n_weeks,
  recurring,
}) => {
  const [inputFocused, setInputFocused] = React.useState(false);
  const onFocus = () => setInputFocused(true);
  const onBlur = () => setInputFocused(false);
  const [isRecurring, setIsRecurring] = React.useState(recurring ?? false);

  const [start, setStart] = React.useState(
    formatYYYYMMDD(scheduled_start || tomorrow())
  );
  const [end, setEnd] = React.useState(
    formatYYYYMMDD(scheduled_end || nextMonth(start))
  );
  const [daysOfWeek, setDaysOfWeek] = React.useState(days_of_week ?? []);
  const [weekCount, setWeekCount] = React.useState(every_n_weeks ?? 1);

  function toggleDOW(which) {
    if (daysOfWeek.includes(which)) {
      setDaysOfWeek(daysOfWeek.filter((x) => x !== which));
    } else {
      setDaysOfWeek([...daysOfWeek, which]);
    }
  }

  const { formatWeekday } = useFormatter();
  const { summary } = useFormatScheduled({
    days_of_week: isRecurring && daysOfWeek,
    scheduled_start: new Date(start).toISOString(),
    scheduled_end: isRecurring && new Date(end).toISOString(),
    every_n_weeks: isRecurring && weekCount,
    recurring: isRecurring,
  });

  React.useEffect(() => {
    tracker.plannedTasks.openPopup();
  }, []);

  const appBarActions = [
    {
      name: "close",
      icon: <SSRIcon paths={CrossIcon} />,
      onClick: () => onClose(),
      position: "right",
    },
  ];

  return (
    <FullScreenPopup
      appBarConfig={{
        title: <Trans>scheduleTaskString</Trans>,
        actions: appBarActions,
      }}
      actionBarContent={
        <>
          {!inputFocused && (
            <Button
              type="primary"
              text={<Trans>submitString</Trans>}
              disabled={
                start < today ||
                (isRecurring &&
                  (end < sixDaysLater(start) || !daysOfWeek.length))
              }
              onClick={() => {
                if (isRecurring) {
                  onSubmit({
                    days_of_week: daysOfWeek,
                    scheduled_start: new Date(start).toISOString(),
                    scheduled_end: new Date(end).toISOString(),
                    every_n_weeks: weekCount,
                    recurring: true,
                  });
                } else {
                  onSubmit({
                    scheduled_start: new Date(start).toISOString(),
                    recurring: false,
                  });
                }
              }}
            />
          )}
        </>
      }
      noPadding
    >
      <FormContainer>
        <InputBox data-testid="scheduleStartDate">
          <Label>
            <Trans>startInputString</Trans>
          </Label>
          <input
            type="date"
            value={start}
            onChange={(e) => setStart(e.target.value || defaultStart)}
          />
        </InputBox>
        <HorizontalInputBox data-testid="recurringToggle">
          <SkapaSwitch
            checked={isRecurring}
            onChange={() => setIsRecurring(!isRecurring)}
          />
          <Label>
            <Trans>repeatString</Trans>
          </Label>
        </HorizontalInputBox>
        {isRecurring && (
          <>
            <InputBox data-testid="repeatFrequencySection">
              <Label>
                <Trans>repeatEveryString</Trans>
              </Label>
              <HorizontalInputBox>
                <SmallInput
                  onFocus={onFocus}
                  onBlur={onBlur}
                  value={weekCount?.toString?.() ?? weekCount}
                  onChange={(e) => {
                    if (Number.isNaN(e.target.value)) {
                      setWeekCount(e.target.value);
                    } else {
                      setWeekCount(parseInt(e.target.value));
                    }
                  }}
                  type="number"
                  min={1}
                />
                <TimeUnit>
                  <Trans i18nKey="weekString" count={weekCount}>
                    weekString
                  </Trans>
                </TimeUnit>
              </HorizontalInputBox>
            </InputBox>
            <InputBox data-testid="repeatOnDay">
              <Label>
                <Trans>onThisDaysString</Trans>
              </Label>
              <DateOfWeekRow data-testid="weekDayContainer">
                {SORTED_WEEKDAYS.map((nr) => (
                  <DowButton
                    key={`day-${nr}`}
                    checked={daysOfWeek.includes(nr)}
                    onClick={() => toggleDOW(nr)}
                    data-testid={formatWeekday.narrow(nr)}
                  >
                    <span>{formatWeekday.narrow(nr)}</span>
                  </DowButton>
                ))}
              </DateOfWeekRow>
            </InputBox>
            <InputBox data-testid="scheduleEndDate">
              <Label>
                <Trans>endInputString</Trans>
              </Label>
              <input
                type="date"
                value={end}
                onChange={(e) => setEnd(e.target.value || suggestEnd(start))}
              />
            </InputBox>
          </>
        )}
        <Spacer />
        <Summary data-testid="recurringSummary">
          <label>
            <Trans>summaryString</Trans>
          </label>
          <p>{summary}</p>
        </Summary>
      </FormContainer>
    </FullScreenPopup>
  );
};

export default Schedule;
