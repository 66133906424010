import { useQuery } from "@tanstack/react-query";
import { getArticlesByGroupId } from "../services/groups.service";
import { QueryKeys } from "./queryKeys";

export const useArticlesByGroupId = (groupId: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.ArticlesByGroup, groupId],
    queryFn: () => getArticlesByGroupId(groupId || ""),
    enabled: !!groupId,
  });
};
