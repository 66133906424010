import React from "react";
import Modal, { ModalHeader, Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button";
import { useTranslation } from "@coworker/locales";
import styles from "./article-error.module.css";
import Text from "@ingka/text";

interface ArticleErrorProps {
  openArticleErrorModal: boolean;
  onClose: (closeArticleErrorModal: boolean) => void;
}

export const ArticleError = ({
  openArticleErrorModal,
  onClose,
}: ArticleErrorProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={openArticleErrorModal}
      handleCloseBtn={() => {
        onClose(!openArticleErrorModal);
      }}
    >
      <Prompt
        titleId="articleError"
        title=""
        header={
          <ModalHeader
            ariaCloseTxt={t("closeActionString")}
            title={"Article Error"}
            className={styles["header"] || ""}
          />
        }
        footer={
          <ModalFooter>
            <Button aria-label={t("okIUnderstandString")} type={"primary"}>
              {t("okIUnderstandString")}
            </Button>
          </ModalFooter>
        }
      >
        <Text
          aria-label={t("cannotCreateTaskString")}
          tagName="h2"
          headingSize="s"
        >
          {t("cannotCreateTaskString")}
        </Text>
        <Text
          aria-label={t("articlePriceMissingString")}
          className={styles["sub-text"] || ""}
        >
          {t("articlePriceMissingString")}
        </Text>
      </Prompt>
    </Modal>
  );
};
