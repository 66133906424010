import React from "react";

import { ListImage } from "../Atoms/ListImage";
import { ArticleIdentifier } from "../Atoms/ArticleIdentifier";
import { Text } from "../Atoms/Text";

import {
  CenterContainer,
  LeftContainer,
  ListItemWrapper,
  FlexibleRowContainer,
} from "../styles/styles";
import { formatFullIdWithDots } from "@coworker/reusable";
import { Item } from "@coworker/apprestructured/src/shared/types/item";

interface ReplacedArticleListItemProps {
  articleInfo?: Item | undefined;
}

export function ReplacedArticleListItem({
  articleInfo,
}: ReplacedArticleListItemProps) {
  if (!articleInfo) return <></>;

  const innerComponent = (
    <>
      <FlexibleRowContainer>
        <LeftContainer>
          <ListImage src={articleInfo?.imageUrl || ""} alt={""} />
        </LeftContainer>
        <CenterContainer $centerText={false}>
          <Text text={articleInfo.name || "Unknown"} bold />
          <ArticleIdentifier
            articleId={formatFullIdWithDots(articleInfo.no || "")}
          />
          <Text text={articleInfo.description} />
        </CenterContainer>
      </FlexibleRowContainer>
    </>
  );

  return (
    <>
      <ListItemWrapper $hasBanner={false}>{innerComponent}</ListItemWrapper>
    </>
  );
}
