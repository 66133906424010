import { useQuery } from "@tanstack/react-query";
import { getRoomById } from "../services/rooms.service";
import { QueryKeys } from "./queryKeys";

export const useRoomById = (roomId: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.Room, roomId],
    queryFn: () => getRoomById(roomId || ""),
    enabled: !!roomId,
  });
};
