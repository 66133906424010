import React, { ReactNode } from "react";
import styled from "styled-components";
import { ListViewItem } from "@ingka/list-view";
import Status from "@ingka/status";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useImagesBySignedUrl } from "../hooks/useImagesBySignedUrl";
import {
  getEDSAlertCount,
  getOngoingTaskCount,
  getRoomMetaInfo,
  getSalesStopAlertCount,
} from "./RoomListItem.helper";
import { HeadLine } from "../Atoms/HeadLine";
import { TaskLinks } from "../types/taskLink";
import { AlertResponse, Room } from "../types/room";
import { FixaWindow } from "@coworker/reusable";
import { TabIds, TabQueryKey } from "../Organisms/RoomTabs.helper";
import { useImagesByRoomId } from "../hooks/useImagesByRoomId";

const SolidRedStatus = styled(Status)`
  .status__dot {
    background-color: currentColor;
  }
`;

interface RoomListItemProps {
  room: Room;
  taskLinks: TaskLinks;
  alerts: AlertResponse[];
}

export function RoomListItemMobile({
  room,
  taskLinks,
  alerts,
}: RoomListItemProps) {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const { data: images } = useImagesByRoomId(room.id);
  const signedUrlImages = useImagesBySignedUrl(images ?? []);

  const ongoingTaskCount = getOngoingTaskCount(taskLinks, room.id);

  const edsAlertCount = getEDSAlertCount(alerts, room.id);

  const salesStopAlertCount = getSalesStopAlertCount(alerts, room.id);

  const mainImageUrl = signedUrlImages[0]?.url || "";

  const extraProps: { addOn?: ReactNode } = {};
  if ((window as FixaWindow).editableRoomSettingsSettings) {
    if (room.active === "INACTIVE") {
      extraProps.addOn = (
        <SolidRedStatus label={t("inactiveString")} statusColor="red" />
      );
    }
  }

  return (
    <ListViewItem
      title={
        <HeadLine
          main={room.name}
          sub1={room.main_article_name_1}
          sub2={room.main_article_name_2}
        />
      }
      image={mainImageUrl}
      description={getRoomMetaInfo(
        ongoingTaskCount,
        edsAlertCount,
        salesStopAlertCount,
        t
      )}
      control={"navigational"}
      controlIcon={"chevronRightSmall"}
      onClick={() => {
        push(`/roomsettings/tabs/${room.id}?${TabQueryKey}=${TabIds.LIST}`);
      }}
      emphasised
      inset
      {...extraProps}
    />
  );
}
