import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { Container } from "./_common";
import { SimpleProductInfo } from "@coworker/components";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";

export const TaskTitle = styled.div`
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey900);
`;

export default function TaskFormSimpleProduct({
  value: { productId: short_id, productType: type },
  onChange,
}) {
  const { data: basicItem } = useItemInfo(short_id);
  const name = basicItem?.name || "";

  return short_id ? (
    <Container data-testid="productDetailWidget">
      <TaskTitle>
        <Trans>product2String</Trans>
      </TaskTitle>
      <SimpleProductInfo
        title={name}
        description={basicItem?.description}
        formattedNumber={basicItem?.noFormatted}
        withArrow={false}
      />
    </Container>
  ) : null;
}
