import React from "react";
import { FixaToast } from "../../wrappers/FixaToast/FixaToast";
import { useTranslation } from "@coworker/locales";
import styled from "styled-components";

interface ToastProps {
  text: string;
  isOpen: boolean;
  onCloseRequest: () => void;
  ariaLabelCloseBtn?: string;
}

const StyledToast = styled(FixaToast)`
  height: "70px";
  top: calc(100vh - 70px);
`;
export const SimpleToast = ({
  text,
  isOpen,
  onCloseRequest,
  ariaLabelCloseBtn,
  "data-testid": testId,
}: ToastProps & { "data-testid"?: string }) => {
  const { t } = useTranslation();
  return (
    <StyledToast
      text={t(text)}
      isOpen={isOpen}
      onCloseRequest={onCloseRequest}
      ariaLabelCloseBtn={ariaLabelCloseBtn ?? ""}
      data-testid={testId} // Apply the testId conditionally
    />
  );
};
