import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import React from "react";
import { useTranslation } from "@coworker/locales";
import { Room } from "../types/room";
import { AggregatedArticleListing } from "./AggregatedArticleListing";
import { GroupedArticleListing } from "./GroupedArticleListing";

import { ArticleIdNbrArticles, Articles } from "../types/article";
import { Groups } from "../types/groups";
import { TabIds } from "./RoomTabs.helper";
import "../styles/room-tabs.css";
import styled from "styled-components";

interface RoomTabsProps {
  room: Room;
  activeArticles: Articles;
  isSelectArticlesMode: boolean;
  getSelectedArticleIds: (
    fetchSelectedArticleIdsFunction: () => ArticleIdNbrArticles[]
  ) => void;
  setSelectArticleMode: () => void;
  allArticlesInRoom: Articles;
  onCurrentTabIdChange: (tab: string) => void;
  groups: Groups;
  currentTabId: string;
}

const StyledTabs = styled(Tabs)`
  .tabs__panel {
    padding: 16px 0;
  }
  .overflow-carousel {
    margin-left: 16px;
    margin-right: 16px;
  }
  .tabs__list::after {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const RoomTabs = ({
  room,
  activeArticles,
  isSelectArticlesMode,
  getSelectedArticleIds,
  setSelectArticleMode,
  onCurrentTabIdChange,
  groups,
  currentTabId,
}: RoomTabsProps) => {
  const { t } = useTranslation();

  if (!activeArticles || !groups) return <></>;

  return (
    <StyledTabs
      defaultActiveTab={currentTabId}
      className="room-tabs-padding"
      tabs={[
        <Tab
          key={TabIds.LIST}
          tabPanelId={TabIds.LIST}
          text={t("articleListString")}
        />,
        <Tab
          key={TabIds.GROUP}
          tabPanelId={TabIds.GROUP}
          text={t("groupsString")}
        />,
      ]}
      onTabChanged={(tab: string) => onCurrentTabIdChange(tab)}
      tabPanels={[
        <TabPanel key={"article-list-tab-panel"} tabPanelId={TabIds.LIST}>
          <AggregatedArticleListing
            articles={activeArticles}
            room={room}
            groups={groups}
            isSelectArticlesMode={isSelectArticlesMode}
            getSelectedArticleIds={getSelectedArticleIds}
            setSelectArticleMode={setSelectArticleMode}
          />
        </TabPanel>,

        <TabPanel key={"groups-tab-panel"} tabPanelId={TabIds.GROUP}>
          <GroupedArticleListing
            articles={activeArticles}
            room={room}
            groups={groups}
          />
        </TabPanel>,
      ]}
    />
  );
};
