import React from "react";
import { Articles } from "../types/article";
import {
  ArticleListItemContainer,
  ArticleListItemImage,
  GreyBoxWithText,
} from "../styles/styles";

const NumberOfImagesToShow = 3;

interface ArticlesImagesOverviewProps {
  articles: Articles;
}

export const ArticlesImagesOverview = ({
  articles,
}: ArticlesImagesOverviewProps) => {
  const numberOfImagesToShow =
    articles.length >= NumberOfImagesToShow
      ? NumberOfImagesToShow
      : articles.length;
  const numberOfImagesNotShown = articles.length - numberOfImagesToShow;

  return (
    <ArticleListItemContainer>
      {articles
        .slice(0, numberOfImagesToShow)
        .map(({ imageSmall, id }, index) => (
          <ArticleListItemImage key={`${id}-${index}`} src={imageSmall ?? ""} />
        ))}
      {numberOfImagesNotShown > 0 && (
        <GreyBoxWithText
          key={`Images not shown ${numberOfImagesNotShown}`}
        >{`+${numberOfImagesNotShown}`}</GreyBoxWithText>
      )}
    </ArticleListItemContainer>
  );
};
