import { useQuery } from "@tanstack/react-query";
import { getTaskLinksByRoom } from "../services/tasks.service";
import { QueryKeys } from "./queryKeys";

export const useTaskLinksByRoom = (roomId: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.TaskLinksByRoom, roomId],
    queryFn: () => getTaskLinksByRoom(roomId || ""),
    enabled: !!roomId,
  });
};
