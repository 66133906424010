import React from "react";
import { useTranslation } from "@coworker/locales";
import { Widget } from "@coworker/components/src/components/Widget";
import { AddDescription } from "@coworker/components";
import { useInputPopup } from "../../InputPopup";
import { useLocation } from "react-router-dom";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import preferences from "@coworker/functions/src/enums/profilePreferences.json";
import { useFocusTile } from "../../../../src/core/hooks/useFocusTile";

/**
 * @deprecated This method has been moved to typescript and will be removed in the future (AddDescriptionWidget)
 */
export default function TaskFormDescription({
  value,
  onChange,
  conf: {
    optional,
    descriptionTitleTranslationKey = "descriptionAddString",
    icon,
    lblColor,
    overridePadding,
  },
  last,
  additionalSubTitle,
}) {
  const location = useLocation();
  const isUpptackaTask = location.search.includes("upptacka");

  const [userLanguage] = useUserPreference(preferences.LANGUAGE, "");
  const focusTile = useFocusTile(userLanguage);
  const { t } = useTranslation();

  value = isUpptackaTask
    ? t("updateUpptackaHomeScreenWithNewString", {
        title: focusTile.title,
      })
    : value;

  React.useEffect(() => {
    if (isUpptackaTask) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getInput } = useInputPopup();
  const getDescription = async () => {
    const desc = await getInput("description", {
      initialValue: value,
      title: "",
      descriptionTitle: t(descriptionTitleTranslationKey),
      optional,
    });
    if (desc) {
      onChange(desc);
    }
  };

  return (
    <Widget
      onClick={getDescription}
      data-testid="descriptionWidget"
      tabIndex={0}
    >
      <AddDescription
        value={value}
        deactivated={!value}
        descriptionTitle={t(descriptionTitleTranslationKey)}
        optional={optional}
        noBottomBorder={last}
        icon={icon}
        lblColor={lblColor}
        overridePadding={overridePadding}
        activeIcon={icon}
        additionalSubTitle={
          !value && window.location.pathname === "/task/new/mfaq_followup" ? (
            additionalSubTitle
          ) : (
            <></>
          )
        }
      />
    </Widget>
  );
}
