import { useQuery } from "@tanstack/react-query";
import { getGroupsByRoom } from "../services/groups.service";
import { QueryKeys } from "./queryKeys";

export const useGroupsByRoomId = (roomId: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.GroupsByRoom, roomId],
    queryFn: () => getGroupsByRoom(roomId || ""),
    enabled: !!roomId,
  });
};
