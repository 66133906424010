import SSRIcon from "@ingka/ssr-icon";
import React from "react";
import { StarConfig } from "../helper";

interface ShoppingToolsProgressStarProps {
  starConfig: StarConfig;
}
export const ShoppingToolsProgressStar = ({
  starConfig,
}: ShoppingToolsProgressStarProps) => {
  return <SSRIcon paths={starConfig.icon} color={starConfig.color} />;
};
