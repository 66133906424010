import { Article } from "./article";
import { Item } from "@coworker/apprestructured/src/shared/types/item";

const unknown = "Unknown";

export function mapArticleInfoToArticle(item: Item): Article {
  return {
    productArticleId: item.no,
    productArticleType: item.type || unknown,
    itemName: item.name || unknown,
    itemType: item.description || unknown,
    itemColor: item.description ? item.description?.split(",")?.[1] : unknown,
    imageSmall: item.smallImageUrl || item.imageUrl || unknown,
    hfb: item.hfbNo || unknown,
  } as Article;
}
