import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useQuery } from "@tanstack/react-query";

type Items = {
  Colour: string;
  ColourDescription: string;
  Images: unknown[];
  IsAvailableInStore: true;
  IsBreathTakingItem: false;
  ItemId: string;
  ItemType: string;
  ItemUnitCode: string;
  LatestSalesDate?: string;
  Name: string;
  PaNumber: string;
  PredictedAssemblyTime: number;
  ProductType: string;
};

const fetchMFSSearchData = async (query: string) => {
  const url = `${TASKS_SERVICE_URL}/v1/products/mfs_search?query=${query}`;
  const response = await fetchAPI("GET", url);
  const items = response?.result?.Items as Items[];
  return items?.[0]?.LatestSalesDate;
};

export function useEdsDateMFSSearch(query: string) {
  return useQuery({
    queryKey: ["mfs", "search", query],
    queryFn: async () => await fetchMFSSearchData(query),
    staleTime: 86400000, // Since this data does not change frequently, it can stay fresh for a long time
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!query,
  });
}
