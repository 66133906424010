import React from "react";
import { useTranslation } from "@coworker/locales";
import { TodaysProgress } from "./TodaysProgress";
import { Rounds } from "../Rounds/Rounds";
import { Filters } from "../Filters/Filters";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import useBooleanParamState from "@coworker/apprestructured/src/shared/hooks/useBooleanParamState";
import { FiltersPopup } from "../Filters/FiltersPopup";
import FilterOptionName from "../Filters/FilterOptionName";

const SHOW_FILTER_OPTIONS = [FilterOptionName.AREA, FilterOptionName.ROUND];

export const ProgressOverview = () => {
  const { t } = useTranslation();

  const {
    value: showFilters,
    setTrue: handleOpenFilters,
    setFalse: handleCloseFilters,
  } = useBooleanParamState("showFilters");

  if (showFilters) {
    return (
      <FiltersPopup
        onClose={handleCloseFilters}
        showOptions={SHOW_FILTER_OPTIONS}
      />
    );
  }

  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("shoppingToolsHeaderString"),
      }}
      noPadding
    >
      <Filters showOptions={SHOW_FILTER_OPTIONS} onOpen={handleOpenFilters} />
      <TodaysProgress />
      <Rounds />
    </FullScreenPopup>
  );
};
