import React, { useState } from "react";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import ListView, { ListViewItem } from "@ingka/list-view";
import { Header } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import { departments } from "./UserFiltersRow/FilterChoiceData";
import {
  InputPopupContainer,
  InputPopupContentBox,
} from "../RoomSettings/styles/styles";
import { StickyButtonMenu } from "../RoomSettings/Molecules/StickyButtonMenu";

interface HFBLocationsProps {
  localHFB: string;
  onSubmit: (data: string) => void;
}

export const HFBLocations = ({ localHFB, onSubmit }: HFBLocationsProps) => {
  const { t } = useTranslation();
  const [selectedHFB, setSelectedHFB] = useState<string>(localHFB);
  const [searchInput, setSearchInput] = useState<string>("");
  const [HFBs, setHFBs] = useState(departments);

  const handleSearch = (value: string) => {
    setSearchInput(value);

    setHFBs(
      departments.filter((department) =>
        department.shortName.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  const handleClear = () => {
    handleSearch("");
  };

  const handleReset = () => {
    setSelectedHFB("all");
    onSubmit("all");
  };

  const handleDone = () => {
    onSubmit(selectedHFB);
  };

  return (
    <>
      <Header
        title={t("selectHFBString")}
        onBack={handleDone}
        showBack
        border
      />

      <InputPopupContainer>
        <FixaSearch
          id="hfb-search"
          value={searchInput}
          onChange={handleChange}
          onClear={handleClear}
          placeholder={t("searchBasicString")}
        />

        {HFBs && (
          <InputPopupContentBox>
            <ListView id={"all-hfbs"} size={"small"} showDivider={false}>
              <ListViewItem
                onChange={() => setSelectedHFB("all")}
                title={t("wholeStoreString").toUpperCase()}
                control={"radiobutton"}
                controlProps={{
                  value: "all",
                  checked: selectedHFB === "all",
                }}
              />
            </ListView>
            {HFBs.map(({ hfbNumber, name }) => (
              <ListView
                id={hfbNumber}
                key={hfbNumber}
                size={"small"}
                showDivider={false}
              >
                <ListViewItem
                  onChange={() => setSelectedHFB(hfbNumber)}
                  title={name}
                  control={"radiobutton"}
                  controlProps={{
                    value: hfbNumber,
                    checked: selectedHFB === hfbNumber,
                  }}
                />
              </ListView>
            ))}
          </InputPopupContentBox>
        )}

        <StickyButtonMenu onReset={handleReset} onDone={handleDone} />
      </InputPopupContainer>
    </>
  );
};
