import Webcam from "react-webcam";
import React from "react";
import styled, { css } from "styled-components";
import { Button, Icon, ScannerZoomSlider } from "@coworker/components";
import { isRDTDevice, isRDTDevice11 } from "@coworker/reusable";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { useInputPopup } from "../InputPopup";
import { Trans } from "@coworker/locales";
import Overlay from "./Overlay";
import {
  useCameraConstraints,
  useScale,
  isTorchOn,
} from "./useCameraCapabilities";
import { useWindowProperties } from "./useWindowProperties";
import useBrowserWorker from "./useBrowserWorker";
import { useVideoCrop } from "./useVideoCrop";
import trackerHelper from "../../helpers/tracker";
import { detectMainCamera } from "./detectMainCamera";
import { scannerLog } from "./log";
import { useMyStore } from "../../hooks/useMyStore";

const StyledLoaderIcon = styled(LoaderIcon)`
  width: 20px;
  height: 20px;
`;

const onUserMediaError = (error) => {
  if (!process.env.JEST_WORKER_ID) console.error(error);
};

const {
  barcode: { logScannerType },
} = trackerHelper;

const AppWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0 2px;
`;

const ControlsContainer = styled.div`
  position: fixed;
  ${({ inPopup }) => css`
    bottom: ${inPopup ? "10px" : "90px"};
  `};
  width: calc(100% - 38px);
  display: flex;
  flex-direction: column;
  margin: 0 19px;
  justify-content: center;
`;

const SliderContainer = styled.div`
  margin: 30px 19px 0;
  flex-grow: 1;
`;

const noop = () => null;
const SCANNING_INTERVAL = 300; //ms

/**
 * Barcode scanner using browser provided BarcodeDetector API
 * @param {{
 *  onBarcode: (barcode: string, ...TODO) => void
 *  switchToRDTScanner: () => void
 *  videoRef: React.MutableRefObject<HTMLVideoElement>
 *  paused: boolean
 *  }} props
 */
export function BrowserScanner({
  onBarcode = noop,
  switchToRDTScanner = noop,
  videoRef,
  paused,
}) {
  /**
   * @type {React.MutableRefObject<{video: HTMLVideoElement, stream: MediaStream}>}
   */
  console.log("BROWSER SCANNER");
  const webcamRef = React.useRef();
  const isScanningRef = React.useRef(false);
  const cropDebugRef = React.useRef();

  // Context
  const { popupOpen: inPopup } = useInputPopup();
  const isRDT = isRDTDevice() || isRDTDevice11();
  const { size, ratio } = useWindowProperties();

  // State
  const [scanning, setScanning] = React.useState(false);
  const [cameraDetectionFailed, setCameraDetectionFailed] =
    React.useState(false);
  const [barcode, setBarcode] = React.useState("");
  const [supplierNumber, setSupplierNumber] = React.useState("");
  const [constraints, setConstraints] = useCameraConstraints(
    webcamRef.current?.stream
  );
  const [cameraCapabilities, setCameraCapabilities] = React.useState();
  const [zoomLevel, setZoom] = useScale();
  const [deviceId, setChosenDeviceId] = React.useState();
  const primaryLocale = useMyStore()?.configuration?.locale?.primary_locale;

  React.useEffect(() => {
    detectMainCamera().then((c) => {
      const { deviceId, label } = c?.device || {};
      if (deviceId) {
        scannerLog(`Choosing camera: "${deviceId} - ${label}"`);
        setChosenDeviceId(deviceId);
      } else {
        scannerLog("No main camera detected", c?.device || c);
        setCameraDetectionFailed(true);
      }
    });
  }, []);

  React.useEffect(() => {
    setConstraints({ zoom: zoomLevel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomLevel]);
  React.useEffect(
    () => videoRef?.current && (videoRef.current = webcamRef.current.video),
    [videoRef]
  );

  // Web worker handling
  const barcodeWorker = useBrowserWorker(
    isScanningRef,
    setBarcode,
    setSupplierNumber,
    setScanning
  );

  const reticule = useVideoCrop(webcamRef.current?.video);
  // Send scanning result to web worker on interval
  React.useEffect(() => {
    let interval;
    let errorCount = 0;
    if (barcodeWorker && !barcode && scanning) {
      function findBarcode() {
        if (isScanningRef.current) return;

        const video = webcamRef.current?.video;
        if (!video || !video?.width || !video?.videoWidth) return; // Avoid trying to scan when no image available. Protect from https://sentry.io/organizations/ingka/issues/2730397843/ and https://sentry.io/organizations/ingka/issues/2739670291

        // Cut out an image within the reticule (with some overscan to allow for inprecise user aim)
        createImageBitmap(video, ...reticule)
          .then((image) => {
            if (window.enableScannerLogging && cropDebugRef.current) {
              // Used to debug image cropping
              scannerLog("drawing");
              cropDebugRef.current.width = image.width;
              cropDebugRef.current.height = image.height;
              cropDebugRef.current
                .getContext("bitmaprenderer")
                .transferFromImageBitmap(image);
            } else {
              isScanningRef.current = true; // Flag that we've sent off an image to avoid sending images faster than the recognition worker can handle.
              barcodeWorker.postMessage(image, [image]);
            }
          })
          .catch((error) => {
            errorCount += 1;
            scannerLog(`Scanner.jsx error count ${errorCount}`, video);
            scannerLog(error);
          });
      }

      interval = setInterval(findBarcode, SCANNING_INTERVAL);
    }
    return () => {
      scannerLog("Clearing interval");
      if (interval) clearInterval(interval);
    };
  }, [barcode, barcodeWorker, reticule, scanning]);

  // Run `onBarcode` callback when a barcode is detected
  React.useEffect(() => {
    if (barcode && !paused) {
      scannerLog("browserScanner: Barcode detected", barcode);
      onBarcode(barcode, undefined, undefined, supplierNumber, primaryLocale);
      logScannerType("BROWSER_SCANNER", barcode);
      setBarcode(null);
      setSupplierNumber(null);
    }
  }, [barcode, onBarcode, paused, supplierNumber, primaryLocale]);

  const videoConstraints = React.useMemo(() => {
    const constraints = { ratio };
    if (deviceId) constraints.deviceId = { exact: deviceId };
    else constraints.facingMode = "environment";
    return constraints;
  }, [deviceId, ratio]);
  const onUserMedia = React.useCallback((stream) => {
    const track = stream.getVideoTracks()[0];
    setCameraCapabilities(track.getCapabilities());
    scannerLog(`track set, deviceId: ${track.label}`);
  }, []);

  return (
    <AppWrapper>
      {window.enableScannerLogging && (
        <canvas // Used to debug image cropping
          ref={cropDebugRef}
          style={{
            position: "absolute",
            zIndex: 100,
            transform: "scale(0.2)",
            transformOrigin: "top left",
          }}
        />
      )}
      <Overlay />
      {ratio && (deviceId || cameraDetectionFailed) && (
        <Webcam
          style={{ backgroundColor: "var(--black)" }}
          audio={false}
          width={size.width}
          height={size.height}
          imageSmoothing={false}
          videoConstraints={videoConstraints}
          ref={webcamRef}
          onUserMedia={onUserMedia}
          onUserMediaError={onUserMediaError}
        />
      )}
      <ControlsContainer inPopup={inPopup}>
        <ButtonContainer>
          {isRDT && (
            <Button
              primary
              dark
              text={<Icon family="icons" name="rdt_scanner" color="white" />}
              onClick={switchToRDTScanner}
              flexNoGrow
              style={{ marginRight: "9px" }}
            />
          )}
          <Button
            primary
            dark
            data-testid="pushToScan"
            flexGrow
            text={scanning ? <StyledLoaderIcon /> : <Trans>scanString</Trans>}
            onClick={() => {
              setScanning(!scanning);
            }}
          />
          {cameraCapabilities?.torch && (
            <Button
              primary
              dark
              flexNoGrow
              style={{ marginLeft: "9px" }}
              text={
                <Icon
                  family="actions"
                  name={isTorchOn(constraints) ? "torch-off" : "torch-on"}
                  color="white"
                />
              }
              onClick={() => setConstraints({ torch: !isTorchOn(constraints) })}
            />
          )}
        </ButtonContainer>
        {cameraCapabilities?.zoom && (
          <SliderContainer>
            <ScannerZoomSlider
              value={zoomLevel}
              setValue={setZoom}
              min={cameraCapabilities.zoom.min}
              max={cameraCapabilities.zoom.max}
              step={cameraCapabilities.zoom.step}
            />
          </SliderContainer>
        )}
      </ControlsContainer>
    </AppWrapper>
  );
}
