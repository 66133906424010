import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PartialRoom, PartialRoomWithId } from "../types/room";
import { updateRoom } from "../services/rooms.service";
import { QueryKeys } from "./queryKeys";

export const useUpdateRoom = (roomId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PartialRoom) => updateRoom(roomId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.Room, roomId],
      });
    },
  });
};

export const useUpdateRoomWithId = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (partialRoom: PartialRoomWithId) =>
      updateRoom(partialRoom.id, partialRoom),
    onSuccess: (_response, partialRoom) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.Room, partialRoom.id],
      });
    },
  });
};
