import React from "react";
import styled from "styled-components";
import { ReactComponent as OptionsIcon } from "@coworker/reusable/svg/three-dots.svg";
import { ReactComponent as EditIconWhite } from "../../../../assets/svg/customize-task.svg";
import { ReactComponent as CheckmarkIcon } from "../../../../assets/svg/checkmark.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/svg/settings.svg";
import { ReactComponent as MagnifyingGlass } from "../../../../assets/svg/magnifying-glass.svg";
import { useWorkspacesAction } from "../../../../hooks/useWorkspaces";
import { useToggle } from "../../../../hooks/useToggle";
import { ElidedText } from "@coworker/reusable/components/ElidedText";

const PaddedTextHeader = styled.div`
  font-weight: bold;
  padding: 0 30px;
  font-size: 14px;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const ArticleTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  border-bottom: 1px solid var(--grey150);
`;

const Input = styled.input`
  font-size: 16px;
  font-weight: bold;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 1rem;
  height: 24px;
  width: 24px;
`;

const LeftIconContainer = styled(IconContainer)`
  left: 1rem;
`;

const RightIconContainer = styled(IconContainer)`
  right: 1rem;
`;

const EditIcon = styled(EditIconWhite)`
  & > path {
    fill: var(--black);
  }
`;

export interface TitleHeaderProps {
  title: string | JSX.Element;
  editPrefix?: string | undefined;
  customName?: string | undefined;
  navigateToLink?: string;
  showOptions?: boolean;
  showSettings?: boolean;
  showSearch?: boolean;
  onSearch?: () => Promise<void>;
  onOptionsClick?: () => void;
  isEditable?: boolean;
  onNameEdited?: (newName: string) => Promise<unknown>;
}

export function TitleHeader({
  title,
  editPrefix,
  customName,
  navigateToLink,
  onOptionsClick,
  showOptions,
  showSettings,
  showSearch,
  onSearch,
  isEditable,
  onNameEdited,
}: TitleHeaderProps) {
  const { push } = useWorkspacesAction();

  const [isEditing, toggleIsEditing] = useToggle(false);
  const [customTitle, setCustomTitle] = React.useState(customName ?? "");

  function restrictWhiteSpace(e: React.KeyboardEvent) {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input.selectionStart === 13 && e.code === "Space") {
      e.preventDefault();
    }
  }

  return (
    <Container>
      <LeftIconContainer />
      <ArticleTitleContainer>
        {isEditing ? (
          <Input
            id="input"
            onKeyDown={restrictWhiteSpace}
            data-testid="editNameInput"
            autoFocus
            autoComplete="off"
            value={`${editPrefix} - ${decodeURIComponent(customTitle)}`}
            onChange={(e) =>
              setCustomTitle(
                e.target.value.substring((editPrefix?.length ?? 0) + 3)
              )
            }
          />
        ) : (
          <ElidedText>
            <PaddedTextHeader data-testid="titleField">
              {title}
              {!!customTitle ? ` - ${decodeURIComponent(customTitle)}` : ""}
            </PaddedTextHeader>
          </ElidedText>
        )}
      </ArticleTitleContainer>
      {(showOptions || isEditable) && (
        <RightIconContainer
          data-testid="rightIcon"
          onClick={async () => {
            if (isEditable) {
              if (isEditing) {
                await onNameEdited?.(customTitle);
              }
              toggleIsEditing();
            } else if (onOptionsClick) {
              onOptionsClick();
            }
          }}
        >
          {isEditable ? (
            isEditing ? (
              <CheckmarkIcon />
            ) : (
              <EditIcon />
            )
          ) : (
            <OptionsIcon />
          )}
        </RightIconContainer>
      )}
      {showSettings && (
        <RightIconContainer
          data-testid="rightIcon"
          onClick={() => {
            push(navigateToLink);
          }}
        >
          <SettingsIcon />
        </RightIconContainer>
      )}
      {showSearch && (
        <RightIconContainer
          data-testid="rightSearchIcon"
          onClick={() => onSearch && onSearch()}
        >
          <MagnifyingGlass />
        </RightIconContainer>
      )}
    </Container>
  );
}
