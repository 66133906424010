import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { callInternalApi } from "@coworker/app/src/hooks/API/useCallInternal";
import { Task } from "@coworker/types/lib/tasks/base";
import { ActivityFilterType } from "../types/filterOptionsTypes";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { getUserFixaUidsForTeamId } from "@coworker/app/src/hooks/API/core.service";
import {
  useFixaUID,
  useStoreId,
} from "@coworker/app/src/core/auth/useLoggedInUser";

// These are only the properties used for filtering, not ordering
type StrictFilters = Partial<
  Pick<
    ActivityFilterType,
    | "taskType"
    | "createdBy"
    | "assignedTo"
    | "locations"
    | "createdBy"
    | "priority"
    | "page"
  >
>;

const STALE_AFTER = 1000 * 60 * 5;
const getTasksEndpointUrl = (
  filters: StrictFilters & { state: string; pageAfter: string }
) => {
  let url = `tasks?`;
  for (const key in filters) {
    let value = filters[key as keyof StrictFilters];

    if (!value) {
      continue;
    }

    if (Array.isArray(value)) {
      value = value.join(",");
    }
    if (value !== undefined) {
      url += `${key}=${encodeURIComponent(value)}&`;
    }
  }

  // delete the last "&"
  if (url.endsWith("&")) {
    url = url.slice(0, -1);
  }

  return url;
};

type TaskQueryResult = {
  list: Task[];
  totalCount: number;
  filteredCount: number;
  page?: number;
};

export const useActivityData = (
  filters: StrictFilters,
  callTasksService?: boolean
): {
  openTasks: UseInfiniteQueryResult<TaskQueryResult>;
  inProgressTasks: UseInfiniteQueryResult<TaskQueryResult>;
  completedTasks: UseInfiniteQueryResult<TaskQueryResult>;
  closedTasks: UseInfiniteQueryResult<TaskQueryResult>;
} => {
  // sorted array of filter values to key the query along with keys
  // it is sorted so the query caching doesn't create multiple queries for the same
  // filter values but in different order
  const fixaUid = useFixaUID();
  const storeId = useStoreId();
  let filtersIndex = [];
  for (const key in filters) {
    if (filters[key as keyof StrictFilters]) {
      filtersIndex.push(`${key}=${filters[key as keyof StrictFilters]}`);
    }
  }
  filtersIndex.sort();

  const getRelevantUsersFixaUidsCSV = async (
    teamIdOrFixaUid: string | undefined
  ) => {
    if (!teamIdOrFixaUid) {
      return "";
    }

    if (fixaUid === teamIdOrFixaUid) {
      return fixaUid;
    } else {
      const teamsFixaUids = await getUserFixaUidsForTeamId(teamIdOrFixaUid);
      if (teamsFixaUids.data.length === 0) {
        return "emptyTeam";
      } else {
        return teamsFixaUids.data.join(",");
      }
    }
  };

  const addTeamFiltersToQueryOptions = async (
    queryOptions: StrictFilters,
    createdBy: string,
    assignedTo: string
  ) => {
    if (createdBy !== "") {
      queryOptions.createdBy = await getRelevantUsersFixaUidsCSV(createdBy);
    }
    if (assignedTo !== "") {
      queryOptions.assignedTo = assignedTo;
    }
  };

  const getTasksQueryFunction = async (
    state: string,
    { pageParam = callTasksService ? 1 : "" }: { pageParam: string | number }
  ) => {
    const queryOptions = {
      state: state,
      ...filters,
      page: pageParam as number,
      pageAfter: pageParam as string,
    };

    await addTeamFiltersToQueryOptions(
      queryOptions,
      filters.createdBy || "",
      filters.assignedTo || ""
    );

    return await callInternalApi(getTasksEndpointUrl(queryOptions), {
      fixaUid,
      store_id: storeId,
    }).then((response) => {
      return { ...response.data, page: pageParam };
    });
  };

  const getNextPageParam = (lastPage: TaskQueryResult) => {
    if (lastPage.list && lastPage.list.length > 0) {
      if (callTasksService) {
        return lastPage.page! + 1;
      } else {
        return lastPage?.list[lastPage.list.length - 1]?.id;
      }
    }
    if (callTasksService) {
      return 1;
    }
    return "";
  };

  const openTasksQueryResult = useInfiniteQuery<TaskQueryResult>(
    ["activityTasks", "openTasks", ...filtersIndex],
    async ({ pageParam }) => {
      return getTasksQueryFunction("open", { pageParam });
    },
    {
      getNextPageParam: getNextPageParam,
      staleTime: STALE_AFTER,
    }
  );

  const inProgressTasksQueryResult = useInfiniteQuery<TaskQueryResult>(
    ["activityTasks", "inProgressTasks", ...filtersIndex],
    async ({ pageParam }) => {
      return getTasksQueryFunction("in_progress", { pageParam });
    },
    {
      getNextPageParam: getNextPageParam,
      staleTime: STALE_AFTER,
    }
  );

  const completedTasksQueryResult = useInfiniteQuery<TaskQueryResult>(
    ["activityTasks", "completedTasks", ...filtersIndex],
    async ({ pageParam }) => {
      return getTasksQueryFunction("completed", { pageParam });
    },
    {
      getNextPageParam: getNextPageParam,
      staleTime: STALE_AFTER,
    }
  );

  const closedTasksQueryResult = useInfiniteQuery<TaskQueryResult>(
    ["activityTasks", "closedTasks", ...filtersIndex],
    async ({ pageParam }) => {
      return getTasksQueryFunction("closed", { pageParam });
    },
    {
      getNextPageParam: getNextPageParam,
      staleTime: STALE_AFTER,
    }
  );

  return {
    openTasks: openTasksQueryResult,
    inProgressTasks: inProgressTasksQueryResult,
    completedTasks: completedTasksQueryResult,
    closedTasks: closedTasksQueryResult,
  };
};

export const useActivityLocations = (
  taskType: string | false = "all"
): UseQueryResult<{ id: string; transKey: string }[]> => {
  const storeId = useStoreId();
  return useQuery<{ id: string; transKey: string }[]>({
    queryKey: ["locations", taskType],
    queryFn: async () => {
      const callUrl =
        taskType === "all" || taskType === false || taskType === ""
          ? "locations/allfromtasks"
          : "locations/allfromtasks?taskType=" + taskType;
      const response = await callInternalApi(callUrl, { store_id: storeId });
      return response.data
        .filter((location: string) => {
          return location !== "" && location !== null;
        })
        .map((location: string) => ({
          id: location,
          transKey: location,
        }));
    },
    staleTime: STALE_AFTER,
  });
};
