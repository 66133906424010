import Button from "@ingka/button";
import React from "react";
import { SizedText } from "../../styles/styles";
import { Articles } from "../../types/article";
import { useStoreId, useUserId } from "../../../../core/auth/useLoggedInUser";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import { setOngoingTasksToClosed } from "../../tools";
import { Trans, useTranslation } from "@coworker/locales";
import { ModalTypes } from "../../types/views";
import { useTasksByRoom } from "../../hooks/useTasksByRoom";
import { OngoingTaskLinksResponse } from "../../types/taskLink";

import trashCan from "@ingka/ssr-icon/paths/trash-can"; // NOSONAR
import { logEvent } from "../../../../helpers/tracker";
import { useDeleteArticlesByIds } from "../../hooks/useDeleteArticlesByIds";
import { useToastNotification } from "@coworker/components";
import { getAllArticlesBasedOnProductArticleId } from "./GroupModal.helper";

interface DeleteSelectedArticlesModalProps {
  modalVisibility: boolean;
  selectedArticles: Articles;
  roomId: string;
  onTriggerNewModal: (modalType: ModalTypes) => void;
  allArticles: Articles;
}

export const DeleteSelectedArticlesModal = ({
  modalVisibility,
  selectedArticles,
  roomId,
  onTriggerNewModal,
  allArticles,
}: DeleteSelectedArticlesModalProps) => {
  const { t } = useTranslation();
  const uid = useUserId();
  const storeId = useStoreId();
  const roomTasks = useTasksByRoom(roomId);
  const deleteArticles = useDeleteArticlesByIds(roomId);
  const { showToast } = useToastNotification();

  const [deleting, setDeleting] = React.useState(false);

  const handleDeleteArticles = async () => {
    let articleIdsToDelete = selectedArticles.map((article) => article.id);

    articleIdsToDelete = getAllArticlesBasedOnProductArticleId(
      allArticles,
      selectedArticles
    ).map((article) => article.id);

    const tasksToBeClosed = roomTasks.tasks.filter((task) =>
      articleIdsToDelete.includes(task.article_id)
    );
    await setOngoingTasksToClosed(
      roomId,
      t("taskClosedBecauseRemovedArticleString"),
      {
        count: tasksToBeClosed.length,
        tasks: tasksToBeClosed,
      } as OngoingTaskLinksResponse,
      uid,
      storeId
    );
    try {
      await deleteArticles.mutateAsync(articleIdsToDelete);
    } catch (error) {
      alert(t("searchErrorString"));
    }
    onTriggerNewModal(ModalTypes.DEFAULT_VIEW);
    logEvent("ce:delete_selected_articles");

    showToast(t("articlesDeletedString"));
  };

  return (
    <Modal
      visible={modalVisibility}
      handleCloseBtn={() => onTriggerNewModal(ModalTypes.KEEP_SELECTION)}
    >
      <Prompt
        title={""}
        titleId={`delete-${roomId}`}
        header={<ModalHeader title={""} ariaCloseTxt="Close prompt." />}
        footer={
          <ModalFooter>
            <Button
              text={t("cancelString")}
              type="secondary"
              onClick={() => onTriggerNewModal(ModalTypes.KEEP_SELECTION)}
            />
            <Button
              text={t("deleteString")}
              ssrIcon={trashCan}
              onClick={async () => {
                setDeleting(true);
                await handleDeleteArticles();
              }}
              style={{
                backgroundColor: "var(--pink)",
                color: "var(--white)",
              }}
              loading={deleting}
            />
          </ModalFooter>
        }
      >
        <>
          <SizedText $fontSize={16} $bold={true}>
            <Trans values={{ item: roomId }}>
              sureToDeleteSelectedArticlesString
            </Trans>
          </SizedText>
          <SizedText $fontSize={14} $bold={false}>
            <Trans>allSelectedArticlesWillBeDeletedIncludingGroupsString</Trans>
          </SizedText>
          <SizedText $fontSize={14} $bold={false}>
            <Trans>allOngoingTasksWillBeClosedString</Trans>
          </SizedText>
        </>
      </Prompt>
    </Modal>
  );
};
