import Button from "@ingka/button";
import React from "react";
import { SizedText } from "../../styles/styles";
import { Article } from "../../types/article";
import { useTasksByArticle } from "../../hooks/useTasksByArticle";
import { useStoreId, useUserId } from "../../../../core/auth/useLoggedInUser";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import { setOngoingTasksToClosed } from "../../tools";
import { Trans, useTranslation } from "@coworker/locales";
import { useDeleteArticlesByIds } from "../../hooks/useDeleteArticlesByIds";
import { useToastNotification } from "@coworker/components";
import { useArticlesByRoomId } from "../../hooks/useArticlesByRoomId";
import { getAllArticlesBasedOnProductArticleId } from "./GroupModal.helper";
import { MediumSkeletonBox } from "../../Molecules/skeletons/MediumSkeletonBox";

interface DeleteArticleModalProps {
  article: Article;
  onModalOpenChange: (modalOpen: boolean) => void;
  postDeleteFunction?: () => Promise<void>;
  deleteByArticleId: boolean;
}

export const DeleteArticleModal = ({
  article,
  onModalOpenChange,
  postDeleteFunction,
  deleteByArticleId,
}: DeleteArticleModalProps) => {
  const { showToast } = useToastNotification();
  const { t } = useTranslation();
  const uid = useUserId();
  const storeId = useStoreId();
  const tasks = useTasksByArticle(storeId, article.productArticleId);
  const deleteArticles = useDeleteArticlesByIds(article.roomId);
  const { data: articlesInRoom } = useArticlesByRoomId(article.roomId);

  const handleDeleteArticle = async () => {
    await setOngoingTasksToClosed(
      article.roomId,
      t("taskClosedBecauseRemovedArticleString"),
      tasks,
      uid,
      storeId
    );
    try {
      if (!deleteByArticleId) {
        const articlesToDelete = getAllArticlesBasedOnProductArticleId(
          articlesInRoom ?? [],
          [article]
        );
        await deleteArticles.mutateAsync(articlesToDelete.map((a) => a.id));
      } else {
        await deleteArticles.mutateAsync([article.id]);
      }
      if (postDeleteFunction) await postDeleteFunction();
    } catch (error) {
      alert(t("searchErrorString"));
    }
    onModalOpenChange(false);
    showToast(t("articleDeletedString"));
  };

  if (!articlesInRoom)
    return (
      <Modal visible={true} handleCloseBtn={() => onModalOpenChange(false)}>
        <Prompt
          title={""}
          titleId={`delete-${article.id}`}
          header={
            <ModalHeader
              title={t("deleteNameString", { name: article.itemName })}
              ariaCloseTxt="Close prompt."
            />
          }
          footer={<></>}
        >
          <MediumSkeletonBox />
        </Prompt>
      </Modal>
    );

  const headline = !deleteByArticleId ? (
    <Trans
      values={{
        articleName: article.itemName,
        mediaName: t("roomSettingsString"),
      }}
    >
      surelyDeleteArticleFromAllGroupsString
    </Trans>
  ) : (
    <Trans values={{ item: article.itemName }}>surelyDeleteString</Trans>
  );

  let tasksWillBeClosed = <Trans>deleteRoomSettingTasksClosedString</Trans>;
  if (article.groupName) {
    tasksWillBeClosed = <Trans>deleteArticleInGroupSetTasksClosedString</Trans>;
  } else {
    tasksWillBeClosed = (
      <Trans values={{ mediaName: t("roomSettingsString") }}>
        deleteArticleSetTasksClosedString
      </Trans>
    );
  }

  return (
    <>
      <Modal visible={true} handleCloseBtn={() => onModalOpenChange(false)}>
        <Prompt
          title={""}
          titleId={`delete-${article.id}`}
          header={
            <ModalHeader
              title={t("deleteNameString", { name: article.itemName })}
              ariaCloseTxt="Close prompt."
            />
          }
          footer={
            <ModalFooter>
              <Button
                text={t("cancelString")}
                type="secondary"
                onClick={() => onModalOpenChange(false)}
              />
              <Button
                text={t("deleteString")}
                type="danger"
                onClick={handleDeleteArticle}
              />
            </ModalFooter>
          }
        >
          <>
            <SizedText $fontSize={16} $bold={true}>
              {headline}
            </SizedText>
            <SizedText $fontSize={14} $bold={false}>
              {tasksWillBeClosed}
              &nbsp;
              <Trans>canNotBeUndoneString</Trans>
            </SizedText>
          </>
        </Prompt>
      </Modal>
    </>
  );
};
