import { useQuery } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { Item } from "../../types/item";
import { formatProductIdWithDots } from "@coworker/reusable";

export async function fetchItemInfoAndPriceSR(itemNo: string) {
  const url = `${TASKS_SERVICE_URL}/items/item-info-price-sr?itemNo=${itemNo}`;
  const { result: itemInfo } = await fetchAPI("GET", url);
  return itemInfo
    ? ({
        ...itemInfo,
        noFormatted: formatProductIdWithDots(itemInfo?.no),
      } as Item)
    : undefined;
}

export function useItemInfoAndPriceSR(itemNo: string) {
  return useQuery({
    queryKey: ["item", "sr", itemNo],
    queryFn: async () => await fetchItemInfoAndPriceSR(itemNo),
    enabled: !!itemNo,
    staleTime: 86400000, // Since this data does not change frequently, it can stay fresh for a long time
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
