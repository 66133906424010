import { cleanupProductTitle } from "@coworker/reusable";
import { useItemsInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemsInfo";

export function useCleanProductTitle(someId, override) {
  const name = (override !== "?" && override) || "";
  return cleanupProductTitle(name);
}

export function useProductsMap(itemNos) {
  let itemsMap = {};
  const { data: itemsInfo, isLoading } = useItemsInfo(itemNos);
  itemsMap =
    !isLoading &&
    itemsInfo &&
    Object.fromEntries(itemsInfo?.map((item) => [item.no, item]));
  return { itemsMap, isLoading };
}
