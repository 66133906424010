import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  background: var(--grey150);
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  color: var(--grey900);
`;

export const Content = styled.div`
  > *:not(:last-child) {
    margin-bottom: 10px;
  }
  ${(props) => (props.noPadding ? `padding: 0px` : `padding:10px`)}
`;

export const Header = styled.div`
  display: flex;
  background: var(--white);
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  padding-top: 25px;
  height: 40px;
  line-height: 50px;
`;
