import { useQuery } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";

export async function fetchItemPriceAndCurrency(itemNo: string) {
  const url = `${TASKS_SERVICE_URL}/items/item-price?itemNo=${itemNo}`;
  const { result } = await fetchAPI("GET", url);
  return result?.[itemNo]?.[0] ?? {}; //TODO check logic for secondary price
}

// fetching price from SR
export function useItemPriceAndCurrency(itemNo: string) {
  return useQuery({
    queryKey: ["item", "price", itemNo],
    queryFn: async () => await fetchItemPriceAndCurrency(itemNo),
    staleTime: 7200000, // 1 day, considered fresh for a day.
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!itemNo,
  });
}
