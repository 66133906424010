import React from "react";
import { ArticleIdNbrArticles, Articles } from "../types/article";
import { Room } from "../types/room";
import { ArticleListItemMobile, ListWrapper } from "../styles/styles";
import { Groups } from "../types/groups";
import {
  AggregatedArticle,
  useAggregatedArticles,
} from "../hooks/useAggregatedArticles";
import {
  hasOngoingTask,
  isBackupForProduct,
  isMainArticle,
  selectArticleIdNbrArticles,
} from "../Molecules/RoomArticles.helper";
import { useTasksByRoom } from "../hooks/useTasksByRoom";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import { joinProductArticleIds, sortAndFilterArticles } from "../tools";
import { useEDSData } from "../hooks/useEDSData";
import { useSalesStopData } from "../hooks/useSalesStopData";
import { AggregatedArticleListItem } from "../Molecules/AggregatedArticleListItem";
import { SearchBar } from "../Molecules/SearchBar";
import { useTranslation } from "@coworker/locales";
import { SelectArticles } from "../Molecules/SelectArticles";
import { ArticleFilters } from "../Molecules/ArticleFilters";
import { ArticleFilterTypes, ArticleSortingTypes } from "../constants";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import preferences from "@coworker/functions/src/enums/profilePreferences.json";
import IngkaButton from "@ingka/button";
import "@ingka/commercial-message/dist/style.css";
import styled from "styled-components";

const ArticleFiltersContainer = styled.div`
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid var(--grey200);
`;

const SearchBarContainer = styled.div`
  padding: 16px;
`;

const SelectButtonContainer = styled.div`
  padding: 16px;
  text-align: center;
  position: flex;
  top: 56px;
  background-color: white;
  z-index: 1;
`;

interface AggregatedArticleListingProps {
  articles: Articles;
  room: Room;
  groups?: Groups | undefined;
  isSelectArticlesMode: boolean;
  getSelectedArticleIds: (
    fetchSelectedArticleIdsFunction: () => ArticleIdNbrArticles[]
  ) => void;
  setSelectArticleMode: () => void;
}
export const AggregatedArticleListing = ({
  articles,
  room,
  groups,
  isSelectArticlesMode,
  getSelectedArticleIds,
  setSelectArticleMode,
}: AggregatedArticleListingProps) => {
  const { t } = useTranslation();

  const [filter] = useUserPreference(
    preferences.RS_ARTICLE_FILTER,
    ArticleFilterTypes.ALL
  );
  const [sort] = useUserPreference(
    preferences.RS_ARTICLE_SORT_FILTER,
    ArticleSortingTypes.PRIORITY
  );
  const [hfb] = useUserPreference(preferences.RS_ARTICLE_HFB_FILTER, "all");

  const { taskLinks } = useTasksByRoom(room.id);
  const countryId = useCountryId();
  const articleNumbers = joinProductArticleIds(articles);
  const { data: edsData } = useEDSData(countryId, articleNumbers);
  const { data: salesStopData } = useSalesStopData(
    room.store_id,
    articleNumbers
  );
  const uniqueArticles = useAggregatedArticles(articles);

  const [selectedArticleIdsNbrArticles, setSelectedArticleIdsNbrArticles] =
    React.useState<ArticleIdNbrArticles[]>([]);
  const [articleSearchResult, setArticleSearchResult] =
    React.useState(uniqueArticles);
  const [sortedAndFilteredArticles, setSortedAndFilteredArticles] =
    React.useState(uniqueArticles);
  const totalPiecesCount = uniqueArticles.reduce(
    (accumulator, currentValue) => (accumulator += currentValue.nbrArticles),
    0
  );

  const filteredArticles = sortedAndFilteredArticles.filter(
    (sortedAndFilteredArticle) =>
      articleSearchResult.some(
        (searchedArticle) =>
          searchedArticle.productArticleId ===
          sortedAndFilteredArticle.productArticleId
      )
  );

  React.useEffect(() => {
    if (!isSelectArticlesMode) setSelectedArticleIdsNbrArticles([]);
  }, [isSelectArticlesMode]);

  React.useEffect(() => {
    const sortedArticles = sortAndFilterArticles(
      uniqueArticles,
      filter ?? ArticleFilterTypes.ALL,
      sort ?? ArticleSortingTypes.PRIORITY,
      hfb ?? "all",
      taskLinks,
      edsData ?? [],
      salesStopData ?? [],
      room
    );

    // Prevent infinite loop, if the articles are the same, do not update the state
    if (
      JSON.stringify(sortedArticles) ===
      JSON.stringify(sortedAndFilteredArticles)
    ) {
      return;
    }

    setArticleSearchResult(uniqueArticles);
    setSortedAndFilteredArticles(sortedArticles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edsData, salesStopData, taskLinks, filter, sort, hfb]);

  getSelectedArticleIds(() => {
    return selectedArticleIdsNbrArticles;
  });

  const handleSortedArticles = (
    selectedFilter: string,
    selectedSort: string,
    selectedHFB: string
  ) => {
    const initialArticles = [...uniqueArticles];
    const sortedArticles = sortAndFilterArticles(
      initialArticles,
      selectedFilter as ArticleFilterTypes,
      selectedSort as ArticleSortingTypes,
      selectedHFB,
      taskLinks,
      edsData ?? [],
      salesStopData ?? [],
      room
    );

    setSortedAndFilteredArticles(sortedArticles);
  };

  return (
    <>
      <ArticleFiltersContainer>
        <ArticleFilters onArticlesChange={handleSortedArticles} />
      </ArticleFiltersContainer>
      <SearchBarContainer>
        <SearchBar
          articles={uniqueArticles}
          onSearchResults={(result: AggregatedArticle[]) =>
            setArticleSearchResult(result)
          }
        />
      </SearchBarContainer>
      {uniqueArticles.length > 0 && (
        <SelectButtonContainer>
          <IngkaButton
            type={"secondary"}
            text={
              isSelectArticlesMode
                ? t("cancelString")
                : t("selectArticlesString")
            }
            onClick={setSelectArticleMode}
            fluid
          />
        </SelectButtonContainer>
      )}
      <SelectArticles
        selectedCount={selectedArticleIdsNbrArticles.length}
        piecesCount={totalPiecesCount}
        searchCount={filteredArticles.length}
        isSelected={isSelectArticlesMode}
        onSelectAll={(selectAllChecked: boolean) => {
          if (selectAllChecked) {
            setSelectedArticleIdsNbrArticles(
              uniqueArticles.map((article) => ({
                articleId: article.id,
                nbrArticles: article.nbrArticles,
              }))
            );
          } else {
            setSelectedArticleIdsNbrArticles([]);
          }
        }}
        allArticlesInRoom={uniqueArticles}
      />
      <ListWrapper>
        {filteredArticles.map((uniqueArticle) => (
          <ArticleListItemMobile key={uniqueArticle.id}>
            <AggregatedArticleListItem
              article={uniqueArticle}
              isMainProduct={() =>
                isMainArticle(
                  uniqueArticle.id,
                  room.main_article_id_1,
                  room.main_article_id_2
                )
              }
              isBackupForProduct={() =>
                isBackupForProduct(
                  uniqueArticle.id,
                  room.backup_article_id_1 ?? "",
                  room.backup_article_id_2 ?? ""
                )
              }
              hasOngoingTask={() => hasOngoingTask(uniqueArticle.id, taskLinks)}
              edsData={
                edsData?.find(
                  (eds: { productArticleId: string }) =>
                    eds.productArticleId === uniqueArticle.productArticleId
                ) ?? null
              }
              salesStopData={
                salesStopData?.find(
                  (salesStop) =>
                    salesStop.productArticleId ===
                    uniqueArticle.productArticleId
                ) ?? null
              }
              selectArticleIdsMode={isSelectArticlesMode}
              onSelectArticleIdNbrArticles={(articleIdNbrArticles) =>
                selectArticleIdNbrArticles(
                  articleIdNbrArticles,
                  selectedArticleIdsNbrArticles,
                  setSelectedArticleIdsNbrArticles
                )
              }
              isSelected={
                !!selectedArticleIdsNbrArticles.find(
                  (item) => item.articleId === uniqueArticle.id
                )
              }
              showActionRow={true}
              groups={groups}
            />
          </ArticleListItemMobile>
        ))}
      </ListWrapper>
    </>
  );
};
