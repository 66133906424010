import React, { useCallback, useMemo, useState } from "react";

import styles from "./Linkbar.module.css";
import LinkbarLoading from "./LinkbarLoading";
import LinkbarExpanded from "./LinkbarExpanded";
import LinkbarVariant from "./LinkbarVariant";
import LinkbarMinimal from "./LinkbarMinimal";
import useFirstElementThatFits from "./useFirstElementThatFits";

export type LinkbarItem = {
  label: string;
  to: string;
};

/**
 * Props for the Linkbar component.
 */
export type LinkbarProps = {
  /**
   * Determines if the current label should be rendered as an H1 element.
   * This should be done if no other H1 exists on the page.
   * @default false
   */
  currentAsH1?: boolean;

  /**
   * An array of LinkbarItem objects representing the parent paths.
   * Should not include the home page which is added automatically.
   * @default []
   */
  items?: LinkbarItem[];

  /**
   * The label for the current path.
   */
  currentLabel: string;

  /**
   * Whether to show a linkbar placeholder, e.g. if the
   * items or currentLabel is still being calculated.
   * @default false
   */
  loading?: boolean;

  "data-testid"?: string | undefined;
};

/**
 * A horizontal bar enabling upwards navigation.
 *
 * We keep everything on a single line and adjust the rendered components to
 * fit. This is done by hiding parent items behind a "..." element, which can
 * be clicked to expand all parent items on multiple lines.
 *
 * Programmatically we render many invisible variants of the linkbar and only
 * show the largest one that still fits.
 */
function Linkbar(props: LinkbarProps) {
  // Expands all parent items on multiple lines.
  const [expanded, setExpanded] = useState(false);

  const expand = useCallback(() => {
    setExpanded(true);
  }, []);

  const {
    items = [],
    currentLabel,
    currentAsH1 = false,
    loading = false,
    "data-testid": dataTestId,
  } = props;

  // An array of all linkbar variants to choose from, ordered by preference.
  // All items must accept a style prop and forward its ref to the DOM.
  const linkbarVariants = useMemo(() => {
    return [
      ...Array.from({ length: items.length })
        .map((_, i) => {
          const nbrOfVisibleItems = i + 1;
          return (
            <LinkbarVariant
              currentLabel={currentLabel}
              currentAsH1={currentAsH1}
              onDotsClick={expand}
              nbrOfVisibleItems={nbrOfVisibleItems}
              items={items}
              data-testid={
                dataTestId
                  ? `${dataTestId}_variant_${nbrOfVisibleItems}`
                  : undefined
              }
            />
          );
        })
        .reverse(),
    ];
  }, [currentAsH1, currentLabel, dataTestId, expand, items]);

  // Get the first variant that still fits without overflowing.
  // Also get invisible versions of all linkbar variants; these must be
  // rendered to be measured.
  const { invisibleElementsToMeasure, firstElementThatFits } =
    useFirstElementThatFits(linkbarVariants);

  if (loading) {
    // If items or current page label are still being calculated, show a
    // placeholder linkbar.
    return <LinkbarLoading data-testid={dataTestId} />;
  } else if (expanded) {
    // If the "..." element has been clicked, show all linkbar items on
    // multiple lines.
    return (
      <LinkbarExpanded
        data-testid={dataTestId}
        items={items}
        currentLabel={currentLabel}
        currentAsH1={currentAsH1}
      />
    );
  }

  // Render the invisible variants so they can be measured,
  // as well as the largest element that fits without overflowing.
  // If no element fits, show a linkbar without parent
  // paths where the current page label is truncated.
  return (
    <div data-testid={dataTestId} className={styles["root"]}>
      {invisibleElementsToMeasure}
      {firstElementThatFits || (
        <LinkbarMinimal
          data-testid={dataTestId ? `${dataTestId}_minimal` : undefined}
          currentLabel={currentLabel}
          currentAsH1={currentAsH1}
          onDotsClick={expand}
          items={items}
        />
      )}
    </div>
  );
}

// In order for Linkbar to measure different variants, each variant needs a
// separate ref. These refs are created on mount. If the number of variants
// changes between renders (caused by a change in the number of items) the
// number of created refs will be incorrect and an incorrect variant can
// possibly be used. To prevent this, we create the Linkbar with a key that
// changes if the number of items changes between renders, which in turn will
// force a remount of the Linkbar component, leading to the correct number of
// refs being created and the correct variant being used.
function RemountableLinkbar(props: LinkbarProps) {
  return <Linkbar key={props.items?.length || 0} {...props} />;
}

export default RemountableLinkbar;
