import React from "react";
import { useTranslation } from "@coworker/locales";
import { Text } from "./Text";
import { fontSizeMap } from "../styles/styles";

interface PiecesTextProps {
  pieces: number;
  bold?: boolean | undefined;
  italic?: boolean | undefined;
  grey?: boolean | undefined;
  size?: keyof typeof fontSizeMap;
}

export const PiecesText = ({
  pieces,
  bold,
  italic,
  grey,
  size,
}: PiecesTextProps) => {
  const { t } = useTranslation();
  return (
    <Text
      text={t("piecesCountString", { count: pieces })}
      bold={bold}
      italic={italic}
      grey={grey}
      size={size || "medium"}
    />
  );
};
