import React, { useState } from "react";
import { ArticleListItemActionRow } from "./ArticleListItemActionRow";
import { DeleteArticleModal } from "../Organisms/Modals/DeleteArticleModal";
import "../styles/RoomSettings.css";
import { ArticleIdNbrArticles, EdsData, SalesStopData } from "../types/article";
import "@ingka/commercial-message/dist/style.css";
import { Trans, useTranslation } from "@coworker/locales";
import { useRoomById } from "../hooks/useRoomById";
import { logEvent } from "../../../helpers/tracker";
import { useToastNotification } from "@coworker/components";
import { useCreateTask } from "../../../hooks/TaskActions/useCreateTask";
import { useUserId } from "../../../core/auth/useLoggedInUser";
import { createAutoGeneratedTaskAsCompleted } from "./ArticleListItemActionRow.helper";
import { Reasons } from "../constants";
import { useUpdateArticle } from "../hooks/useUpdateArticle";
import { ArticleListItemInner } from "./ArticleListItemInner";
import { PleaseFillIn } from "../../Reusable/PleaseFillIn";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { AggregatedArticle } from "../hooks/useAggregatedArticles";
import { EditGroupQuantityModal } from "../Organisms/Modals/EditGroupQuantityModal";
import { Groups } from "../types/groups";
import { Room } from "../types/room";
import { Text } from "../Atoms/Text";
import { Row } from "../styles/styles";
import { PiecesText } from "../Atoms/PiecesText";

interface AggregatedArticleListItemProps {
  article: AggregatedArticle;
  isMainProduct?: (articleId: string) => boolean;
  isBackupForProduct?: (articleId: string) => boolean;
  hasOngoingTask?: (articleId: string) => boolean;
  showActionRow?: boolean;
  hideChevron?: boolean;
  edsData?: EdsData | null;
  salesStopData?: SalesStopData | null;
  selectArticleIdsMode?: boolean;
  isSelected?: boolean;
  onSelectArticleIdNbrArticles?: (
    articleIdNbrArticles: ArticleIdNbrArticles
  ) => void;
  groups?: Groups | undefined;
}

export function AggregatedArticleListItem({
  article,
  isMainProduct,
  isBackupForProduct,
  hasOngoingTask,
  showActionRow,
  hideChevron,
  edsData,
  salesStopData,
  selectArticleIdsMode,
  isSelected,
  onSelectArticleIdNbrArticles,
  groups,
}: AggregatedArticleListItemProps) {
  const { t } = useTranslation();
  const { data: room } = useRoomById(article?.roomId || "");

  const [bottomAlertMessage, setBottomAlertMessage] = useState<string>("");
  const [currentNbrArticles, setCurrentNbrArticles] = useState<number>(
    article?.nbrArticles || 0
  );
  const [suppressItemClick, setSuppressItemClick] = useState<boolean>(false);
  const [trashcanModalOpen, setTrashcanModalOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState<null | AggregatedArticle>(
    null
  );

  const { showToast } = useToastNotification();
  const { call: createTask } = useCreateTask();
  const userId = useUserId();
  const { push, pop } = useWorkspacesAction();

  const updateArticle = useUpdateArticle(article.id);

  const handleQuantityChange = async (value: number) => {
    logEvent("ce:MM_articles_quantity_changed");
    if (selectArticleIdsMode && article && currentNbrArticles !== value) {
      setCurrentNbrArticles(value);
      if (value > article.nbrArticles) {
        setBottomAlertMessage(
          t("moveExpandedArticlesInfoString", { newCount: value })
        );
      } else if (value < article.nbrArticles) {
        setBottomAlertMessage(
          t("moveReducedArticlesInfoString", {
            moveCount: value,
            remainingCount: article.nbrArticles - value,
          })
        );
      } else {
        setBottomAlertMessage("");
      }
      if (!!onSelectArticleIdNbrArticles) {
        onSelectArticleIdNbrArticles({
          articleId: article.id,
          nbrArticles: value,
        });
      }
      return;
    }

    if (article.nbrArticles === value) return;
    try {
      article.nbrArticles = value;
      await updateArticle.mutateAsync({
        ...article, //Roomsettings-service expects a full article object when updating
        nbrArticles: value,
      });
    } catch (_error) {
      alert(t("searchErrorString"));
    }
  };

  const onQuantityChange = async (
    article: AggregatedArticle,
    value: number
  ) => {
    if (article.aggregatedArticles.filter((a) => !!a.groupId).length > 0) {
      setShowEditModal(article);
    } else {
      await handleQuantityChange(value);
    }
  };

  const createAutoGeneratedTaskWithReason = async (
    reason: Reasons,
    productArticleId: string
  ) => {
    if (!room) return;
    await createAutoGeneratedTaskAsCompleted(
      userId,
      reason,
      room.id,
      room.name,
      room.store_id,
      article,
      productArticleId,
      t("taskIsAutoCompletedNoteString"),
      createTask
    );
  };

  if (!article) return <></>;

  const isActionRowVisible =
    selectArticleIdsMode && !isSelected ? false : showActionRow;

  const doUseQuantityStepper =
    article.aggregatedArticles.filter((a) => !!a.groupId).length === 0;

  const totalQuantity = () => {
    return doUseQuantityStepper ||
      !isActionRowVisible ||
      selectArticleIdsMode ? (
      <></>
    ) : (
      <Row style={{ margin: "2px 0px 0px 68px" }}>
        <Text text={`${t("totalString")}:`} grey={true} />
        &nbsp;
        <PiecesText
          pieces={article.aggregatedArticles.reduce(
            (acc, a) => acc + a.nbrArticles,
            0
          )}
          bold={true}
          grey={true}
        />
      </Row>
    );
  };

  return (
    <>
      <ArticleListItemInner
        article={article}
        isMainProduct={(isMainProduct && isMainProduct(article.id)) || false}
        isBackupForProduct={
          (isBackupForProduct && isBackupForProduct(article.id)) || false
        }
        hasOngoingTask={(hasOngoingTask && hasOngoingTask(article.id)) || false}
        showActionRow={showActionRow || false}
        hideChevron={!!hideChevron}
        edsData={edsData}
        salesStopData={salesStopData}
        selectArticleIdsMode={selectArticleIdsMode || false}
        isSelected={isSelected || false}
        onSelectArticleIdNbrArticles={onSelectArticleIdNbrArticles}
        currentNbrArticles={currentNbrArticles}
        onClick={() => {
          if (hideChevron || selectArticleIdsMode || suppressItemClick) {
            setSuppressItemClick(false);
            return;
          }
          push(
            `/roomsettings/rooms/${article.roomId}/articles/product_article_id/${article.productArticleId}`
          );
        }}
        isAnchor={!hideChevron}
      >
        {totalQuantity()}
        {isActionRowVisible && (
          <ArticleListItemActionRow
            nbrArticles={article?.nbrArticles || 0}
            onQuantityChange={(value) => onQuantityChange(article, value)}
            selectArticleIdsMode={!!selectArticleIdsMode}
            onDeleteArticle={() => {
              setTrashcanModalOpen(true);
              setSuppressItemClick(true);
              logEvent("ce:MM_articles_selected_deleteproduct");
            }}
            doUseQuantityStepper={doUseQuantityStepper}
          />
        )}
        {!!bottomAlertMessage && isSelected && (
          <div
            style={{
              display: "flex",
              gap: "16px",
              marginTop: "8px",
              marginLeft: "68px",
            }}
          >
            <PleaseFillIn
              show={!!bottomAlertMessage}
              alternateWarning={bottomAlertMessage}
            />
          </div>
        )}
      </ArticleListItemInner>
      {trashcanModalOpen && (
        <DeleteArticleModal
          article={article}
          onModalOpenChange={setTrashcanModalOpen}
          postDeleteFunction={async () => {
            await createAutoGeneratedTaskWithReason(Reasons.REMOVE_ARTICLE, "");
            showToast(<Trans>articleDeletedString</Trans>);
            if (article.isFromArticleView) {
              pop();
            }
          }}
          deleteByArticleId={false}
        />
      )}
      {showEditModal !== null && groups !== undefined && (
        <EditGroupQuantityModal
          aggregatedArticle={showEditModal}
          room={room as Room}
          onClose={() => {
            setShowEditModal(null);
          }}
        />
      )}
    </>
  );
}
