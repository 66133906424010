import React, { useEffect, useState } from "react";

import { AppBar, AppBarProps } from "../AppBar/AppBar";
import {
  useScrollDirection,
  SCROLL_UP,
} from "@coworker/app/src/hooks/useScrollDirection";

import styles from "./full-screen-popup.module.css";
import { ActionBar } from "../ActionBar/ActionBar";

interface FullScreenPopupProps {
  children: React.ReactNode;
  appBarConfig?: AppBarProps;
  noPadding?: boolean;
  actionBarContent?: React.ReactNode;
  isActionBarHiddenOnScroll?: boolean;
  noAppBar?: boolean;
  fullHeightContent?: boolean;
  linkbar?: React.ReactNode;
}

const FullScreenPopup = ({
  children,
  appBarConfig,
  noPadding,
  actionBarContent,
  isActionBarHiddenOnScroll = true,
  noAppBar,
  fullHeightContent = false,
  linkbar,
}: FullScreenPopupProps) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollTarget] = useScrollDirection();
  const [actionBarHeight, setActionBarHeight] = useState(0);

  useEffect(() => {
    if (!scrollContainerRef.current) return;

    setScrollTarget(scrollContainerRef.current);
  }, [scrollContainerRef, setScrollTarget]);

  const popupClassNames = [styles["full-screen-popup"]];
  if (noPadding && !fullHeightContent) {
    popupClassNames.push(styles["no-padding"]);
  }
  return (
    <div className={popupClassNames.join(" ")} ref={scrollContainerRef}>
      {linkbar}
      {!noAppBar && appBarConfig && <AppBar {...appBarConfig} />}
      <div
        className={styles["content"]}
        style={
          fullHeightContent ? { padding: `0 0 ${actionBarHeight}px 0` } : {}
        }
      >
        {children}
      </div>
      {actionBarContent && (
        <ActionBar
          hidden={isActionBarHiddenOnScroll && scrollDirection === SCROLL_UP}
          fullHeightContent={fullHeightContent}
          onHeight={(height) => setActionBarHeight(height)}
        >
          {actionBarContent}
        </ActionBar>
      )}
    </div>
  );
};

export default FullScreenPopup;
