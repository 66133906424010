import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import trackerHelper from "../../helpers/tracker";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import SSRIcon from "@ingka/ssr-icon";
import CloseIcon from "@ingka/ssr-icon/paths/cross";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import Button from "@ingka/button";

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const SectionTitle = styled(Title)`
  padding: 1em 0;
`;

const Section = styled.div`
  padding-bottom: 1em;
  font-size: 14px;
  border-bottom: 1px solid var(--grey200);
`;

export function SafetyAlarmMoreInfo({
  onSubmit,
}: {
  onSubmit: (ok?: boolean) => void | Promise<void>;
}) {
  const { t } = useTranslation();

  const appBarActions: AppBarAction[] = [
    {
      name: "close",
      icon: <SSRIcon paths={CloseIcon} />,
      onClick: () => onSubmit(true),
      position: "right",
    },
  ];
  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("safetyAlarmString"),
        actions: appBarActions,
      }}
      actionBarContent={
        <>
          <Button
            text={<Trans>okiunderstandString</Trans>}
            type="primary"
            onClick={() => {
              onSubmit(true);
              trackerHelper.safetyAlarmInfo.closeOK();
            }}
          />
        </>
      }
    >
      <Title>
        <Trans>safetyAlarmRequiresString</Trans>
      </Title>
      <SectionTitle>
        <Trans>safetyAlarmTitle1String</Trans>
      </SectionTitle>
      <Section>
        <Trans>safetyAlarmText1String</Trans>
      </Section>
      <SectionTitle>
        <Trans>safetyAlarmTitle2String</Trans>
      </SectionTitle>
      <Section>
        <Trans>safetyAlarmText2String</Trans>
      </Section>
      <SectionTitle>
        <Trans>safetyAlarmTitle3String</Trans>
      </SectionTitle>
      <Section>
        <Trans>safetyAlarmText3String</Trans>
      </Section>
      <SectionTitle>
        <Trans>safetyAlarmTitle4String</Trans>
      </SectionTitle>
      <Section>
        <Trans>safetyAlarmText4String</Trans>
      </Section>
    </FullScreenPopup>
  );
}
