import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadImageToCloud } from "../services/images.service";
import { QueryKeys } from "./queryKeys";

export const useUploadImageToCloud = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: uploadImageToCloud,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [QueryKeys.ImagesByRoom],
      });
    },
  });
};
