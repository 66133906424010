import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { RoomsAndArticleCountResponse } from "../types/room";
import { Articles } from "../types/article";

export function getSortedRoomsArticleCount(
  roomsArticleCount: RoomsAndArticleCountResponse[],
  roomId?: string | undefined
): RoomsAndArticleCountResponse[] {
  const sortedByRoomAndName = roomsArticleCount.sort((a, b) => {
    if (roomId) {
      if (a.room_id === roomId) {
        return -1;
      } else if (b.room_id === roomId) {
        return 1;
      }
    }
    return a.name.localeCompare(b.name);
  });

  // Partition into one array per room. This to be able to sort by group later on
  const individualRoomAndArticleCountPerRoom = new Map<
    string,
    RoomsAndArticleCountResponse[]
  >();
  for (const roomArticleCount of sortedByRoomAndName) {
    const room = individualRoomAndArticleCountPerRoom.get(
      roomArticleCount.room_id
    );
    if (room) {
      room.push(roomArticleCount);
    } else {
      individualRoomAndArticleCountPerRoom.set(roomArticleCount.room_id, [
        roomArticleCount,
      ]);
    }
  }

  // Sort each room array by group and reassemble to one array
  const assembledRoomsArticleCount: RoomsAndArticleCountResponse[] = [];
  individualRoomAndArticleCountPerRoom.forEach(
    (individualRoomAndArticleCount) => {
      const sortedByGroup = sortRoomsAndArticleCountByGroup(
        individualRoomAndArticleCount
      );
      assembledRoomsArticleCount.push(...sortedByGroup);
    }
  );

  return assembledRoomsArticleCount;
}

function sortRoomsAndArticleCountByGroup(
  roomsArticleCount: RoomsAndArticleCountResponse[]
): RoomsAndArticleCountResponse[] {
  return roomsArticleCount.sort((a, b) => {
    if (!a.group_name) {
      return -1;
    } else if (!b.group_name) {
      return 1;
    }
    return a.group_name.localeCompare(b.group_name);
  });
}

export function sortArticlesByGroup(articles: Articles) {
  return articles.sort((a, b) => {
    if (!a.groupName) {
      return -1;
    } else if (!b.groupName) {
      return 1;
    }
    return a.groupName.localeCompare(b.groupName);
  });
}

export function reduceRoomsArticleCount(
  roomsArticleCount: RoomsAndArticleCountResponse[]
): RoomsAndArticleCountResponse[] {
  return roomsArticleCount.reduce((acc, cur) => {
    const existingRoom = acc.find((room) => room.room_id === cur.room_id);
    if (existingRoom) {
      existingRoom.nbr_articles += cur.nbr_articles;
    } else {
      acc.push(cur);
    }
    return acc;
  }, [] as RoomsAndArticleCountResponse[]);
}

export function getPrimeTasks(
  roomsArticleCount: RoomsAndArticleCountResponse[],
  tasks: RoomSettings[]
): RoomSettings[] {
  const primeTasks: RoomSettings[] = [];
  roomsArticleCount.forEach(({ article_id }) => {
    const matchedTask = tasks.find((task) => task.article_id === article_id);
    if (matchedTask) {
      primeTasks.push(matchedTask);
    }
  });

  return primeTasks;
}
