import { useQuery } from "@tanstack/react-query";
import { searchArticlesInRooms } from "../services/articles.service";
import { QueryKeys } from "./queryKeys";

export const useSearchArticlesInRoom = (
  storeId: string,
  searchQuery: string
) => {
  const enabled = searchQuery.length > 1;
  return useQuery({
    queryKey: [QueryKeys.SearchArticlesInRooms, { storeId, searchQuery }],
    queryFn: () => searchArticlesInRooms(storeId, searchQuery),
    enabled,
  });
};
