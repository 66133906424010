import React from "react";
import styled from "styled-components";

const SearchResultItem = styled.div`
  min-height: 110px;
  border-bottom: 1px solid var(--grey150);
  display: flex;
  padding-left: 29px;
  cursor: pointer;
  background: var(--white);
`;

const ItemImage = styled.div`
  margin-top: 19px;
  width: 72px;
  min-width: 72px;
  height: 72px;
  background-image: linear-gradient(to top, var(--grey100), var(--grey150));
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const ItemDetail = styled.div`
  flex-grow: 1;
  margin-top: 22px;
  padding-left: 20px;
  & h1 {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--grey800);
  }
  & h2 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--grey700);
    padding-right: 5px;
  }
  padding-bottom: 10px;
`;

function SearchResult({
  itemName,
  description,
  formattedNumber,
  image,
  onClick,
  className,
  testId,
}) {
  const imageStyle = image ? { backgroundImage: `url(${image})` } : {};

  return (
    <SearchResultItem
      onClick={onClick}
      data-testid={testId}
      className={className}
    >
      <ItemImage style={imageStyle} data-testid="productImage" />
      <ItemDetail data-testid="productContent">
        <h1>{itemName}</h1>
        <h2>
          <span data-testid={formattedNumber}>{description}</span>
        </h2>
        <h2>{formattedNumber}</h2>
      </ItemDetail>
    </SearchResultItem>
  );
}

export default SearchResult;
