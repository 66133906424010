import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addImageToRoom } from "../services/images.service";
import { QueryKeys } from "./queryKeys";

export const useAddImageToRoom = (roomId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addImageToRoom,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [QueryKeys.ImagesByRoom, roomId],
      });
    },
  });
};
