import React from "react";
import { useParams } from "react-router-dom";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import { Trans } from "@coworker/locales";
import { useFeatureFlagHooks } from "../useMFAQs.featureToggle";
import UserFiltersRow from "../../InputPopup/UserFiltersRow/UserFiltersRow";
import {
  areaIsWholeStore,
  getAreaFilter,
  defaultFilters,
} from "../../InputPopup/UserFiltersRow/filterChoices";
import { usePeriodTimestamps } from "../../InputPopup/UserFiltersRow/usePeriodTimestamps";
import VoteBox from "./VoteBoxComponent";
import TaskHistory from "./TaskHistoryComponent";
import OtherAreasComponent from "./OtherAreasComponent";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import FullscreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import {
  calculateNumberOfVotes,
  createTaskHistory,
} from "./QuestionsDetailPage.helper";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import MfaqTaskButtonComponent from "./MfaqTaskButtonComponent";
import QuestionActionButtonRowComponent from "./QuestionActionButtonRowComponent";
import { Question } from "../BackendApiRepository/QuestionResponse";

export default function CustomerMFAQDetail() {
  const { useMFAQ } = useFeatureFlagHooks();
  const pageContainerRef = React.useRef<HTMLDivElement | null>(null);
  const store_id = useStoreId();
  const { id } = useParams();

  const [filters] = useLocalFilters() as any;
  const [startDate, endDate] = usePeriodTimestamps(filters);
  const question = useMFAQ(id, startDate, endDate);

  const [questionState, setQuestionState] = React.useState<Question>(question);
  const area = getAreaFilter(filters) ?? "";
  const [mergeClicked, setMergeClicked] = React.useState(false);
  const [currentUpvotes, setCurrentUpvotes] = React.useState(
    calculateNumberOfVotes(question, area)
  );
  React.useEffect(() => {
    setCurrentUpvotes(calculateNumberOfVotes(questionState, area));
  }, [area, question, questionState]);
  React.useEffect(() => {
    setQuestionState(question);
  }, [question]);

  const isWholeStore = areaIsWholeStore(filters);
  const completedTasks = createTaskHistory(question, area);

  const actions = question?.actions;
  if (questionState === undefined) {
    return <div>Loading...</div>;
  }
  const showMfaqTaskButton = !isWholeStore && !mergeClicked;

  return (
    <FullscreenPopup
      noPadding
      actionBarContent={
        showMfaqTaskButton && (
          <MfaqTaskButtonComponent filters={filters} question={questionState} />
        )
      }
      isActionBarHiddenOnScroll={false}
      appBarConfig={{
        title: <Trans>questionStringMFAQ</Trans>,
      }}
    >
      {/* why does this exist? */}
      <div ref={pageContainerRef}>
        <UserFiltersRow
          filters={filters}
          settingsPrefix={profilePreferences.MFAQ_PREFIX}
        />
        <VoteBox
          numberOfVotes={currentUpvotes}
          questionText={questionState?.question_text}
          isWholeStore={isWholeStore}
          questionId={id ?? ""}
          storeId={store_id}
          area={area}
          question={questionState}
          setQuestion={setQuestionState}
          endDate={endDate ?? Date.now()}
        />
        <QuestionActionButtonRowComponent
          questionId={id ?? ""}
          questionText={questionState?.question_text}
          setQuestion={setQuestionState}
          mergeQuestionButtonClicked={setMergeClicked}
        />

        <TaskHistory tasks={completedTasks} actions={actions} />
        <OtherAreasComponent upVotesByArea={questionState?.upvotes_by_area} />
      </div>
    </FullscreenPopup>
  );
}

function useLocalFilters() {
  const [profileFilters] = useUserPreference(
    profilePreferences.MFAQ_PREFIX + profilePreferences.USER_FILTERS,
    defaultFilters
  );
  const [filters, setCurrentFilters] = React.useState(profileFilters);

  React.useEffect(() => {
    setCurrentFilters(profileFilters);
  }, [profileFilters]);

  return [filters, setCurrentFilters];
}
