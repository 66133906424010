import { useQuery } from "@tanstack/react-query";
import { getTaskLinksByStore } from "../services/tasks.service";
import { QueryKeys } from "./queryKeys";

export const useTaskLinksByStore = (storeId: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.TaskLinksByStore, storeId],
    queryFn: () => getTaskLinksByStore(storeId || ""),
    enabled: !!storeId,
  });
};
