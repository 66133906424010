import React from "react";
import styled from "styled-components";
import { PreviewLink } from "../../core/components/Previews";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";
import { parseProduct } from "../../services/products.service";

const Title = styled.p`
  color: var(--black);
  font-weight: bold;
  font-size: 14px;
`;

const Description = styled.p`
  color: var(--grey718);
  font-size: 14px;
`;

export function InsightProductItem({ fullId }) {
  const [shortId] = parseProduct(fullId);
  const { data: basicItem } = useItemInfo(shortId);
  return (
    <PreviewLink to={`/product/${fullId}`} image={basicItem?.smallImageUrl}>
      <Title>{basicItem?.name}</Title>
      <Description>{basicItem?.productTypeName}</Description>
      <Description>{basicItem?.noFormatted}</Description>
    </PreviewLink>
  );
}
