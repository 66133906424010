import React, { forwardRef } from "react";

import Home from "./Home";
import Dots from "./Dots";
import Parent from "./Parent";
import Current from "./Current";
import { LinkbarItem } from "./Linkbar";
import styles from "./Linkbar.module.css";

const LinkbarVariant = forwardRef<
  HTMLDivElement,
  {
    currentLabel: string;
    currentAsH1?: boolean;
    onDotsClick: () => void;
    nbrOfVisibleItems: number;
    items?: LinkbarItem[];
    style?: React.CSSProperties;
    "data-testid"?: string | undefined;
  }
>(
  (
    {
      currentLabel,
      currentAsH1 = false,
      onDotsClick,
      nbrOfVisibleItems,
      items = [],
      style,
      "data-testid": dataTestId,
    },
    ref
  ) => {
    const visibleItems = items.slice(
      items.length - Math.min(Math.max(nbrOfVisibleItems, 0), items.length),
      items.length
    );

    return (
      <div
        data-testid={dataTestId}
        className={styles["linkbar-variant"]}
        ref={ref}
        style={style}
      >
        <Home />
        {nbrOfVisibleItems < items.length && <Dots onClick={onDotsClick} />}
        {visibleItems.map(({ label, to }, i) => (
          <Parent key={i} label={label} to={to} />
        ))}
        <Current label={currentLabel} asH1={currentAsH1} />
      </div>
    );
  }
);

export default LinkbarVariant;
