import React from "react";
import { parseProduct } from "../services/products.service";
import { useItemStock } from "@coworker/apprestructured/src/shared/hooks/item/useItemStock";

export function getIndicatorColor(
  loading: boolean,
  available: number,
  elevated: null | number
) {
  if (loading) {
    return "transparent";
  } else if (available > 0) {
    return "--success";
  } else if (available === 0 && elevated && elevated > 0) {
    return "--warning";
  } else if (available === 0 && elevated === 0) {
    return "--error";
  } else {
    return "--grey500";
  }
}

interface StockInfo {
  available: number;
  elevated: number | null;
  loading: boolean;
  color?: string;
}

export function useAvailableElevatedStock(full_id: string) {
  const [short_id, type] = parseProduct(full_id);

  const { data: itemStockInfo, isLoading: isLoadingItemStockInfo } =
    useItemStock(type, short_id);

  return React.useMemo(() => {
    let available = itemStockInfo?.available;
    let elevated = itemStockInfo?.elevated;
    if (available) available = parseInt("" + available);
    if (elevated) elevated = parseInt("" + elevated);
    const color = getIndicatorColor(
      isLoadingItemStockInfo,
      available,
      elevated
    );
    return {
      loading: isLoadingItemStockInfo,
      available,
      elevated,
      color,
    } as StockInfo;
  }, [isLoadingItemStockInfo, itemStockInfo]);
}
