import React from "react";
import {
  CenterContainer,
  LeftContainer,
  FlexibleRowContainer,
} from "../../styles/styles";
import Skeleton from "@ingka/skeleton";

export const MediumSkeletonListItem = () => {
  return (
    <>
      <FlexibleRowContainer style={{ padding: "20px 24px" }}>
        <LeftContainer>
          <Skeleton height={"48px"} width={"48px"} />
        </LeftContainer>
        <CenterContainer $centerText={false} style={{ gap: "5px" }}>
          <Skeleton height={"14px"} width={"100%"} />
          <Skeleton height={"14px"} width={"100%"} />
          <Skeleton height={"14px"} width={"100%"} />
        </CenterContainer>
      </FlexibleRowContainer>
    </>
  );
};
