import { useQuery } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { Item } from "../../types/item";
import { formatProductIdWithDots } from "@coworker/reusable";

export async function fetchItemsInfo(itemNos: string) {
  const url = `${TASKS_SERVICE_URL}/items/items-info?itemNos=${itemNos}`;
  const { result } = await fetchAPI("GET", url);
  return result?.map((item: Item) => {
    return {
      ...item,
      noFormatted: formatProductIdWithDots(item?.no),
    } as Item;
  });
}

// Fetches Items info from Items table if found or SR
// Doesn't contain price and currency info
export const useItemsInfo = (itemNos: string[]) => {
  const uniqueItemNos = [...new Set(itemNos)].join(",");
  return useQuery({
    queryKey: ["items", "info", itemNos],
    queryFn: async () => (await fetchItemsInfo(uniqueItemNos)) as Item[],
    enabled: !!itemNos && itemNos?.length > 0,
    staleTime: 86400000, // Since this data does not change frequently, it can stay fresh for a long time
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
