import React from "react";
import { LoadingBall } from "@ingka/loading";
import "@ingka/loading/dist/style.css";

type FixaLoadingBallProps = {
  size: "small" | "medium" | "large";
};

const FixaLoadingBall: React.FC<FixaLoadingBallProps> = (
  props: FixaLoadingBallProps
) => {
  return <LoadingBall {...props} />;
};

export default FixaLoadingBall;
