import React from "react";
import Button from "@ingka/button";
import styles from "./load-more.module.css";
import { Trans } from "@coworker/locales";

interface LoadMoreProps {
  totalCount: number;
  loadedItemsCount: number;
  handleClick: () => void;
  isFetching?: boolean;
}

export const LoadMore = ({
  totalCount,
  loadedItemsCount,
  handleClick,
  isFetching,
}: LoadMoreProps) => {
  return (
    <div className={styles["load-more"]}>
      <div className={styles["content-wrapper"]}>
        <div className={styles["counts"]}>
          <Trans i18nKey={"loadMoreCountsString"}>
            {{ loadedItemsCount }}
            {{ totalCount }}
          </Trans>
        </div>
        <div className={styles["bar"]}>
          <div
            className={styles["progress"]}
            style={{ width: `${(loadedItemsCount / totalCount) * 100}%` }}
          />
        </div>
        <Button
          onClick={handleClick}
          text={<Trans>loadMore</Trans>}
          type="secondary"
          size="small"
          fluid
          loading={isFetching!!}
        />
      </div>
    </div>
  );
};
