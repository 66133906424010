import React from "react";
import { Trans } from "@coworker/locales";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import {
  shouldDisplayCloseTask,
  shouldDisplayDropTask,
  shouldDisplayEditTask,
  shouldDisplayReassignTask,
  getEditLink,
} from "../../components/TaskDetail/actions/helpers";
import { useInputPopup } from "../../components/InputPopup/index";
import { useOnline } from "../../core/hooks/useOnline";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../hooks/useWorkspaces";
import { useReassignTask } from "../../hooks/TaskActions/useReassignTask";
import { useDropTask } from "../../hooks/TaskActions/useDropTask";
import { useCloseTask } from "../../hooks/TaskActions/useCloseTask";
import { useUserId, useTeamId } from "../../core/auth/useLoggedInUser";
import type {
  UsableTask,
  TeamAndUserIds,
} from "../../components/TaskDetail/actions/helpers";
import trackerHelper from "../../helpers/tracker";
import { useQueryClient } from "@tanstack/react-query";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";

function useTaskMenuActions(task: UsableTask) {
  const { closePopup } = useInputPopup();
  const uid = useUserId();
  const team_id = useTeamId();
  const isPlanned = task.task_type === taskTypeOptions.PLANNED;
  const { isOnline } = useOnline();
  const { pop } = useWorkspacesAction();
  const { toggleModal } = React.useContext(ModalContext);
  const { navigationPath } = useWorkspacesState();
  const queryClient = useQueryClient();
  const isDetailPage = navigationPath?.includes("/task/");
  const isActivityOrHomepage =
    navigationPath === "/activity" || navigationPath === "/";

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["activityTasks"] });
    queryClient.invalidateQueries({ queryKey: ["locations"] });
    queryClient.invalidateQueries({ queryKey: ["tasks"] });
  };

  const { call: reassignTask } = useReassignTask(task, task.id, {
    undo: !isPlanned,
    afterUndo: () => {
      invalidateQueries();
    },
  });
  const { call: dropTask } = useDropTask(task, task.id, {
    undo: !isPlanned,
    afterUndo: () => {
      invalidateQueries();
      if (!isDetailPage && !isActivityOrHomepage) {
        toggleModal({
          type: ModalType.TaskDetail,
          payload: { taskId: task.id },
        });
      }
    },
  });
  const { call: closeTask } = useCloseTask(task, task.id, {
    undo: !isPlanned,
    afterUndo: () => {
      invalidateQueries();
      if (!isDetailPage && !isActivityOrHomepage) {
        toggleModal({
          type: ModalType.TaskDetail,
          payload: { taskId: task.id },
        });
      }
    },
  });

  return [
    {
      isHidden: !shouldDisplayEditTask(task),
      isDisabled: !isOnline,
      to: getEditLink(task.task_type, task.id),
      title: <Trans>editTaskString</Trans>,
      onClick: () => {
        closePopup();
        toggleModal({ type: ModalType.TaskDetail });
      },
      enum: "EDIT",
    },
    {
      isHidden: !shouldDisplayReassignTask(task),
      isDisabled: !isOnline,
      onClick: () =>
        reassignTask().then((done) => {
          invalidateQueries();
          closePopup();
          toggleModal({ type: ModalType.TaskDetail });
          if (done && isDetailPage) pop();
        }),
      title: <Trans>reassignTaskString</Trans>,
      enum: "REASSIGN",
    },
    {
      isHidden: !shouldDisplayDropTask(task, {
        uid,
        team_id,
      } as TeamAndUserIds),
      isDisabled: !isOnline,
      onClick: () =>
        dropTask().then((done) => {
          invalidateQueries();
          closePopup();
          toggleModal({ type: ModalType.TaskDetail });
          if (done && isDetailPage) pop();
        }),
      title:
        task.assigned_user_id === uid ? (
          <Trans>dropString</Trans>
        ) : (
          <Trans>releaseString</Trans>
        ),
      enum: "DROP",
    },
    {
      isHidden: !shouldDisplayCloseTask(task),
      isDisabled: !isOnline,
      onClick: () =>
        closeTask().then((done) => {
          invalidateQueries();
          closePopup();
          toggleModal({ type: ModalType.TaskDetail });
          if (done && isDetailPage) pop();
        }),
      title: <Trans>closeString</Trans>,
      enum: "CLOSE",
    },
  ];
}

// Note: The call to popupMenu will be moved to a shared component.
export function useTaskOptionPopupMenu(task: UsableTask) {
  const { getInput } = useInputPopup();
  const actions = useTaskMenuActions(task);
  return {
    getTaskMenu: () => {
      getInput("popupMenu", { actions });
      trackerHelper.trackTaskDetailActionButtons("more_button");
    },
  };
}
