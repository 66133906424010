import React from "react";
import Button from "@ingka/button";
import { Trans, useTranslation } from "@coworker/locales";
import styles from "./login-view.module.css";
import FullScreenPopup from "../../../layout/components/FullScreenPopup/FullScreenPopup";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { tokenScopes } from "../../../assets/auth/msalConfig";
import { useMsal } from "@azure/msal-react";
import { FixaText } from "../../../shared/wrappers/FixaText/FixaText";

export const LoginView: React.FC = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const handleLogin = async () => {
    await instance.loginRedirect({
      scopes: tokenScopes,
    });
  };

  const { push } = useWorkspacesAction();
  return (
    <FullScreenPopup noPadding noAppBar>
      <div className={styles["main-container"]}>
        <div className={styles["outer-container"]}>
          <div className={styles["logo-text-container"]}>
            <FixaText
              className={styles["text-container"] || ""}
              tagName="h1"
              headingSize="l"
              bodySize="l"
            >
              Fixa
            </FixaText>
            <FixaText
              className={styles["text-container-small"] || ""}
              tagName="p"
              headingSize="s"
              bodySize="m"
            >
              Simplifying your daily routines
            </FixaText>
          </div>
          <div className={styles["login-privacy-container"]}>
            <div>
              <Button
                aria-label={t("login")}
                onClick={handleLogin}
                type="primary"
                text={<Trans>login</Trans>}
              ></Button>
            </div>
            <div className={styles["privacy-container"]}>
              <Button
                onClick={() => push("/privacy")}
                type="tertiary"
                text={<Trans>privacyString</Trans>}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </FullScreenPopup>
  );
};
