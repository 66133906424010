import React, { useState } from "react";
import useCallSIK, { SIK_SEARCH, SIK_SEARCH_MORE } from "./API/useCallSIK";
import { parseSearchProduct, parseProduct } from "../services/products.service";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { useLanguage } from "../core/hooks/useLanguage";
import { INTERVAL } from "../constants/clientTime";
import { formatFullIdWithDots } from "@coworker/reusable";
import { useItemInfoAndPriceSR } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfoAndPriceSR";

const defaultState = {
  loading: false,
  hideSPR: false,
  data: [],
  page: 1,
  total: 0,
  totalSIK: 0,
  totalMFS: 0,
  dupesSIK: 0,
  dupesMFS: 0,
  hiddenSIK: 0,
  hiddenMFS: 0,
  token: "",
};

function guesstimateTotal(state, extra) {
  return Math.max(extra, state.total, state.totalMFS, state.totalSIK);
}

const paginationReducer = (state, action) => {
  const alreadyListed = (query) => state.data.find(({ key }) => key === query);
  // skip SPR articles if necessary
  const shouldHide = (row) => row?.itemType === "SPR" && state?.hideSPR;

  switch (action.type) {
    case "numericSR": {
      const { basicProduct } = action;
      const key = basicProduct.type + basicProduct.no;
      // Do nothing if item is already listed from other search sources.
      if (shouldHide(basicProduct) || alreadyListed(key)) return state;

      const row = {
        key,
        name: basicProduct.name,
        no: basicProduct?.no,
        type: basicProduct.type,
        salesPrice: basicProduct.salesPrice,
        description: basicProduct.description,
        smallImageUrl: basicProduct.smallImageUrl,
        imageUrl: basicProduct.smallImageUrl,
        noFormatted: formatFullIdWithDots(basicProduct.no),
        fromSR: true,
      };

      return { ...state, loading: false, data: [...state.data, row] };
    }

    case "moreMFS": {
      const data = [...state.data];
      let { dupesMFS, hiddenMFS } = state;
      for (const row of action.data || []) {
        if (shouldHide(row)) hiddenMFS++;
        else {
          const listed = alreadyListed(row.key);
          if (!listed) data.push({ ...row, fromMFS: true });
          else if (!listed.fromMFS) dupesMFS++;
        }
      }
      const totalMFS = action.totalMFS || state.totalMFS;
      const total = guesstimateTotal(state, totalMFS);
      return {
        ...state,
        loading: false,
        data,
        dupesMFS,
        hiddenMFS,
        totalMFS,
        total,
      };
    }
    case "moreSIK": {
      const data = [...state.data];
      let { dupesSIK, hiddenSIK } = state;
      for (const row of action.data || []) {
        if (shouldHide(row)) hiddenSIK++;
        else {
          const listed = alreadyListed(row.key);
          if (!listed) data.push({ ...row, fromSIK: true });
          else if (!listed.fromSIK) dupesSIK++;
        }
      }
      const totalSIK = action.totalSIK || state.totalSIK;
      const total = guesstimateTotal(state, totalSIK);
      return {
        ...state,
        loading: false,
        data,
        dupesSIK,
        hiddenSIK,
        totalSIK,
        total,
        token: action.token,
      };
    }
    case "reset": {
      return {
        ...defaultState,
        hideSPR: action.hideSPR,
        loading: action.loading,
      };
    }
    case "loadnewpage": {
      return {
        ...state,
        hideSPR: action.hideSPR,
        page: state.page + 1,
      };
    }
    default:
      return state;
  }
};
// helper function for dispatching events
const numericSR = (basicProduct) => ({ type: "numericSR", basicProduct });
const moreSIK = (data, totalSIK, token) => ({
  type: "moreSIK",
  data,
  totalSIK,
  token,
});
const reset = (nonEmptyQuery, hideSPR) => ({
  type: "reset",
  hideSPR,
  loading: nonEmptyQuery,
});
const loadNewPage = (hideSPR) => ({ type: "loadnewpage", hideSPR });

const emptyList = [];
/**
 * @returns {Array<Object>} A list of products to be parsed by `parseSearchProduct.fromSIK`
 */
function collectItemsSIK(data, page) {
  const base = page > 1 ? data?.more : data?.searchResultPage?.products.main;
  const items = [];
  // Since the /more with token was implemented, you might get results that aren't type product, at least if you somehow miss to include the `types=` parameter in a /more -request.
  for (const item of base?.items || []) {
    if (item?.product) items.push(item?.product);
  }
  return items.length ? items : emptyList;
}

/**
 * @returns {String} A token for requesting the next page of search results.
 */
function returnSIKmoreToken(SIKResponse) {
  const data = SIKResponse?.data || {};
  const base = data?.searchResultPage?.products?.main || data?.more;
  return base?.moreToken;
}

const RESULT_LIMIT_PER_PAGE = 10;
const productNumberRE = /^\s*(?:s|ART|SPR)\s*(\d\d\d)\.?(\d\d\d)\.?(\d\d)\s*/;

/**
 * Searches SIK only if query includes a space (" ")
 *
 *  - If a user searches a partial product name/id the SIK endpoint is used
 *  - If a user searches a partial product name/id and selects an autocomplete
 * option that does not exactly match the search input use SIK endpoint
 *  - If a user searches a full product name (eg.: FIXA or BILLY or 50205626)
 * and select an autocomplete option that matches the input it searches MFS
 * endpoint
 *  - If no results from autocomplete use MFS search
 */
export function useMixedSearchProducts(articleId = "", options) {
  const [state, dispatch] = React.useReducer(paginationReducer, defaultState);
  const store_id = useStoreId();
  const language = useLanguage();
  const [fetchItemFromSR, setFetchItemFromSR] = useState(false);
  const { blockRequest, includeNumberSearch } = options;
  const match = articleId.match(productNumberRE);
  const query = match ? match.slice(1).join("") : articleId;
  const [short_id] = parseProduct(query);
  const queryLength = query.length * (language === "zh_CN" ? 3 : 1);

  const hideSPR = options?.hideSPR;

  const { token } = state;
  const blockSIK = queryLength < 2 || blockRequest;
  const SIKResponse = useCallSIK(
    state.page === 1 ? SIK_SEARCH : SIK_SEARCH_MORE,
    {
      q: query,
      range: "ALL",
      ...(state.page === 1
        ? { size: RESULT_LIMIT_PER_PAGE }
        : { size: RESULT_LIMIT_PER_PAGE, token }),
      types: "PRODUCT",
    },
    { ...options, blockRequest: blockSIK || (state.page > 1 && !state.token) }
  );
  const loading = SIKResponse.loading && !SIKResponse.isBlocked;

  React.useEffect(() => {
    setFetchItemFromSR(false);
    dispatch(reset(query !== "", hideSPR));
  }, [query, hideSPR]);

  React.useEffect(() => {
    const firstTotal = SIKResponse.data?.searchResultPage?.products?.badge; // Needed once per search
    const token = returnSIKmoreToken(SIKResponse);
    const items = collectItemsSIK(SIKResponse.data, state.page);
    const products = parseSearchProduct.fromSIK(store_id, language, items);
    if (products?.length) {
      setFetchItemFromSR(false);
      dispatch(moreSIK(products, firstTotal, token));
    }
    // Ignored: SIKResponse object to avoid looping
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SIKResponse.data, language, store_id, state.page]);

  const {
    data: itemInfoAndPriceSR,
    isInitialLoading: isLoadingItemSR,
    isError,
  } = useItemInfoAndPriceSR(
    fetchItemFromSR ? includeNumberSearch && short_id : undefined
  );

  React.useEffect(() => {
    if (
      !SIKResponse.isBlocked &&
      !loading &&
      state.data.length === 0 &&
      !!query
    ) {
      const sikTotal = SIKResponse.data?.searchResultPage?.products?.badge;
      if (
        sikTotal === 0 &&
        SIKResponse?.data?.searchResultPage?.searchPhrase === query
      ) {
        setFetchItemFromSR(true);
      }
    }
  }, [
    SIKResponse.isBlocked,
    loading,
    state.data.length,
    query,
    SIKResponse.data,
  ]);

  React.useEffect(() => {
    if (!isLoadingItemSR && !isError) {
      if (itemInfoAndPriceSR?.type && fetchItemFromSR)
        dispatch(numericSR(itemInfoAndPriceSR));
    }
  }, [itemInfoAndPriceSR, fetchItemFromSR, isLoadingItemSR, isError]);

  return {
    ...state,
    loading: loading || (fetchItemFromSR && isLoadingItemSR),
    RESULT_LIMIT_PER_PAGE,
    nextPage: () => dispatch(loadNewPage(hideSPR)),
  };
}

/**
 * @param {string} shortId Search query
 * @param {number} cacheTimeout milliseconds
 * @returns {{itemType, itemName, smallImage, description, itemNo, key}|{}}
 */
export function useBasicProductFromSIK(
  shortId,
  cacheTimeout = 8 * INTERVAL.HOUR
) {
  const store_id = useStoreId();
  const language = useLanguage();
  const blockRequest = !shortId || shortId?.length < 7;
  const { data, loading } = useCallSIK(
    SIK_SEARCH,
    { q: shortId, size: 1, types: "PRODUCT", range: "ALL" },
    { blockRequest, cacheTimeout }
  );
  return React.useMemo(() => {
    if (blockRequest) return;
    if (!loading) {
      const items = collectItemsSIK(data, 1);
      const parsedItems = parseSearchProduct.fromSIK(store_id, language, items);
      const found = parsedItems.find((parsedItem) =>
        [parsedItem?.id, parsedItem?.key].join(",").includes(shortId)
      );
      return found ? found : undefined;
    }
  }, [shortId, data, language, store_id, blockRequest, loading]);
}
