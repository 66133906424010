import { parseProduct } from "../services/products.service";
import { useBasicProductFromSIK } from "./useSearchProducts";
import { useItemInfoAndPriceSR } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfoAndPriceSR";

// Cache in-memory for less re-renders, and in localStorage for later
const cache = {};
const keyFor = (id) => `cache/bp/${id}`;
export const readLocalProductCache = (id) => {
  const found = cache[id]?.name
    ? cache[id]
    : JSON.parse(localStorage?.getItem(keyFor(id)));
  return found?.name ? found : null;
};

function goodChange(key, before, after) {
  switch (key) {
    case "smallImage":
      return !before || (before.includes("_s5.jpg") && after.includes("S2."));
    case "descriptives":
      return !before || (after && before?.join("") !== after?.join(""));
    case "updated_at":
    case "source":
      return;
    default:
      return before !== after;
  }
}

export function writeLocalProductCache(id, data, whereFrom) {
  if (!id || !data) return;

  // Any incoming empty property is not allowed to overwrite existing properties. Needed to prevent MFS search results from removing panumber.
  const currentlyStored = readLocalProductCache(id);
  let basicProduct = { ...(currentlyStored || {}) };
  let diff = false;
  for (const key of Object.keys(data)) {
    if (data[key] && goodChange(key, basicProduct[key] || "", data[key])) {
      basicProduct[key] = data[key];
      diff = true;
    }
  }

  // The writes to localStorage here via setItem does not go beyond 300, so well below any troublesome levels. And each item stored in cache is ~100 bytes.
  if (currentlyStored ? diff : whereFrom) {
    try {
      localStorage?.setItem(keyFor(id), JSON.stringify(basicProduct));
    } catch (error) {}
  }

  return (cache[id] = basicProduct);
}

/**
 * @param {string} short_id
 */
export function useFastBasicProduct(some_id) {
  const [short_id] = parseProduct(some_id);
  const found = useBasicProductFromSIK(short_id);
  const {
    data: fetched,
    isError,
    isInitialLoading,
  } = useItemInfoAndPriceSR(!found ? short_id : undefined);
  const loading = !found && isInitialLoading;
  const error = !found && isError;

  return { basicItem: found ?? fetched, loading, error };
}
