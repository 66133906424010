import React from "react";
import styled from "styled-components";
import { useTasksForProduct } from "../../../hooks/useTasksForProduct";
import { Icon } from "@coworker/components";
import { RelatedTasksLink } from "../../../components/RelatedTasksLink";
import { TasksWithArticle } from "@coworker/types/lib/tasks";

const ContainerRelatedTask = styled.div`
  margin: 14px 30px;
  display: flex;
  align-items: center;
`;

interface RelatedTasksProps {
  value: Pick<TasksWithArticle, "product_article_id">;
}

export function RelatedTasksWidget({
  value: { product_article_id },
}: RelatedTasksProps) {
  const relatedTasks = useTasksForProduct(product_article_id);
  return relatedTasks?.formattedCount ? (
    <ContainerRelatedTask>
      <RelatedTasksLink
        productId={product_article_id}
        relatedTasksCount={relatedTasks?.formattedCount}
        ofWhichClosed={relatedTasks?.ofWhichClosed}
      />
      <Icon family="arrows" name="chevron-right" size={20} />
    </ContainerRelatedTask>
  ) : null;
}
