import React from "react";
import { useTranslation } from "@coworker/locales";
import {
  groupByTypes,
  translationSwitchEnum,
} from "@coworker/app/src/constants/groupBy";
import styles from "./grouped-tasks-header.module.css";
import { FixaText } from "../../../shared/wrappers/FixaText/FixaText";

interface GroupedTasksHeaderProps {
  groupByTitle: string;
  numberOfTasksInGroup: number;
  selectedGroupByOption: string;
}

export const GroupedTasksHeader = ({
  groupByTitle,
  numberOfTasksInGroup,
  selectedGroupByOption,
}: GroupedTasksHeaderProps) => {
  const { t } = useTranslation();
  const translation =
    (selectedGroupByOption !== groupByTypes.BY_GRID_CODE ||
      groupByTitle === groupByTypes.NO_GROUPING) &&
    groupByTitle in translationSwitchEnum
      ? t((translationSwitchEnum as any)[groupByTitle])
      : groupByTitle;

  const mainHeaderTitle =
    translation.charAt(0).toUpperCase() + translation.slice(1);
  const title =
    selectedGroupByOption === groupByTypes.BY_TASK_TYPE
      ? `${numberOfTasksInGroup} ${mainHeaderTitle} ${
          numberOfTasksInGroup > 1
            ? t("TasksString").toLowerCase()
            : t("TaskString").toLowerCase()
        }`
      : `${mainHeaderTitle}`;

  return (
    <div className={styles["grouped-header"]}>
      <FixaText tagName="h4" headingSize="m">
        {groupByTitle === groupByTypes.NO_GROUPING ? `(${title})` : title}
      </FixaText>
    </div>
  );
};
