import React, { useState } from "react";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { Article } from "../types/article";
import { useTranslation } from "@coworker/locales";

interface SearchBarProps<A extends Article> {
  articles: A[];
  onSearchResults: (results: A[]) => void;
}

export const SearchBar = <A extends Article>({
  articles,
  onSearchResults,
}: SearchBarProps<A>) => {
  const [searchInput, setSearchInput] = useState("");
  const { t } = useTranslation();

  const handleSearch = (value: string) => {
    setSearchInput(value);

    if (!articles) return;

    onSearchResults(
      articles.filter(
        (article) =>
          article.itemName.toLowerCase().includes(value.toLowerCase()) ||
          article.itemType.toLowerCase().includes(value.toLowerCase()) ||
          article.itemColor.toLowerCase().includes(value.toLowerCase()) ||
          article.productArticleId.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  const handleClear = () => {
    handleSearch("");
  };

  return (
    <FixaSearch
      id="article-search"
      value={searchInput}
      onChange={handleChange}
      onClear={handleClear}
      placeholder={t("searchBasicString")}
    />
  );
};
