import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import MultiSelectCheckbox from "./../MultiSelectCheckbox";
import { NativeNumberKeyPad } from "./NativeNumberKeyPad";
import Button from "@ingka/button";

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100% - 40px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 10px;
  overflow-y: auto;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  color: var(--grey900);
  margin: 55px 29px;
  margin-bottom: 0px;
`;

const InputWrapper = styled.div`
  margin: 10px 40px 10px 29px;
`;

const CapInfo = styled.div`
  font-size: 12px;
  color: var(--grey600);
`;

const CapWarning = styled.div`
  font-size: 12px;
  color: var(--success);
`;

const UnknownCheckBoxWrapper = styled.div`
  display: flex;
  margin: 22px 0px;
  width: 100%;
`;

const CheckBoxTextWrapper = styled.div`
  vertical-align: center;
  font-size: 14px;
  width: 100%;
`;

const SUPPLIER_NUMBER_LENGTH = 5;

interface SupplierNumberValue {
  supplier_number: string;
  supplier_number_unknown: boolean;
}
interface SupplierNumberProps {
  value: SupplierNumberValue;
  bigTitle: string;
  instruction: React.ReactNode;
  onSubmit: (value: SupplierNumberValue) => void;
}

const SupplierNumber = ({
  value,
  bigTitle,
  onSubmit,
  instruction,
}: SupplierNumberProps) => {
  const [supplierNumber, setSupplierNumber] = React.useState<string>(
    value.supplier_number || ""
  );

  const containerRef = React.useRef(null);
  const [supplierNumberNotKnownChecked, setSupplierNumberNotKnownChecked] =
    React.useState<boolean>(value.supplier_number_unknown || false);

  const CapText = () =>
    supplierNumber.length < SUPPLIER_NUMBER_LENGTH ? (
      <CapInfo>
        <Trans values={{ length: SUPPLIER_NUMBER_LENGTH }}>maxNDigits</Trans>
      </CapInfo>
    ) : (
      <CapWarning>
        <Trans values={{ length: SUPPLIER_NUMBER_LENGTH }}>
          numberCanOnlyBeNDigits
        </Trans>
      </CapWarning>
    );

  const UnknownCheckbox = () => (
    <UnknownCheckBoxWrapper>
      <CheckBoxTextWrapper>
        <Trans>iDontHaveASuppliedNumber</Trans>
      </CheckBoxTextWrapper>
      <MultiSelectCheckbox
        checked={supplierNumberNotKnownChecked}
        onClick={() => {
          const newSupplierNumberNotKnown = !supplierNumberNotKnownChecked;
          setSupplierNumberNotKnownChecked(newSupplierNumberNotKnown);

          if (newSupplierNumberNotKnown) {
            setSupplierNumber("");
            onSubmit({
              supplier_number: "",
              supplier_number_unknown: !supplierNumberNotKnownChecked,
            });
          }
        }}
        value={""}
      />
    </UnknownCheckBoxWrapper>
  );

  React.useEffect(() => {
    if (!supplierNumberNotKnownChecked) {
      const sanitizedValue = supplierNumber.replace(/[^0-9]/g, "");
      setSupplierNumber(sanitizedValue);
    }
  }, [supplierNumber, supplierNumberNotKnownChecked]);

  return (
    <Container ref={containerRef}>
      <Title>{bigTitle}</Title>
      <InputWrapper>
        <NativeNumberKeyPad
          keypadType="number"
          inputMode="numeric"
          disabled={supplierNumberNotKnownChecked}
          onComplete={() => {
            onSubmit({
              supplier_number: supplierNumber,
              supplier_number_unknown: supplierNumberNotKnownChecked,
            });
          }}
          onUpdate={(updatedValue: string) => {
            if (!supplierNumberNotKnownChecked) {
              setSupplierNumber(updatedValue);
            }
          }}
          initialValue={supplierNumber}
          maxLength={SUPPLIER_NUMBER_LENGTH}
        />
        <CapText />
        <UnknownCheckbox />
        <Button
          onClick={() => {
            onSubmit({
              supplier_number: supplierNumber,
              supplier_number_unknown: supplierNumberNotKnownChecked,
            });
          }}
          type="primary"
          fluid
        >
          <Trans>submitString</Trans>
        </Button>
        {instruction}
      </InputWrapper>
    </Container>
  );
};

export default SupplierNumber;
