import { sign } from "@coworker/reusable/helpers/math";

export function sortByCreatedAt(a, b) {
  if (a.created_at && b.created_at) {
    return sign(a.created_at - b.created_at);
  } else if (!a.created_at && b.created_at) {
    return 1;
  } else if (a.created_at && !b.created_at) {
    return -1;
  } else if (!a.created_at && !b.created_at) {
    return 0;
  }
}

export function sortArticlesByItemName(a, b) {
  if (a.itemName === b.itemName)
    return a.productArticleId > b.productArticleId ? 1 : -1;

  const isEnglish = /^[A-Za-z]/; // Regex for names that start with A-Z
  const aIsEnglish = isEnglish.test(a.itemName);
  const bIsEnglish = isEnglish.test(b.itemName);

  // prioritize English names
  if (aIsEnglish && !bIsEnglish) return -1;
  if (!aIsEnglish && bIsEnglish) return 1;

  // If both are either English or then sort alphabetically
  return a.itemName.localeCompare(b.itemName, "sv", { sensitivity: "base" });
}
