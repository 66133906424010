import Skeleton from "@ingka/skeleton";
import React from "react";
import { RoomListItemCardDesktop } from "../styles/styles";

export function RoomListItemSkeletonDesktop() {
  return (
    <RoomListItemCardDesktop active={true}>
      <Skeleton height={"100%"} width={"100%"} />
    </RoomListItemCardDesktop>
  );
}
