import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * This is a boolean state machine using the URL search params and navigation.
 *
 * This hook
 * - determines the value from looking at a URL search param
 * - determines what paths you should go to in order to change the value
 * - provides functions for programmatically going to those paths
 *
 * Examples (name = 'showFilters'):
 *
 * Path: /some/path
 * value: false
 * setTrue: Navigate to /some/path?showFilters=
 * setFalse: Navigate to /some/path
 *
 * Path: /some/path?showFilters=
 * value: true
 * setTrue: Navigate to /some/path?showFilters=
 * setFalse: Navigate to /some/path
 *
 * Path: /some/path?otherParam=someValue
 * value: false
 * setTrue: Navigate to /some/path?otherParam=someValue&showFilters=
 * setFalse: Navigate to /some/path?otherParam=someValue
 *
 * Path: /some/path?otherParam=someValue&showFilters=
 * value: true
 * setTrue: Navigate to /some/path?otherParam=someValue&showFilters=
 * setFalse: Navigate to /some/path?otherParam=someValue
 *
 * Path: /some/path?showFilters=someValue
 * value: true
 * setTrue: Navigate to /some/path?showFilters=
 * setFalse: Navigate to /some/path
 *
 * @param name the name of the URL search param
 */
function useBooleanParamState(name: string): {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
} {
  const location = useLocation();
  const navigate = useNavigate();

  const truePath = useMemo(() => {
    const params = new URLSearchParams(location.search);
    params.set(name, "");
    return `${location.pathname}?${params.toString()}`;
  }, [location.pathname, location.search, name]);

  const falsePath = useMemo(() => {
    const params = new URLSearchParams(location.search);
    params.delete(name);
    return `${location.pathname}?${params.toString()}`;
  }, [location.pathname, location.search, name]);

  const setTrue = useCallback(() => {
    navigate(truePath);
  }, [navigate, truePath]);

  const setFalse = useCallback(() => {
    navigate(falsePath);
  }, [falsePath, navigate]);

  const value = useMemo(() => {
    return new URLSearchParams(location.search).has(name);
  }, [location.search, name]);

  return { value, setTrue, setFalse };
}

export default useBooleanParamState;
