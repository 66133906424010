import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Item } from "../../types/item";
import { fetchItemsInfo } from "./useItemsInfo";

// Fetches Items info from Items table if found or SR
// Doesn't contain price and currency info
export const useItemInfo = (itemNo: string) => {
  const queryClient = useQueryClient();

  const previousDataset = queryClient
    .getQueriesData(["items", "info"])
    ?.find(
      ([_, data]) => (data as Item[])?.findIndex(({ no }) => no === itemNo) > -1
    );

  return useQuery({
    queryKey: ["item", "info", itemNo],
    queryFn: async () => {
      const response = await fetchItemsInfo(itemNo);
      return response?.[0] as Item;
    },
    enabled: !!itemNo && !previousDataset,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 86400000,
    initialData:
      previousDataset &&
      (previousDataset?.[1] as Item[])?.find(({ no }) => no === itemNo),
    initialDataUpdatedAt: () =>
      previousDataset &&
      queryClient.getQueryState(previousDataset[0])?.dataUpdatedAt,
  });
};
