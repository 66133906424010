import React from "react";
import styles from "./profile-section.module.css";
import { Trans } from "@coworker/locales";
import { FixaText } from "../../../../shared/wrappers/FixaText/FixaText";
interface ProfileSectionHeaderProps {
  title: string;
}

export const ProfileSectionHeader: React.FC<ProfileSectionHeaderProps> = ({
  title,
}) => {
  return (
    <div>
      <div className={styles["section-header"]}>
        <FixaText
          className={styles["section-header-text"] as string}
          tagName="h3"
          headingSize="xs"
        >
          <Trans>{title}</Trans>
        </FixaText>
      </div>
    </div>
  );
};
