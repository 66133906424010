import { useQuery } from "@tanstack/react-query";
import { getTaskLinksByArticle } from "../services/tasks.service";
import { QueryKeys } from "./queryKeys";

export const useTaskLinksByArticle = (
  storeId: string | undefined,
  productArticleId: string | undefined
) => {
  return useQuery({
    queryKey: [QueryKeys.TaskLinksByArticle, storeId, productArticleId],
    queryFn: () => getTaskLinksByArticle(storeId || "", productArticleId || ""),
    enabled: !!storeId && !!productArticleId,
  });
};
