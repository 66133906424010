import React from "react";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { useArticleById } from "../hooks/useArticleById";
import { useRoomById } from "../hooks/useRoomById";
import { useParams } from "react-router";
import { useTranslation } from "@coworker/locales";
import { RoomSettingsPlacement } from "../Molecules/RoomSettingsPlacement";
import { SupplierNumber } from "../Molecules/SupplierNumber";
import { DateStamp } from "../Molecules/DateStamp";
import { GreyStrip } from "../Atoms/GreyStrip";
import { CreateTasks } from "../Organisms/CreateTasks";
import { useTasksByArticle } from "../hooks/useTasksByArticle";
import { useRoomsAndArticleCount } from "../hooks/useRoomsAndArticleCount";
import {
  getPrimeTasks,
  getSortedRoomsArticleCount,
} from "./ArticleView.helper";
import { ArticleViewSkeleton } from "../Skeletons/ArticleViewSkeleton";
import {
  isBackupForProduct,
  isMainArticle,
} from "../Molecules/RoomArticles.helper";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { ArticleStock } from "../Molecules/ArticleStock";
import ListView from "@ingka/list-view";
import { HFBLocation } from "../Molecules/HFBLocation";
import { ArticleListItemMobile } from "../styles/styles";

export function ArticleView() {
  const { articleId } = useParams();
  const { t } = useTranslation();

  const { data: article, isLoading: articleLoading } =
    useArticleById(articleId);
  const { data: room, isLoading: roomLoading } = useRoomById(article?.roomId);
  const { tasks } = useTasksByArticle(
    room?.store_id,
    article?.productArticleId
  );
  const { data: roomsArticleCount, isLoading: racLoading } =
    useRoomsAndArticleCount(article?.productArticleId, room?.store_id);

  const sortedRoomsArticleCount = getSortedRoomsArticleCount(
    roomsArticleCount || [],
    room?.name || ""
  );
  const primeTasks = getPrimeTasks(roomsArticleCount || [], tasks);

  const header = `${article?.itemName} (${room?.name})`;

  if (articleLoading || roomLoading || racLoading)
    return (
      <FullScreenPopup
        appBarConfig={{
          title: t("loadingString"),
          actions: [],
        }}
        noPadding
      >
        <ArticleViewSkeleton />
      </FullScreenPopup>
    );

  return (
    <>
      {article && room && tasks && (
        <FullScreenPopup
          appBarConfig={{
            title: header,
            actions: [],
          }}
          noPadding
        >
          <ArticleListItemMobile>
            <ArticleListItem
              article={{ ...article, isFromArticleView: true }}
              isMainProduct={() =>
                isMainArticle(
                  article.id,
                  room.main_article_id_1,
                  room.main_article_id_2
                )
              }
              isBackupForProduct={() =>
                isBackupForProduct(
                  article.id,
                  room.backup_article_id_1 ?? "",
                  room.backup_article_id_2 ?? ""
                )
              }
              showActionRow={true}
              hideChevron={true}
            />
          </ArticleListItemMobile>
          <ListView id="article-view-list" size={"small"}>
            <RoomSettingsPlacement
              roomsArticleCount={sortedRoomsArticleCount || []}
              currentArticleId={article.id}
            />

            <HFBLocation hfb={article.hfb} />

            <ArticleStock
              productArticleId={article.productArticleId}
              productArticleType={article.productArticleType}
            />

            <SupplierNumber
              article={article}
              value={article.supplierNumber ?? ""}
            />

            <DateStamp article={article} value={article.dateStamp ?? ""} />
          </ListView>

          <GreyStrip text={t("foundInAllRoomSettingsString")} />

          <CreateTasks
            roomsArticleCount={sortedRoomsArticleCount || []}
            tasks={primeTasks}
            currentArticleId={article.id}
          />
        </FullScreenPopup>
      )}
    </>
  );
}
