import React from "react";
import { LeftContainer, RightContainer, Row } from "../styles/styles";
import { Text } from "../Atoms/Text";
import { Trans, useTranslation } from "@coworker/locales";
import FixaCheckbox from "@coworker/apprestructured/src/shared/wrappers/FixaCheckbox/FixaCheckbox";
import { getArticlesCountText } from "../tools";
import { Articles } from "../types/article";
import styled from "styled-components";

const StyledFixaCheckbox = styled(FixaCheckbox)`
  display: flex;
  flex-direction: row;
  padding: 0px;
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 24px;
`;

interface SelectArticlesProps {
  selectedCount: number;
  piecesCount: number;
  searchCount: number;
  isSelected: boolean;
  onSelectAll: (selectAllChecked: boolean) => void;
  allArticlesInRoom: Articles;
}

export function SelectArticles({
  selectedCount,
  piecesCount,
  searchCount,
  isSelected,
  onSelectAll,
  allArticlesInRoom,
}: SelectArticlesProps) {
  const { t } = useTranslation();
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);

  const isFilter = allArticlesInRoom.length !== searchCount;
  const articlesCountText = getArticlesCountText(
    t,
    isSelected,
    allArticlesInRoom.length !== searchCount,
    selectedCount,
    isFilter ? allArticlesInRoom.length : searchCount,
    searchCount,
    piecesCount
  );

  return (
    <ListItemContainer>
      <LeftContainer>
        <Text text={articlesCountText} />
      </LeftContainer>
      <RightContainer>
        {isSelected && (
          <Row>
            <Trans>selectAllString</Trans>
            &nbsp;&nbsp;
            <StyledFixaCheckbox
              id={"select-all-checkbox"}
              value="selectAll"
              checked={selectAllChecked}
              onChange={() => {
                const newSelectAllChecked = !selectAllChecked;
                setSelectAllChecked(newSelectAllChecked);
                onSelectAll(newSelectAllChecked);
              }}
            />
          </Row>
        )}
      </RightContainer>
    </ListItemContainer>
  );
}
