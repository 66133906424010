import React, { useEffect, useRef } from "react";

import styles from "./action-bar.module.css";

interface ActionBarProps {
  children: React.ReactNode;
  hidden: boolean;
  fullHeightContent?: boolean;
  onHeight?: (height: number) => void;
}

export const ActionBar = ({
  hidden,
  children,
  fullHeightContent = false,
  onHeight,
}: ActionBarProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (onHeight && ref && ref.current) {
      onHeight(ref.current.clientHeight);
    }
  }, [ref, onHeight]);

  const classNames = [styles["action-bar"]];
  if (hidden) {
    classNames.push(styles["hidden"]);
  }
  if (fullHeightContent) {
    classNames.push(styles["full-height-content"]);
  }

  return (
    <div className={classNames.join(" ")} ref={ref}>
      {children}
    </div>
  );
};
