export enum QueryKeys {
  ImagesByRoom = "imagesByRoom",
  Alerts = "alerts",
  Article = "article",
  ArticlesByGroup = "articlesByGroup",
  ArticlesByRoomAndProductArticleId = "articlesByRoomAndProductArticleId",
  ArticlesByRoom = "articlesByRoom",
  ArticlesInfo = "articlesInfo",
  Rooms = "rooms",
  Room = "room",
  GroupsByRoom = "groupsByRoom",
  EdsData = "edsData",
  Group = "group",
  SignedUrl = "signedUrl",
  RoomsAndArticleCount = "roomsAndArticleCount",
  SalesStopData = "salesStopData",
  SearchArticlesInRooms = "searchArticlesInRooms",
  TaskLinksByArticle = "taskLinksByArticle",
  TaskLinksByRoom = "taskLinksByRoom",
  TaskLinksByStore = "taskLinksByStore",
}
