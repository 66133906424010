import React from "react";
import ListView from "@ingka/list-view";
import { RoomListItemMobile } from "../Molecules/RoomListItemMobile";
import { AlertResponse, Room } from "../types/room";
import { TaskLinks } from "../types/taskLink";
import { MediumSkeletonListItem } from "../Molecules/skeletons/MediumSkeletonListItem";

interface RoomsViewRoomsListMobileProps {
  rooms: Room[] | undefined;
  taskLinks: TaskLinks;
  alerts: AlertResponse[] | undefined;
}

export function RoomsViewRoomsListMobile({
  rooms,
  taskLinks,
  alerts,
}: RoomsViewRoomsListMobileProps) {
  if (!rooms) {
    return (
      <ListView id="rooms-list">
        <MediumSkeletonListItem key="rooms-view-list-0" />
        <MediumSkeletonListItem key="rooms-view-list-1" />
        <MediumSkeletonListItem key="rooms-view-list-2" />
        <MediumSkeletonListItem key="rooms-view-list-3" />
      </ListView>
    );
  } else {
    return (
      <ListView id="rooms-list">
        {rooms &&
          rooms
            .sort((a, b) => a.name.localeCompare(b.name))

            .map((room) => (
              <RoomListItemMobile
                key={room.id}
                room={room}
                taskLinks={taskLinks}
                alerts={alerts || []}
              />
            ))}
      </ListView>
    );
  }
}
