import {
  ReactElement,
  cloneElement,
  createRef,
  useMemo,
  useRef,
  useEffect,
  useCallback,
  useState,
} from "react";

import { useWindowProperties } from "@coworker/app/src/components/Scanning/useWindowProperties";

/**
 * Get the first element that fits without overflowing.
 * All given elements must accept a style prop and forward its ref to the DOM.
 * The returned invisibleElementsToMeasure should be rendered in
 * the same location as where you render the firstElementThatFits.
 */
function useFirstElementThatFits(elements: ReactElement[]): {
  invisibleElementsToMeasure: ReactElement[];
  firstElementThatFits: ReactElement | null;
} {
  // Create a ref for each element.
  // NOTE: These refs are created on mount. If the number of elements changes
  // between renders, the number of created refs will be wrong and the results
  // of this hook will probably be incorrect. Mitigate this potential issue
  // either by making sure the number of elements never changes, or by
  // remounting the component every time it does.
  const elementRefs = useRef(elements.map(() => createRef<HTMLDivElement>()));

  // Clone each element, make them invisible and apply refs to them.
  const invisibleElementsToMeasure = useMemo(() => {
    return elements.map((element, i) => {
      const elementDataTestId = element.props["data-testid"];
      return cloneElement(element, {
        ref: elementRefs.current[i],
        key: i,
        style: { visibility: "hidden" },
        "data-testid": elementDataTestId
          ? `${elementDataTestId}_invisible`
          : undefined,
      });
    });
  }, [elements]);

  const {
    size: { width: windowWidth },
  } = useWindowProperties();

  // Go through all measured elements and return the first that fits,
  // or null if none of the elements fit.
  const calcFirstElementThatFits = useCallback(() => {
    for (let i = 0; i < elementRefs.current.length; i++) {
      const elementRef = elementRefs.current[i]!;
      if (elementRef.current) {
        const elementWidth = elementRef.current.scrollWidth;

        // This accounts for the width of the scroll bar
        const tooBig = elementWidth + 5 > windowWidth;

        if (!tooBig) {
          return elements[i]!;
        }
      }
    }

    return null;
  }, [elements, windowWidth]);

  const [firstElementThatFits, setFirstElementThatFits] =
    useState<ReactElement | null>(null);

  const updateFirstElementThatFits = useCallback(() => {
    setFirstElementThatFits(calcFirstElementThatFits());
  }, [calcFirstElementThatFits]);

  // The size of an element in the DOM can change for many reasons, e.g.
  // because of asynchronously applied styles. These changes will not be picked
  // up by the React useEffect hook.
  //
  // Instead, we attach a ResizeObserver to each element that recalculates
  // the first element that fits every time any of the elements changes size.
  useEffect(() => {
    // This is what is run every time any of the measured elements changes size.
    const resizeObserver = new ResizeObserver(updateFirstElementThatFits);

    // We keep track of which elements we observe.
    const observedElements: HTMLDivElement[] = [];

    // We attach the ResizeObserver to each measured element.
    elementRefs.current.forEach((ref) => {
      const element = ref.current;
      if (element) {
        resizeObserver.observe(element);
        observedElements.push(element);
      }
    });

    // Calculate this on mount
    updateFirstElementThatFits();

    // We unobserve all observed elements when unmounting the component.
    return () => {
      observedElements.forEach((element) => {
        resizeObserver.unobserve(element);
      });
    };
  }, [updateFirstElementThatFits]);

  return useMemo(
    () => ({
      invisibleElementsToMeasure,
      firstElementThatFits,
    }),
    [firstElementThatFits, invisibleElementsToMeasure]
  );
}

export default useFirstElementThatFits;
