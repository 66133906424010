import React from "react";
import Checkbox from "@ingka/checkbox";

type FixaCheckboxProps = {
  id: string;
  value: string;
  checked?: boolean;
  onChange?: () => void;
  label?: string | React.ReactElement;
  indeterminate?: boolean;
  subtle?: boolean;
  readOnly?: boolean;
  "data-testid"?: string;
};

function FixaCheckbox(props: FixaCheckboxProps) {
  return <Checkbox {...props} />;
}

export default FixaCheckbox;
