import { useCountryId } from "@coworker/app/src/core/auth/useLoggedInUser";
import { useEDSData } from "@coworker/app/src/components/RoomSettings/hooks/useEDSData";
import { formatEDSDate } from "@coworker/app/src/components/Insights/helpers";
import { isChineseEnvironment } from "@coworker/reusable";
import { useEdsDateMFSSearch } from "./useEdsDateMFSSearch";

export const useItemEdsDateFormatted = (itemNo: string) => {
  const countryId = useCountryId();
  const isChina = isChineseEnvironment();
  const { data: latestSalesData, isLoading: loadingMFSData } =
    useEdsDateMFSSearch(isChina ? itemNo : "");
  const { data: edsData, isLoading } = useEDSData(
    countryId,
    isChina ? "" : itemNo
  );
  if (isChina && !loadingMFSData) {
    return {
      isLoading: false,
      edsDate: latestSalesData && formatEDSDate(latestSalesData, countryId),
    };
  } else if (!isLoading) {
    const hasEdsData = edsData?.length > 0;
    if (hasEdsData) {
      const formattedEdsDate = formatEDSDate(
        edsData?.[0]?.validToDateTime,
        countryId
      );
      return { isLoading: false, edsDate: formattedEdsDate };
    }
  }
  return { isLoading: isChina ? loadingMFSData : isLoading, edsDate: "" };
};
