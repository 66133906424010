import React, { ReactNode, useState } from "react";
import {
  CenterContainer,
  LeftContainer,
  ListItemContainer,
  ListItemImage,
  ListItemImageContainer,
} from "../styles/styles";
import { UploadImageButton } from "./UploadImageButton";
import { Room } from "../types/room";
import { DeleteImageButton } from "./DeleteImageButton";
import Button from "@ingka/button";
import FixaCheckbox from "@coworker/apprestructured/src/shared/wrappers/FixaCheckbox/FixaCheckbox";
import { useTranslation } from "@coworker/locales";
import { ArrowCounterClockWise } from "../Atoms/Icons/ArrowCounterClockWise";
import { Image, ImageUploadResponse } from "../types/images";

interface RoomImagesListItemProps {
  icon?: ReactNode;
  room: Room;
  images: Image[];
  onAdd: (images: Image[]) => void;
  onRemove: (images: Image[]) => void;
  onUpdate: (image: Image) => void;
}

export const RoomImagesListItem = ({
  icon,
  room,
  images,
  onAdd,
  onRemove,
  onUpdate,
}: RoomImagesListItemProps) => {
  const [selectImage, setSelectImage] = useState<string[]>([]);
  const [selectButtonClick, setSelectButtonClick] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState(false);
  const [showButton, setShowButton] = useState<"upload" | "delete">("upload");
  const { t } = useTranslation();

  const handleImageUpload = async (fileList: ImageUploadResponse[]) => {
    onAdd(
      fileList.map((file) => ({
        roomId: room.id,
        fileName: file.file_name,
        folder: file.folder,
        url: file.signed_url,
        rotation: 0,
      }))
    );
  };

  const handleDeleteImage = async (imageIds: string[]) => {
    onRemove(imageIds.map((id) => images.find((image) => image.id === id)!));
    setSelectImage([]);
    setShowButton("upload");
    setSelectButtonClick(false);
  };

  const handleSelectedImage = (imageId: string) => {
    if (selectImage.includes(imageId)) {
      setSelectImage(selectImage.filter((id) => id !== imageId));
    } else {
      setSelectImage([...selectImage, imageId]);
    }
  };

  const handleRotateImage = async (
    image: Image,
    index: number
  ): Promise<void> => {
    image.rotation = image.rotation ? (image.rotation + 90) % 360 : 90;
    const carouselImage = document.getElementById(`${image.id}-${index}`);
    if (carouselImage) {
      carouselImage.style.transform = "rotate(" + image.rotation + "deg)";
    }

    onUpdate(image);
  };

  if (!images) return <></>;

  return (
    <>
      <ListItemContainer>
        <LeftContainer>{icon}</LeftContainer>
        <CenterContainer $centerText={false}>
          <ListItemImageContainer>
            {images.map((image, index) => (
              <div style={{ position: "relative" }} key={index}>
                <ListItemImage
                  id={`${image.id}-${index}`}
                  key={image.id}
                  src={image.url ?? ""}
                  $rotation={image.rotation || 0}
                />
                {selectButtonClick && image.id && (
                  <div
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  >
                    <FixaCheckbox
                      id={image.id || ""}
                      value={image.id || ""}
                      onChange={() => {
                        image.id && handleSelectedImage(image.id);
                      }}
                    />
                  </div>
                )}
                <div
                  onClick={() => {
                    void handleRotateImage(image, index);
                  }}
                >
                  <ArrowCounterClockWise />
                </div>
              </div>
            ))}

            {showButton === "upload" && (
              <UploadImageButton handleImageUpload={handleImageUpload} />
            )}
            {showButton === "delete" && (
              <DeleteImageButton
                deleteImage={handleDeleteImage}
                selectedImages={selectImage}
                disabled={selectImage.length === 0}
              />
            )}
          </ListItemImageContainer>
        </CenterContainer>
      </ListItemContainer>
      {images.length > 0 && (
        <Button
          style={{ marginLeft: "40px", marginBottom: "5px" }}
          type="secondary"
          onClick={() => {
            setShowButton(showButton === "upload" ? "delete" : "upload");
            setIsCancel(!isCancel);
            setSelectButtonClick(!selectButtonClick);
            if (isCancel) {
              setSelectImage([]);
            }
          }}
          text={showButton === "upload" ? t("deleteString") : t("cancelString")}
          size="small"
        />
      )}
    </>
  );
};
