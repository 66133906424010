import React from "react";
import styled from "styled-components";
import { AssignToUserOrGroup } from "../AssignToUserOrGroup";
import { Trans, useTranslation } from "@coworker/locales";
import Instructions from "../Instructions";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import trackerHelper from "../../helpers/tracker";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import { useActiveUsers } from "../../hooks/useActiveUsers";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { useTeamId, useUserId } from "../../core/auth/useLoggedInUser";
import { useAssignableTeams } from "../../core/hooks/useAssignableTeams";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import SSRIcon from "@ingka/ssr-icon";
import CloseIcon from "@ingka/ssr-icon/paths/cross";

const SearchContainer = styled.div`
  margin: 24px;
`;

const Handoff = ({ onSubmit, assignees, onClose }) => {
  const userId = useUserId();
  const teamId = useTeamId();
  const assignableTeams = useAssignableTeams(false);
  const activeUsers = useActiveUsers();
  const { t } = useTranslation();

  const [assignToMyTeamOpened, setAssignToMyTeamOpenedInner] =
    useUserPreference(profilePreferences.ASSIGN_TO_MY_TEAM_OPENED, false);
  const setAssignToMyTeamOpened = React.useCallback(
    (change) => {
      trackerHelper.task.trackAssignToMyTeamOpenedToggle(change);
      setAssignToMyTeamOpenedInner(change);
    },
    [setAssignToMyTeamOpenedInner]
  );

  const [searchQuery, setSearchQuery] = React.useState("");

  const appBarActions = [
    {
      name: "close",
      icon: <SSRIcon paths={CloseIcon} />,
      onClick: () => onClose(),
      position: "right",
    },
  ];

  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("handoffRefillString"),
        actions: appBarActions,
      }}
      noPadding
    >
      <Instructions
        title={<Trans>handoffString</Trans>}
        text={<Trans>handoffTextString</Trans>}
        type={profilePreferences.DISABLE_POPUPS_HANDOFF}
      />
      <SearchContainer>
        <FixaSearch
          id="handoff-search"
          placeholder={t("searchBasicString")}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onClear={() => {
            setSearchQuery("");
          }}
        />
      </SearchContainer>
      <AssignToUserOrGroup
        assignees={assignees}
        onSubmit={onSubmit}
        handoff
        assignToMyTeamOpened={assignToMyTeamOpened}
        setAssignToMyTeamOpened={setAssignToMyTeamOpened}
        users={activeUsers}
        assignableTeams={assignableTeams}
        me={{ id: userId, group: teamId }}
        onKeepTaskClick={trackerHelper.task.trackKeepTaskWithMeClick}
        searchQuery={searchQuery}
        overflow={false}
      />
    </FullScreenPopup>
  );
};

export default Handoff;
