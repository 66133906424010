import React from "react";

import styles from "./Linkbar.module.css";

export type CurrentProps = {
  label: string;
  asH1?: boolean | undefined;
  truncate?: boolean;
  "data-testid"?: string | undefined;
};

function Current({
  label,
  asH1 = false,
  truncate = false,
  "data-testid": dataTestId,
}: CurrentProps) {
  const Component = asH1 ? "h1" : "div";
  return (
    <Component
      data-testid={dataTestId}
      className={`${
        truncate ? styles["current-truncate"] : styles["current-no-truncate"]
      }`}
    >
      {label}
    </Component>
  );
}

export default Current;
