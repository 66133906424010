import React from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { Widget } from "@coworker/components/src/components/Widget";
import { AddTitle } from "@coworker/components";
import { useInputPopup } from "../../InputPopup";
import { useLocation } from "react-router-dom";

export default function TaskFormCustomTitle({ value, onChange }) {
  const location = useLocation();
  const isUpptackaTask = location.search.includes("upptacka");
  const { t } = useTranslation();

  value = isUpptackaTask ? t("setUpUpptackaHomeScreenString") : value;

  React.useEffect(() => {
    if (isUpptackaTask) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getInput } = useInputPopup();
  const getTitle = async () => {
    const data = await getInput("title", {
      initialValue: value,
      title: "",
    });
    if (data) {
      onChange(data);
    }
  };

  return (
    <Widget onClick={getTitle} data-testid="customTitleWidget">
      <AddTitle
        value={value}
        deactivated={false}
        titleString={<Trans>titleAddString</Trans>}
        optional={<Trans>optionalString</Trans>}
      />
    </Widget>
  );
}
