import React from "react";
import Button from "@ingka/button";
import { Trans } from "@coworker/locales";
import styled from "styled-components";
import { useInputPopup } from "../../InputPopup";
import { useFeatureFlagHooks } from "../useMFAQs.featureToggle";
import { useToastNotification } from "@coworker/components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import {
  getTranslatedQuestionText,
  removeQuestionMarks,
} from "./QuestionsDetailPage.helper";
import { Question } from "../BackendApiRepository/QuestionResponse";
interface QuestionActionButtonRowComponentProps {
  questionId: string;
  questionText: string;
  setQuestion: Function;
  mergeQuestionButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
}
const ButtonContainer = styled.div`
  display: flex;
  padding-inline: 16px;
  padding-block: 24px;
  gap: 8px;
  border-top: 1px solid var(--grey200);
  border-bottom: 1px solid var(--grey200);
`;

const QuestionActionButtonRowComponent: React.FC<
  QuestionActionButtonRowComponentProps
> = ({ questionId, setQuestion, questionText, mergeQuestionButtonClicked }) => {
  const { getInput, closePopup } = useInputPopup();
  const { mergeQuestionRequest, updateQuestionRequest } = useFeatureFlagHooks();
  const { showToast } = useToastNotification();
  const { push } = useWorkspacesAction();

  const mergeQuestion = async (secondQuestionId: string) => {
    const newQuestionId = await mergeQuestionRequest(
      questionId,
      secondQuestionId
    );
    if (newQuestionId) {
      showToast(<Trans>mfaqMergeCompletedString</Trans>);
      push(`/customerquestions/${newQuestionId}`);
    } else {
      //  showToast(<Trans>mergeUnsuccessString</Trans>);
      console.error("Error: MFAQ merge question", newQuestionId);
    }
  };

  const updateQuestion = (newQuestionText: string) => {
    const subject = removeQuestionMarks(newQuestionText);
    const questionText = getTranslatedQuestionText(newQuestionText);
    const payload = {
      question_id: questionId,
      question_text: questionText,
      subject: subject,
    };
    updateQuestionRequest(questionId, questionText, subject);
    setQuestion((prevQuestion: Question) => ({
      ...prevQuestion,
      ...payload,
    }));
  };

  return (
    <ButtonContainer>
      <Button
        data-testid="createMFAQTask"
        type="secondary"
        text={<Trans>{"mfaqEditQuestionString"}</Trans>}
        size="small"
        onClick={async () => {
          const newQuestionText = await getInput("mfaqEditQuestion", {
            questionText,
          });
          if (!newQuestionText) return;
          updateQuestion(newQuestionText);
          closePopup();
        }}
      />
      <Button
        data-testid="createMFAQTask"
        type="secondary"
        text={<Trans>{"mfaqMergeQuestionString"}</Trans>}
        size="small"
        onClick={async () => {
          mergeQuestionButtonClicked(true);
          const mergeQuestionId = await getInput("mfaqMergeQuestion", {
            questionId,
          });
          if (!mergeQuestionId) return;
          mergeQuestion(mergeQuestionId);
          closePopup();
        }}
      />
    </ButtonContainer>
  );
};

export default QuestionActionButtonRowComponent;
