import React from "react";
import styles from "./super-users-title.module.css";
import { FixaText } from "../../../../wrappers/FixaText/FixaText";

interface SuperUsersTitleProps {
  title: string;
  subTitle: string;
}

export const SuperUsersTitle: React.FC<SuperUsersTitleProps> = ({
  title,
  subTitle,
}) => {
  return (
    <div className={styles["header-container"]}>
      <FixaText tagName={"h1"}>{title}</FixaText>
      <FixaText bodySize={"m"}>{subTitle}</FixaText>
    </div>
  );
};
