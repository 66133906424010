import React from "react";
import { useTeamId } from "@coworker/app/src/core/auth/useLoggedInUser";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import FullScreenPopup from "../../../layout/components/FullScreenPopup/FullScreenPopup";
import { useTranslation } from "react-i18next";
import { SimpleLoadingBall } from "../../../shared/components/SimpleLoading/SimpleLoading";

export function LandingPage() {
  const teamId = useTeamId();
  const { replace } = useWorkspacesAction();
  const { t } = useTranslation();

  if (!teamId) {
    replace("/teamselection");
  } else if (teamId) {
    replace("/home");
  }
  return (
    <FullScreenPopup noPadding noAppBar>
      <SimpleLoadingBall text={t("loadingEllipsisString")} />
    </FullScreenPopup>
  );
}
