import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ListView, { ListViewItem } from "@ingka/list-view";
import tag from "@ingka/ssr-icon/paths/tag";
import sparkles from "@ingka/ssr-icon/paths/clean-sparkles";
import camera from "@ingka/ssr-icon/paths/camera";
import star from "@ingka/ssr-icon/paths/star-filled";
import starOutline from "@ingka/ssr-icon/paths/star";
import paintBrush from "@ingka/ssr-icon/paths/paintbrush";
import flag from "@ingka/ssr-icon/paths/flag";
import stairs from "@ingka/ssr-icon/paths/stairs";
import nursing from "@ingka/ssr-icon/paths/nursing";
import bike from "@ingka/ssr-icon/paths/bike";
import home from "@ingka/ssr-icon/paths/home";
import locationPinSmall from "@ingka/ssr-icon/paths/location-pin-small";
import { useTranslation } from "@coworker/locales";
import { useArticleById } from "../hooks/useArticleById";
import { RoomImagesListItem } from "../Molecules/RoomImagesListItem";
import { Room } from "../types/room";
import { DeleteRoomModal } from "./Modals/DeleteRoomModal";
import { Image } from "../types/images";
import { useImagesByRoomId } from "../hooks/useImagesByRoomId";
import { useAddImageToRoom } from "../hooks/useAddImageToRoom";
import { useDeleteImages } from "../hooks/useDeleteImages";
import { useQueryClient } from "@tanstack/react-query";
import { updateImage } from "../services/images.service";
import { QueryKeys } from "../hooks/queryKeys";
import { useImagesBySignedUrl } from "../hooks/useImagesBySignedUrl";
dayjs.extend(relativeTime);

interface RoomsetSettingsListProps {
  room: Room;
}

export const RoomsetSettingsList = ({ room }: RoomsetSettingsListProps) => {
  const { t } = useTranslation();
  const { data: firstMainProduct } = useArticleById(room.main_article_id_1);
  const { data: secondMainProduct } = useArticleById(room.main_article_id_2);
  const { data: backupArticle1 } = useArticleById(room.backup_article_id_1);
  const { data: backupArticle2 } = useArticleById(room.backup_article_id_2);
  const { data: images } = useImagesByRoomId(room.id);
  const signedImages = useImagesBySignedUrl(images ?? []);
  const addImageToRoom = useAddImageToRoom(room.id);
  const deleteImageMutation = useDeleteImages();
  const queryClient = useQueryClient();
  const createdAtString = `${dayjs().to(dayjs(room.created_at))} (${
    room.created_at.split("T")[0] ?? ""
  })`;

  const onImageAdd = async (images: Image[]) => {
    for (const image of images) {
      try {
        await addImageToRoom.mutateAsync(image);
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
  };

  const onImageRemove = async (images: Image[]) => {
    try {
      await deleteImageMutation.mutateAsync({
        imageIds: images.map((image) => image.id ?? ""),
        roomId: room.id,
      });
    } catch (error) {
      alert(t("searchErrorString"));
    }
  };

  const onImageUpdate = async (image: Image) => {
    if (image.id) {
      try {
        await updateImage(image.id, image);
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.ImagesByRoom],
        });
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
  };

  return (
    <>
      <ListView id="room-settings-list" size={"medium"}>
        <ListViewItem
          title={t("mediaNameString")}
          leadingIcon={tag}
          description={room.name ? room.name : t("notSpecifiedString")}
          emphasised
          inset
        />
        <ListViewItem
          title={t("createdString")}
          leadingIcon={sparkles}
          description={createdAtString}
          emphasised
          inset
        />
        <ListViewItem
          title={t("photosString")}
          leadingIcon={camera}
          emphasised
          inset
        />
        <RoomImagesListItem
          room={room}
          images={signedImages}
          onAdd={onImageAdd}
          onRemove={onImageRemove}
          onUpdate={onImageUpdate}
        />
        <ListViewItem
          title={`${t("mainProductBasicString")} #1`}
          leadingIcon={star}
          description={`${room.main_article_name_1} ${
            firstMainProduct?.itemType ?? ""
          } ${firstMainProduct?.itemColor ?? ""}`}
          emphasised
          inset
        />
        <ListViewItem
          title={`${t("backupForProductString")} #1`}
          leadingIcon={starOutline}
          description={
            backupArticle1
              ? `${room.backup_article_name_1} ${
                  backupArticle1?.itemType ?? ""
                } ${backupArticle1?.itemColor ?? ""}`
              : ""
          }
          emphasised
          inset
        />
        <ListViewItem
          title={`${t("mainProductBasicString")} #2`}
          leadingIcon={star}
          description={`${room.main_article_name_2} ${
            secondMainProduct?.itemType ?? ""
          } ${secondMainProduct?.itemColor ?? ""}`}
          emphasised
          inset
        />
        <ListViewItem
          title={`${t("backupForProductString")} #2`}
          leadingIcon={starOutline}
          description={
            backupArticle2
              ? `${room.backup_article_name_2} ${
                  backupArticle2?.itemType ?? ""
                } ${backupArticle2?.itemColor ?? ""}`
              : ""
          }
          emphasised
          inset
        />
        <ListViewItem
          title={t("mediaStoryString")}
          description={
            room.media_story ? room.media_story : t("notSpecifiedString")
          }
          emphasised
          inset
        />
        <ListViewItem
          title={t("styleGroupString")}
          leadingIcon={paintBrush}
          description={
            room.style_group ? room.style_group : t("notSpecifiedString")
          }
          emphasised
          inset
        />
        <ListViewItem
          title={t("livingSituationString")}
          leadingIcon={nursing}
          description={
            room.living_situation
              ? room.living_situation
              : t("notSpecifiedString")
          }
          emphasised
          inset
        />
        <ListViewItem
          title={t("longtermPriorityString")}
          leadingIcon={flag}
          description={room.hf_ltp ? room.hf_ltp : t("notSpecifiedString")}
          emphasised
          inset
        />
        <ListViewItem
          title={t("hfbLocationInStoreString")}
          leadingIcon={locationPinSmall}
          description={room.hfb ? room.hfb : t("notSpecifiedString")}
          emphasised
          inset
        />
        <ListViewItem
          title={t("priceLevelString")}
          leadingIcon={stairs}
          description={room.pricing ? room.pricing : t("notSpecifiedString")}
          emphasised
          inset
        />
        <ListViewItem
          title={t("lifeHomeActivityString")}
          leadingIcon={bike}
          description={room.laha ? room.laha : t("notSpecifiedString")}
          emphasised
          inset
        />
        <ListViewItem
          title={t("livingConditionsString")}
          leadingIcon={home}
          description={
            room.living_conditions
              ? room.living_conditions
              : t("notSpecifiedString")
          }
          emphasised
          inset
        />
      </ListView>
      <DeleteRoomModal room={room} />
    </>
  );
};
