import React, { PropsWithChildren } from "react";
import { useViewportSize } from "../hooks/useViewportSize";
import { ViewportSize } from "../constants/viewport";

interface DesktopOnlyProps {
  breakpoint?: ViewportSize;
}
export function DesktopOnly({
  breakpoint = ViewportSize.EXTRA_LARGE_1200,
  children,
}: PropsWithChildren<DesktopOnlyProps>) {
  const viewportSize = useViewportSize();

  return breakpoint <= viewportSize ? <>{children}</> : null;
}
