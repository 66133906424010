import React from "react";
import Toast from "@ingka/toast";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/toast/dist/style.css";
import "@ingka/focus/dist/style.css";

interface ToastProps {
  text: string;
  isOpen: boolean;
  onCloseRequest: () => void;
  ariaLabelCloseBtn?: string;
}

export const FixaToast = (props: ToastProps) => {
  return <Toast {...props} />;
};
