import { useMemo } from "react";
import { Article, Articles } from "../types/article";

export interface AggregatedArticle extends Article {
  aggregatedArticles: Article[];
}

export const useAggregatedArticles = (articles: Articles) => {
  const uniqueArticles = useMemo(() => {
    const articlesProductIdMap = new Map<string, AggregatedArticle>();
    for (const article of articles) {
      const oldArticle = articlesProductIdMap.get(article.productArticleId);
      if (oldArticle) {
        oldArticle.nbrArticles =
          (oldArticle?.nbrArticles ?? 1) + article.nbrArticles;
        oldArticle.aggregatedArticles.push(article);
      } else {
        articlesProductIdMap.set(article.productArticleId, {
          ...article,
          aggregatedArticles: [article],
        });
      }
    }
    return Array.from(articlesProductIdMap.values());
  }, [articles]);
  return uniqueArticles;
};
