import React from "react";
import styled from "styled-components";
import { PreviewHeader } from "../core/components/Previews";
import { WidgetDivider, Spacer } from "@coworker/reusable/components/Widgets";
import { formatFullIdWithDots } from "@coworker/reusable";
import { Item } from "@coworker/apprestructured/src/shared/types/item";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";

const ProductHeaderTitle = styled.p`
  color: var(--black);
  font-weight: bold;
  font-size: 14px;
`;

const ProductHeaderDescription = styled.p`
  color: var(--grey718);
  font-size: 14px;
`;

export function ProductHeader({ fullId }: { fullId: string }) {
  const { data: basicItem } = useItemInfo(fullId);
  const { smallImageUrl, name, productTypeName } = (basicItem as Item) || {};
  return (
    <div>
      <PreviewHeader image={smallImageUrl ?? ""}>
        <Spacer height={4} />
        <ProductHeaderTitle>{name}</ProductHeaderTitle>
        <ProductHeaderDescription>{productTypeName}</ProductHeaderDescription>
        <ProductHeaderDescription>
          {formatFullIdWithDots(fullId)}
        </ProductHeaderDescription>
        <Spacer height={4} />
      </PreviewHeader>
      <WidgetDivider />
    </div>
  );
}
