import React from "react";
import { useTranslation } from "@coworker/locales";
import { Text } from "./Text";
import { getRoomsCountTranslation } from "../tools";
import { PaddedContainer } from "../styles/styles";

interface RoomsCountTextProps {
  roomSettings: number | undefined;
  searchCount?: number;
}

export function RoomsCountText({
  roomSettings,
  searchCount,
}: RoomsCountTextProps) {
  const { t } = useTranslation();
  let countText = `${t("viewingString")} -`;

  if (roomSettings !== undefined) {
    const settingsOrSetting = t(getRoomsCountTranslation(roomSettings));
    const searchCounter =
      !searchCount || roomSettings === searchCount
        ? roomSettings
        : `${searchCount}/${roomSettings}`;
    countText = `${t("viewingString")} ${searchCounter} ${settingsOrSetting}`;
  }

  return (
    <PaddedContainer $py="8px" $px="24px">
      <Text text={countText} grey />
    </PaddedContainer>
  );
}
