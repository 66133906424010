import { useQuery } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";

async function fetchItemStockInfo(itemType: string, itemNo: string) {
  const url = `${TASKS_SERVICE_URL}/items/item-stock?itemNo=${itemNo}&itemType=${itemType}`;
  const { result } = await fetchAPI("GET", url);
  return result;
}

export function useItemStock(itemType: string, itemNo: string) {
  return useQuery({
    queryKey: ["item", "stock", itemType, itemNo],
    queryFn: async () => await fetchItemStockInfo(itemType, itemNo),
    staleTime: 86400000, // 1 day, considered fresh for a day.
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!itemNo && !!itemType,
  });
}
