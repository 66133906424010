import React, { Dispatch, ReactNode } from "react";
import FixaRadioButton from "@coworker/apprestructured/src/shared/wrappers/FixaRadioButton/FixaRadioButton";

interface SkapaRadioButtonProps {
  title: ReactNode;
  id: string;
  value: string;
  isChecked: boolean;
  onClick: Dispatch<React.SetStateAction<string>>;
  name: string;
}

export const SkapaRadioButton: React.FC<SkapaRadioButtonProps> = ({
  title,
  id,
  value,
  isChecked,
  onClick,
  name,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "8px",
          fontSize: "14px",
        }}
      >
        <label>{title}</label>
        <FixaRadioButton
          name={name}
          id={id}
          value={value}
          checked={isChecked}
          onChange={() => {
            onClick(value);
          }}
        />
      </div>
    </>
  );
};
