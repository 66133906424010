import React, { useMemo } from "react";
import { Trans } from "@coworker/locales";
import { SkapaPill } from "../../SkapaPill";
import filtersIcon from "@ingka/ssr-icon/paths/filters";
import styled from "styled-components";
import dayjs from "dayjs";
import FilterOptionName from "./FilterOptionName";

const PillWrapper = styled.div`
  display: flex;
  padding: 0.8rem 0rem 0.8rem 0.5rem;
  box-sizing: border-box;
  border-bottom: 1px solid var(--grey150);
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Filters = ({
  onOpen,
  showOptions,
}: {
  onOpen: React.MouseEventHandler;
  showOptions: FilterOptionName[];
}) => {
  const activePeriod =
    localStorage.getItem("shoppingToolsPeriodFilter") ?? "today";
  const customStartDate = localStorage.getItem(
    "shoppingToolsStartDatePeriodFilter"
  );
  const customEndDate = localStorage.getItem(
    "shoppingToolsEndDatePeriodFilter"
  );
  const activeArea =
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore";
  const aciveRound =
    localStorage.getItem("shoppingToolsRoundFilter") ?? "allRounds";

  const options = useMemo(() => {
    return [
      {
        name: FilterOptionName.PERIOD,
        component: (
          <SkapaPill
            key={FilterOptionName.PERIOD}
            style={{
              marginRight: "10px",
            }}
            label={
              activePeriod === "customTitle" ? (
                `${dayjs(customStartDate).format("DD/MM")} - ${dayjs(
                  customEndDate
                ).format("DD/MM")}`
              ) : (
                <Trans>{activePeriod}String</Trans>
              )
            }
            onClick={onOpen}
          />
        ),
      },
      {
        name: FilterOptionName.AREA,
        component: (
          <SkapaPill
            key={FilterOptionName.AREA}
            style={{
              marginRight: "1rem",
            }}
            label={<Trans>{activeArea}String</Trans>}
            onClick={onOpen}
          />
        ),
      },
      {
        name: FilterOptionName.ROUND,
        component: (
          <SkapaPill
            key={FilterOptionName.ROUND}
            style={{
              marginRight: "1rem",
            }}
            label={<Trans>{aciveRound}String</Trans>}
            onClick={onOpen}
          />
        ),
      },
    ]
      .filter(({ name }) => showOptions.includes(name))
      .map(({ component }) => component);
  }, [
    aciveRound,
    activeArea,
    activePeriod,
    customEndDate,
    customStartDate,
    onOpen,
    showOptions,
  ]);
  return (
    <PillWrapper>
      <SkapaPill
        label={<Trans>filtersString</Trans>}
        ssrIcon={filtersIcon}
        style={{
          background: "white",
          border: "1px solid black",
          boxSizing: "border-box",
          marginRight: "10px",
        }}
        onClick={onOpen}
      />
      {options}
    </PillWrapper>
  );
};
