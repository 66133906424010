import { useQuery } from "@tanstack/react-query";
import { getArticleById } from "../services/articles.service";
import { QueryKeys } from "./queryKeys";

export const useArticleById = (articleId: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.Article, articleId],
    queryFn: () => getArticleById(articleId || ""),
    enabled: !!articleId,
  });
};
