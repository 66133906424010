import React from "react";
import { Trans } from "@coworker/locales";
import { DetailSection, DetailSectionLeft, SectionTitle } from "./_common";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { SimpleProductInfo } from "@coworker/components";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";

function SimpleProductRow({ task }) {
  const { push } = useWorkspacesAction();
  const { modalState, toggleModal } = React.useContext(ModalContext);
  const full_id = `${task.product_article_type}${task.product_article_id}`;
  const { data: basicItem } = useItemInfo(task.product_article_id);

  return (
    <DetailSection
      data-testid="productSection"
      onClick={() => {
        if (modalState.isTaskDetailSheetOpen) {
          toggleModal({ type: ModalType.TaskDetail });
        }
        push(`/product/${full_id}`);
      }}
    >
      <DetailSectionLeft>
        <SectionTitle>
          <Trans>product2String</Trans>
        </SectionTitle>
        <SimpleProductInfo
          title={basicItem?.name}
          description={basicItem?.description}
          formattedNumber={basicItem?.noFormatted}
          withArrow
        />
      </DetailSectionLeft>
    </DetailSection>
  );
}

export default SimpleProductRow;
