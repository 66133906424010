import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { Button } from "@coworker/components";
import AddonList from "./AddonList";
import useInsightsAddonLists from "../../hooks/useInsightsAddonLists";
import { useInsightsFetchParams } from "./helpers";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { useTranslation } from "@coworker/locales";

const CenterButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

export const AddonsWithPagination = ({
  path,
  testId,
  dataFetchParams: dataFetchParamsFromParent,
}) => {
  const { location_id, product_id } = useParams();
  const store_id = useStoreId();

  const [params, setParams] = React.useState({
    ...dataFetchParamsFromParent,
  });

  const [dataFetchParams, , isFetchingSavedFilters] = useInsightsFetchParams({
    store_id,
    product_id,
    location_id,
  });

  React.useEffect(() => {
    if (!isFetchingSavedFilters && dataFetchParamsFromParent === undefined) {
      setParams({ ...dataFetchParams, isFetchingSavedFilters });
    }
  }, [isFetchingSavedFilters, dataFetchParams, dataFetchParamsFromParent]);

  const { data, loading, nextPage, total, limit, page, errorLoadingProducts } =
    useInsightsAddonLists(path === "location", {
      ...(dataFetchParamsFromParent ? dataFetchParamsFromParent : params),
    });
  const hasNextPage = !!total && total > page * limit + limit;

  return (
    <div>
      <AddonList
        data={data}
        showOnlyLocation={!!product_id || path === "location"}
        errorLoadingProducts={errorLoadingProducts}
        itemTransformFn={(id) => {
          if (!product_id && !location_id) {
            return `/insights/${path}/${encodeURIComponent(id)}`;
          }
          if (product_id) {
            return `/insights/product/${product_id}/location/${encodeURIComponent(
              id
            )}`;
          }
          return `/insights/product/${id}/location/${encodeURIComponent(
            location_id
          )}`;
        }}
        loading={loading}
        testId={testId}
      />
      {!loading && hasNextPage && (
        <CenterButton>
          <Button
            data-testid="next-page"
            onClick={nextPage}
            text={<Trans>nextPageString</Trans>}
          />
        </CenterButton>
      )}
    </div>
  );
};

export const AddonProductsListPopup = () => {
  const { t } = useTranslation();
  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("insightsAddonArticleListTitle"),
      }}
      noPadding
    >
      <AddonsWithPagination path="product" testId="addonProducts" />
    </FullScreenPopup>
  );
};

export const AddonLocationProductsListPopup = () => {
  const { t } = useTranslation();
  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("insightsAddonArticleListTitle"),
      }}
      noPadding
    >
      <AddonsWithPagination path="product" testId="addonProducts" />
    </FullScreenPopup>
  );
};

export const AddonLocationsListPopup = () => {
  const { t } = useTranslation();
  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("insightsAddonLocationListTitle"),
      }}
      noPadding
    >
      <AddonsWithPagination path="location" testId="addonSalesList" />
    </FullScreenPopup>
  );
};

export const AddonProductLocationsListPopup = () => {
  const { t } = useTranslation();
  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("insightsAddonLocationListTitle"),
      }}
      noPadding
    >
      <AddonsWithPagination path="location" testId="addonSalesList" />
    </FullScreenPopup>
  );
};
