import { useQuery } from "@tanstack/react-query";
import { getArticlesByProductArticleId } from "../services/rooms.service";
import { QueryKeys } from "./queryKeys";

export const useArticlesByProductArticleId = (
  roomId: string | undefined,
  productArticleId: string | undefined
) => {
  return useQuery({
    queryKey: [
      QueryKeys.ArticlesByRoomAndProductArticleId,
      { roomId, productArticleId },
    ],
    queryFn: () =>
      getArticlesByProductArticleId(roomId || "", productArticleId || ""),
    enabled: !!roomId && !!productArticleId,
  });
};
