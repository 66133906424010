import React from "react";
import { AlertResponse, Room } from "../types/room";
import { TaskLinks } from "../types/taskLink";
import { RoomsViewRoomsListMobile } from "./RoomsViewRoomsListMobile";
import { RoomsViewNoSearchResult } from "../Atoms/RoomsViewNoSearchResult";
import { RoomsViewArticleListMobile } from "./RoomsViewArticleListMobile";
import { GreyRow } from "../styles/styles";
import Text from "@ingka/text";
import { Trans } from "@coworker/locales";
import { ArticleWithRoomCount } from "../types/article";

interface RoomsViewSearchListMobileProps {
  rooms: Room[] | undefined;
  query: string;
  taskLinks: TaskLinks;
  alerts: AlertResponse[] | undefined;
  storeId: string;
  articles: ArticleWithRoomCount[] | undefined;
}

export function RoomsViewSearchListMobile({
  rooms,
  query,
  taskLinks,
  alerts,
  storeId,
  articles,
}: RoomsViewSearchListMobileProps) {
  return (
    <>
      {!!rooms && rooms.length === 0 && !!articles && articles.length === 0 && (
        <RoomsViewNoSearchResult query={query ?? ""} />
      )}

      {!!rooms && rooms.length > 0 && (
        <GreyRow>
          <Text>
            <b>
              <Trans count={rooms?.length ?? 0}>countRoomSettingsString</Trans>
            </b>
          </Text>
        </GreyRow>
      )}
      <RoomsViewRoomsListMobile
        rooms={rooms}
        taskLinks={taskLinks}
        alerts={alerts}
      />

      {!!articles && articles.length > 0 && (
        <GreyRow>
          <Text>
            <b>
              <Trans count={articles?.length ?? 0}>countArticlesString</Trans>
            </b>
          </Text>
        </GreyRow>
      )}
      <RoomsViewArticleListMobile
        articles={articles}
        taskLinks={taskLinks}
        storeId={storeId}
      />
    </>
  );
}
