import { TaskTabsTypes } from "../../shared/constants/taskTabsTypes";
import { useFilteredSortedTasks } from "../../tasks/hooks/useFilteredSortedTasks";
import { useTranslation } from "@coworker/locales";
import { TasksPreviewSection } from "../types/taskPreviewType";
import { mapTaskToTaskPreview } from "../helpers/taskPreviewHelper";
import { useItemsInfo } from "../../shared/hooks/item/useItemsInfo";
import { Item } from "../../shared/types/item";

const getMappedTasksForPreview = (
  itemsInfo: Item[] | undefined,
  tasksList: any,
  limit: number,
  t: any
) => {
  return {
    count: tasksList?.sortedTasks?.length,
    isLoading: tasksList?.isLoading,
    mappedTasksList: tasksList?.sortedTasks.slice(0, limit).map((task: any) => {
      const item = itemsInfo?.find(
        (item) => item.no === task.product_article_id
      );
      return mapTaskToTaskPreview(item, task, t);
    }),
  } as TasksPreviewSection;
};

export const useGetTasksForPreview = () => {
  const { t } = useTranslation();
  const limit = 3;
  const openTasksList = useFilteredSortedTasks(TaskTabsTypes.OPEN);
  const myTasksList = useFilteredSortedTasks(TaskTabsTypes.MY);

  const topOpenTasks = openTasksList?.sortedTasks.slice(0, limit);
  const topMyTasks = myTasksList?.sortedTasks.slice(0, limit);

  const articleIdsMissingImages = topOpenTasks
    .concat(topMyTasks)
    ?.filter((task: any) => !task?.item?.image_small)
    .map((task: any) => task.product_article_id as string)
    .filter(Boolean);

  const { data: itemsInfo } = useItemsInfo(articleIdsMissingImages);

  let allTasksPreview: Record<string, TasksPreviewSection> = {};
  allTasksPreview[TaskTabsTypes.OPEN] = getMappedTasksForPreview(
    itemsInfo,
    openTasksList,
    limit,
    t
  );

  allTasksPreview[TaskTabsTypes.MY] = getMappedTasksForPreview(
    itemsInfo,
    myTasksList,
    limit,
    t
  );
  return allTasksPreview;
};
