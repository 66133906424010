import { TestBuy } from "@coworker/types";
import { TaskState } from "@coworker/types/lib/tasks/constants";
import dayjs from "dayjs";
import { useStoreId, useUserId } from "../../../../core/auth/useLoggedInUser";
import { useQuery } from "@tanstack/react-query";
import {
  fetchTestbuy,
  fetchTestbuys,
  fetchTestbuyTasks,
  fetchCountriesWith4A1KData,
} from "./testbuy.api";

interface UseTesbuys {
  loading: boolean;
  testbuys: TestBuy[] | undefined;
}

interface TaskData {
  articleId: string;
  taskId: string;
  state: TaskState;
  finishedAt?: number | undefined;
}

export const useTestbuy = (id: string | undefined) => {
  const {
    data: testbuy,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ["get-testbuy-by-id", id],
    queryFn: async () => {
      return await fetchTestbuy(id);
    },
  });

  return { testbuy, loading, refetch };
};

export const useTestbuys = (
  area: string | undefined,
  sinceTs: number | undefined,
  untilTs: number | undefined,
  divisionId: string | undefined
) => {
  const storeId = useStoreId();
  const uid = useUserId();
  const since = dayjs(sinceTs).format("YYYY-MM-DD");
  const until = dayjs(untilTs).format("YYYY-MM-DD");

  const isValidDate = (date: string) =>
    dayjs(date, "YYYY-MM-DD", true).isValid();

  const { data: testbuy, isLoading: loading } = useQuery({
    queryKey: ["get-testbuys", uid, area, storeId, since, until, divisionId],
    queryFn: async () => {
      if (isValidDate(since) && isValidDate(until)) {
        return await fetchTestbuys(
          uid,
          area,
          storeId,
          since,
          until,
          divisionId
        );
      } else {
        return [];
      }
    },
    enabled: isValidDate(since) && isValidDate(until),
  });

  return {
    loading,
    testbuys: testbuy,
  } as UseTesbuys;
};

export const useTestbuyTasks = (id: string) => {
  const { data: tasks } = useQuery({
    queryKey: ["get-testbuy-tasks", id],
    queryFn: async () => {
      return await fetchTestbuyTasks(id);
    },
  });
  return tasks as TaskData[];
};

export const use4A1KData = () => {
  const { data: countries } = useQuery({
    queryKey: ["get-countries-with-4A1K-data"],
    queryFn: async () => {
      return await fetchCountriesWith4A1KData();
    },
  });
  return countries as string[];
};
