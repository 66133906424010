import { firebase, functions } from "@coworker/common/config/firebase";
import promised from "@coworker/functions/src/shared/promised";
import { fetchFromCoreService } from "./core.service";
import { rerouteToTasksService } from "./tasks.service.helper";
import useCallFirebaseFunction from "./useCallFirebaseFunction";
import { getTokenAsync } from "./getAuthToken";

const INTERNAL_PROXY_FUNCTION = "internalProxy";

export const MFS_LOCATIONS = "mfsGroupedLocations";
export const PRODUCT_BASICS = "productBasics";
export const PRODUCT_MAPPER = "productMapper";
export const GET_BIG_PRODUCT_IMAGE = "getBigProductImage";
export const GET_PRICES_FOR_SHORT_IDS = "getPricesForShortIds";
export const SAVE_FEEDBACK = "saveFeedback";
export const GET_HASHED_USER_ID = "getHashedUserId";
export const EXCEL_EXPORT = "excelExport";
export const MFAQ_ALL_QUESTION = "mfaq_getAllQuestions";
export const MFAQ_GET_QUESTION = "mfaq_getQuestion";
export const MFAQ_GET_TOPQUESTIONS = "mfaq_getTopQuestions";
export const MFAQ_GET_OTHERQUESTIONS = "mfaq_getOtherQuestions";
export const MFAQ_SEARCH_QUESTION = "mfaq_searchQuestions";
export const MFAQ_CREATE_QUESTION = "mfaq_createQuestion";
export const MFAQ_CREATE_VOTE = "mfaq_createVote";
export const MFAQ_AGGREGATED_VOTES = "mfaq_getUpvotesByInterval";
export const MFAQ_CREATE_TASK = "mfaq_createTaskToResolveQuestion";
export const MFAQ_AGGREGATED_TASKS = "mfaq_getTasksByInterval";
export const MFAQ_EDIT_QUESTION = "mfaq_editQuestionText";
export const MFAQ_MERGE_QUESTION = "mfaq_mergeQuestions";
export const NEARBY_PRODUCTS = "nearbyProducts";
export const UPDATE_TASK = "updateTaskInternal";
export const UPDATE_REPEATABLE_TASK = "updateRepeatableTaskInternal";
export const TESTBUY_GET_ARTICLE_WITH_CRITERIA =
  "testbuy_getArticleWithCriteria";
export const TESTBUY_UPDATE_CRITERION = "testbuy_updateCriterion";
export const TESTBUY_UPDATE_CUSTOM_NAME = "testbuy_updateCustomName";
export const TESTBUY_GET_ARTICLES_WITH_CRITERIA =
  "testbuy_getArticlesWithCriteria";
export const TESTBUY_NEW = "testbuy_new";
export const TESTBUY_GET_RANDOM_ARTICLES = "testbuy_getRandomArticles";
export const TESTBUY_ADD_FOLLOW_UP_TASK_ID = "testbuy_addFollowUpTaskId";
export const TESTBUY_SET_COMPLETED = "testbuy_setCompleted";
export const TESTBUY_GET_BY_ID = "testbuy_getById";
export const TESTBUY_GETALL = "testbuy_getAll";
export const TESTBUY_GET_TASKS_DATA = "testbuy_getTasksData";
export const TESTBUY_REPLACE_ARTICLE = "testbuy_replaceArticle";
export const TESTBUY_REASSIGN = "testbuy_reassignTo";
export const TESTBUY_CLOSE = "testbuy_close";
export const TESTBUY_ACTIVE_ON_USER = "testbuy_activeOnUser";
export const TESTBUY_ARTICLES_NUMBER_FOR_AREA =
  "testbuy_getNumberOfArticlesForArea";
export const TESTBUY_CUSTOM_ARTICLE = "testbuy_getCustomArticle";
export const ACES_AND_KING_COUNTRIES = "testbuy_4A1KCountries";
export const MFS_SEARCH = "mfs_search";
export const TASK_TOGGLE_CHECKLIST_ITEM = "task_ToggleTestbuyChecklist";

// testing divisions
export const GET_DIVISIONS = "testbuy_getDivisions";

/**
 * @param {string} internalFunction
 * @param {*} body
 * @returns {Promise<import("@coworker/types").InternalProxyResponse>}
 */
export function callInternal(internalFunction, body) {
  return functions.httpsCallable(INTERNAL_PROXY_FUNCTION)({
    internalFunction,
    body,
  });
}

/**
 * @param {string} uid A normal user id
 * @param {number} timeout If positive, then do the hash request with a timeout.
 */
export async function getHashedUserId(uid, timeout = 0) {
  if (process.env.JEST_WORKER_ID) return "jest-so-fake-hash-token";
  const promise = callInternal(GET_HASHED_USER_ID, { uid });
  const response = await (timeout
    ? promised.withTimeout(promise, timeout).catch(() => "hashing-timed-out")
    : promise);
  return response?.data || "unknown";
}

/**
 * @param {string} which
 */
export async function callSameOriginInternalApi(which, postdata = {}) {
  // It seems we can only support one region per rewrite, if it needs to be defined in firebase.json
  if (
    process.env["REACT_APP_ENV"] === "prod" &&
    process.env["REACT_APP_PROJECT_ID"].includes("ikea-coworker-app-") // Only EU and in deployed state supported, for now.
  ) {
    return callInternalApi(which, postdata);
  }

  const url = `${process.env.PUBLIC_URL || ""}/v0/api/${which}`;
  const _clientVersion =
    require("@coworker/functions/src/version.json").version;
  const token = await getTokenAsync(false);
  if (!token) {
    console.error("No token for callSameOriginInternalApi", { which });
    return;
  }
  const { uid } = firebase.auth().currentUser;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      uid,
      ...postdata,
      _clientVersion,
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json(); // Needs await to fetch and parse the body.
  return { data }; // return as { data } to match Firebase function call response.
}

const NOT_YET_ON_V1 = [
  "global_config",
  "metadata/deployed_version",
  "my/store",
  "my/events",
  "my/unreads",
  "user",
  "teams",
];

const NOT_YET_ON_CORE_SERVICE = [
  "global_config",
  "metadata/deployed_version",
  "my/events",
  "my/unreads",
];

const ALLOWED_ROUTES_FOR_FORWARD_TO_TASKS_SERVICE = [
  "update",
  "create",
  "delete",
];

export async function callInternalApi(
  method,
  postdata,
  forceCallTasksService = false,
  ignoreForwardToTasksService = false,
  serviceToCall = "TASKS_SERVICE"
) {
  const forwardToTasksService =
    window.forwardToTasksService &&
    !ignoreForwardToTasksService &&
    ALLOWED_ROUTES_FOR_FORWARD_TO_TASKS_SERVICE.find((path) =>
      method.includes(path)
    );
  if (
    (window.callTasksService ||
      forceCallTasksService ||
      forwardToTasksService) &&
    serviceToCall === "TASKS_SERVICE"
  ) {
    if (NOT_YET_ON_V1.find((path) => method.includes(path))) {
      console.warn("NOT_YET_ON_V1", { NOT_YET_ON_V1, method });
    } else {
      const result = await rerouteToTasksService(method, postdata);
      if (!forwardToTasksService) return result;
    }
  } else if (serviceToCall === "CORE_SERVICE") {
    if (NOT_YET_ON_CORE_SERVICE.find((path) => method.includes(path))) {
      console.warn("NOT_YET_ON_CORE_SERVICE", {
        NOT_YET_ON_CORE_SERVICE,
        method,
      });
    } else {
      const result = await fetchFromCoreService(method, postdata);
      return result;
    }
  }

  // TODO: Remove this once the Tasks Service is the only source.
  const url = `${INTERNAL_PROXY_FUNCTION}/api/${method}`;
  return await functions.httpsCallable(url)({
    ...(postdata || {}),
    _clientVersion: require("@coworker/functions/src/version.json").version,
  });
}

export default function useCallInternal(internalFunction, body, options = {}) {
  return useCallFirebaseFunction(
    INTERNAL_PROXY_FUNCTION,
    { ...body, internalFunction },
    { ...options, blockRequest: options.blockRequest || !internalFunction }
  );
}

function tooOld(at, maxStale) {
  return at < Date.now() - maxStale;
}

const cache = {};
const fetching = {};

// TODO maxStale = Inf if offline
export function getLatestCacheValue(method) {
  const [last] = cache[method] || [];
  return last;
}

// See also TS-ified version getCachedUsersOrContacts in hooks/useStoreContacts.ts
export async function getCachedInternalApi(
  method,
  callback,
  maxStale = 10_000,
  forceCallTasksService = false,
  serviceToCall,
  postData = {}
) {
  if (!method) return;
  const [last, at = 0] = cache[method] || [];
  if (last) callback(last); // TODO: pretend like cache is missing if extremely old, refresh async and return stale if medium old.

  // Refresh if stale
  if (tooOld(at, maxStale) && !fetching[method]) {
    fetching[method] = callInternalApi(
      method,
      postData,
      forceCallTasksService,
      false,
      serviceToCall
    );
  }

  if (fetching[method]) {
    const response = await fetching[method];
    fetching[method] = undefined;
    if (response) {
      cache[method] = [response, Date.now()];
      callback(response);
    }
  }
}
