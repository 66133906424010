import React from "react";
import { Trans } from "@coworker/locales";
import { useInputPopup } from "../InputPopup";
import { updateTaskWithImages } from "../../services/task.service";
import { useToastNotification } from "@coworker/components";
import {
  useWorkspacesState,
  useWorkspacesAction,
} from "../../hooks/useWorkspaces";
import { useCreateTask } from "../../hooks/TaskActions/useCreateTask";
import { useCompleteWithChanges } from "../../hooks/TaskActions/useCompleteWithChanges";
import { usePrepareForm } from "../../hooks/TaskActions/usePrepareForm";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import { Task } from "@coworker/types/lib/tasks/base";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";

type FormMode = "create" | "edit" | "complete";

interface TaskFormProps {
  config: any;
  task_id: string;
  formType: string;
  formMode: FormMode;
  baseTask: Task;
}

export function useTaskForm({
  config,
  task_id,
  formType,
  formMode,
  baseTask,
}: TaskFormProps) {
  const { push, pop } = useWorkspacesAction();
  const { toggleModal } = React.useContext(ModalContext);
  const { navigationState } = useWorkspacesState();
  const useBeforeCreate = config.useBeforeCreate || (() => () => true);
  const usePostCreate = config.usePostCreate || (() => () => true);
  const [creating, setCreating] = React.useState(false);
  const { closePopup } = useInputPopup();
  const { showToast } = useToastNotification();
  const useReactToChanges = config.useReactToChanges || (() => null);
  const isRepeatableTask = formType === "planned";
  const dbCollection = isRepeatableTask ? "repeatable_tasks" : "tasks";
  const taskPath = `${dbCollection}/${task_id}`;
  const isCreate = formMode === "create";
  const isEdit = formMode === "edit";
  const isComplete = formMode === "complete";
  const storeId = useStoreId();
  const {
    preparedForm,
    images,
    isFormValid,
    editFields,
    oldImages,
    task,
    form,
  } = usePrepareForm({
    baseTask,
    task_id,
    config,
    formType,
    isCreate,
  });
  useReactToChanges(task_id, preparedForm, editFields);

  const beforeCreate = useBeforeCreate({ form: preparedForm });
  const postCreate = usePostCreate({ form: preparedForm });

  const { call: createTask } = useCreateTask();
  const createTaskAction = async () => {
    setCreating(true);

    if (!(await beforeCreate())) {
      return;
    }
    const newTaskId = await createTask(preparedForm, images);

    if (formType === "product_quality") {
      showToast(
        config.createCurtainMessage || <Trans>curtainAddedString</Trans>
      );
    } else {
      showToast(
        config.createCurtainMessage || <Trans>curtainAddedString</Trans>,
        <Trans>curtainViewString</Trans>,
        () => {
          toggleModal({
            type: ModalType.TaskDetail,
            payload: { taskId: newTaskId },
          });
          closePopup();
        }
      );
    }

    if (postCreate()) {
      pop();
    }
  };

  const formFinished = React.useCallback(() => {
    if (navigationState.back) {
      push(navigationState.back);
    } else {
      if (isEdit || isComplete) {
        closePopup();
      } else {
        pop();
      }
    }
  }, [navigationState, push, isEdit, isComplete, closePopup, pop]);

  const updateTaskAction = React.useCallback(async () => {
    await updateTaskWithImages(
      preparedForm,
      task_id,
      oldImages,
      images,
      storeId
    );

    showToast(
      <Trans>curtainUpdateString</Trans>,
      <Trans>curtainViewString</Trans>,
      () => {
        toggleModal({
          type: ModalType.TaskDetail,
          payload: { taskId: task_id },
        });
      }
    );

    formFinished();
  }, [
    images,
    task_id,
    oldImages,
    preparedForm,
    showToast,
    formFinished,
    storeId,
    toggleModal,
  ]);

  const { call: completeWithChanges } = useCompleteWithChanges(
    preparedForm,
    task_id
  );
  const completeWithChangesAction = React.useCallback(async () => {
    const data = await completeWithChanges();
    if (!data) return;
    formFinished();
  }, [completeWithChanges, formFinished]);

  return {
    isFormValid,
    creating,
    createTaskAction,
    updateTaskAction,
    completeWithChangesAction,
    preparedForm,
    form,
    editFields,
    taskPath,
    formMode,
    task,
    images,
  };
}
