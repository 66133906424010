import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createRoom } from "../services/rooms.service";
import { QueryKeys } from "./queryKeys";

export const useCreateRoom = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createRoom,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: [QueryKeys.Rooms] });
    },
  });
};
