import React from "react";
import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import { Groups } from "../../types/groups";
import { ModalTypes } from "../../types/views";
import { useTranslation } from "@coworker/locales";
import { FullWidthButton } from "../../../SkapaButton";
import { Articles } from "../../types/article";
import { InputPopupContainer } from "../../styles/styles";
import ListView, { ListViewItem } from "@ingka/list-view";
import { addUniqueArticlesToGroup } from "./GroupModal.helper";
import { logEvent } from "../../../../helpers/tracker";
import { useToastNotification } from "@coworker/components";

interface AddToGroupModalProps {
  modalVisibility: boolean;
  groups: Groups;
  selectedArticles: Articles;
  onTriggerNewModal: (modalType: ModalTypes) => void;
}

export function AddToGroupModal({
  modalVisibility,
  groups,
  selectedArticles,
  onTriggerNewModal,
}: AddToGroupModalProps) {
  const { showToast } = useToastNotification();
  const [selectedGroupId, setSelectedGroupId] = React.useState<string>("");

  const { t } = useTranslation();

  return (
    <Modal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onTriggerNewModal(ModalTypes.KEEP_SELECTION);
      }}
    >
      <Sheets
        labelledById="add-to-group-modal"
        size={"small"}
        header={<ModalHeader title={`${t("selectGroupString")}`} />}
        footer={
          <ModalFooter>
            <FullWidthButton
              disabled={!selectedGroupId}
              text={t("confirmString")}
              type={"primary"}
              onClick={async () => {
                await addUniqueArticlesToGroup(
                  selectedArticles,
                  selectedGroupId
                );

                setSelectedGroupId("");
                onTriggerNewModal(ModalTypes.DEFAULT_VIEW);
                logEvent("ce:articles_selected_addtogroup");
                showToast(t("articlesAddedString"));
              }}
            />
          </ModalFooter>
        }
      >
        <InputPopupContainer>
          <ListView id={"group-selections"} size={"small"}>
            {groups.map((group) => (
              <ListViewItem
                key={group.id}
                onChange={() => {
                  setSelectedGroupId(group.id);
                }}
                title={group.name}
                control={"radiobutton"}
              />
            ))}
          </ListView>
        </InputPopupContainer>
      </Sheets>
    </Modal>
  );
}
