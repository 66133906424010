import { Articles } from "../types/article";
import { Room, Rooms } from "../types/room";

export const EmptyRoom: Room = {
  created_at: "",
  updated_at: "",
  id: "",
  store_id: "",
  name: "",
  style_group: "",
  hf_ltp: "",
  pricing: "",
  active: "ACTIVE",
  nbr_articles: "",
  main_article_id_1: "",
  main_article_id_2: "",
  main_article_name_1: "",
  main_article_name_2: "",
  living_situation: "",
  laha: "",
  living_conditions: "",
  hfb: "",
  backup_article_id_1: "",
  backup_article_id_2: "",
  backup_article_name_1: "",
  backup_article_name_2: "",
  media_story: "",
};

export const checkRoomName = (room: Room, rooms: Rooms) => {
  const found = rooms.find(
    (roomFromList) =>
      room.name === roomFromList.name && room.id !== roomFromList.id
  );

  if (found) return true;

  return false;
};

export const checkEmptyRoomName = (room: Room) => {
  const isRoomNameEmpty = !room.name;
  if (isRoomNameEmpty) return true;

  return false;
};

export const validationMessage = (
  room: Room,
  rooms: Rooms,
  t: (key: string, options?: any) => string
) => {
  if (checkRoomName(room, rooms)) {
    return t(`roomWithNameAlreadyExistsString`, {
      roomName: room.name,
    });
  } else if (checkEmptyRoomName(room)) {
    return t("roomNameCannotBeEmptyString");
  }
  return "";
};

export const getArticleName = (articles: Articles, articleId: string) => {
  const article = articles.find((article) => article.id === articleId);
  return article ? article.itemName : "";
};

export const getFullArticleName = (articles: Articles, articleId: string) => {
  const article = articles.find((article) => article.id === articleId);
  return article
    ? `${article.productArticleId}: ${article.itemName}, ${article.itemType}`
    : "";
};
