import React from "react";
import styled from "styled-components";
import { StoredImage } from "../../StoredImage";
import { Trans } from "@coworker/locales";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { ImageScroller } from "../../../core/components/ImageScroller";
import { ImageSkeleton } from "@coworker/reusable/components/ImageSkeleton";
import { useRemoteImages } from "../../../hooks/useRemoteImages";
import { parseProduct } from "../../../services/products.service";
import BasicInfo from "./basicinfo";
import { Spacer } from "../../Layout/styles";
import { useInputPopup } from "../../InputPopup";
import { useOwnImages } from "../../../core/hooks/useImages";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";

const CoverImage = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`;

const CorrectlyOrientedImage = styled.img`
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  height: 155px;
  @media (max-height: 500px) {
    padding-top: 30px;
    height: 100px;
  }
  width: 100%;
  object-fit: contain;
  cursor: pointer;
  image-orientation: from-image;
`;

const HeaderImageContainer = styled.div`
  @media (min-height: 500px) {
    min-height: 260px;
  }
`;
const wrapped = (children) => <HeaderImageContainer children={children} />;

const ImageSkeletonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 105px;
`;

// TODO: async breaks this component and removing it makes it really hard to resolve with nested .then since it's gonna turn into infinite calls
function HeaderImage({ full_id, showSkeleton, ownImages, showFirebaseImages }) {
  const { getInput } = useInputPopup();
  const [imgError, setImgError] = React.useState(false);

  const scrollables = useRemoteImages(ownImages);
  const hasScrollables = !!scrollables?.length;
  const hasOwnImages = !!ownImages?.length;

  const [shortId] = parseProduct(full_id);
  const { data: itemInfo } = useItemInfo(!hasOwnImages && shortId);

  const itemImage = itemInfo?.imageUrl;

  const handleClick = () => {
    getInput("imageGallery", {
      data:
        showFirebaseImages && hasOwnImages ? ownImages : [{ src: itemImage }],
    });
  };

  if (!hasOwnImages && !itemImage) return null;

  if (imgError || showSkeleton)
    return wrapped(
      <ImageSkeletonWrapper>
        <ImageSkeleton />
      </ImageSkeletonWrapper>
    );

  if (hasScrollables) {
    return wrapped(
      <div onMouseUp={handleClick}>
        <ImageScroller
          images={scrollables}
          indicator={"number"}
          ofString={<Trans>ofString</Trans>}
          large
        />
      </div>
    );
  }

  return wrapped(
    <CoverImage onClick={handleClick} data-testid="imageScroller">
      {showFirebaseImages ? (
        <StoredImage mediumLoader image={ownImages[0]} taskHeader />
      ) : (
        itemImage && (
          <CorrectlyOrientedImage
            src={itemImage}
            onError={() => {
              setImgError(true);
            }}
          />
        )
      )}
    </CoverImage>
  );
}

export default function TaskHeaderWithImages({ task, taskId }) {
  const ownImages = useOwnImages(taskId);
  const customOrPlanned = [
    taskTypeOptions.CUSTOM,
    taskTypeOptions.PLANNED,
  ].includes(task.task_type);

  const showSkeleton =
    [taskTypeOptions.ADDON, taskTypeOptions.PRODUCT_ISSUE].includes(
      task.task_type
    ) && ownImages === undefined;

  const hideImageContainer =
    (customOrPlanned && !ownImages?.length) ||
    task.task_type === taskTypeOptions.MFAQ_FOLLOW_UP;
  const showFirebaseImages = !!ownImages?.length;
  return (
    <>
      {hideImageContainer ? (
        <Spacer height={90} />
      ) : (
        <>
          <HeaderImage
            full_id={`${task.product_article_type}${task.product_article_id}`}
            {...{ showSkeleton, ownImages, showFirebaseImages }}
          />
        </>
      )}

      <BasicInfo task={task} />
    </>
  );
}
