import { callInternalApi } from "@coworker/app/src/hooks/API/useCallInternal";
import { useQuery } from "@tanstack/react-query";
import { useStoreId } from "@coworker/app/src/core/auth/useLoggedInUser";

async function fetchRelatedTasksForProduct(shortId: string, storeId: string) {
  const result = await callInternalApi(`tasks_for_product/${shortId}`, {
    store_id: storeId,
  });
  return result?.data;
}

export function useRelatedTasksForProduct(shortId: string) {
  const storeId = useStoreId();
  return useQuery({
    enabled: !!shortId,
    queryKey: ["tasks", "product", storeId, shortId],
    queryFn: async () => await fetchRelatedTasksForProduct(shortId, storeId),
    staleTime: 0,
  });
}
