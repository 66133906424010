import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { TaskCard } from "./TaskCard";
import styles from "./swipeable-task-card.module.css";
import { MoreButton } from "./MoreButton";
import { PickupTaskButton } from "./PickupTaskButton";
import { CompleteButtons } from "./CompleteButtons";
import { TaskType } from "../../enums/taskTypes";
import { useLocation } from "react-router-dom";
import { Task } from "@coworker/types/lib/tasks/base";
import { CardMode } from "./types";
import { Item } from "../../../shared/types/item";

interface SwipeableTaskCardProps {
  task: Task;
  item: Item;
  multiSelect: boolean;
  isMyTasks: boolean;
  type: string;
  cardMode?: CardMode;
}

export const SwipeableTaskCard = ({
  task,
  item,
  multiSelect,
  isMyTasks = false,
  type,
  cardMode = "default",
}: SwipeableTaskCardProps) => {
  const [swipeDirection, setSwipeDirection] = useState<"left" | "right" | "">(
    ""
  );
  const [isSwiping, setIsSwiping] = useState(false);
  const location = useLocation();
  const isAddonTask = task.task_type === TaskType.ADDON;
  const isRelatedTasks = location.pathname.includes("related");

  let swipeTimeout: NodeJS.Timeout;

  const handlers = useSwipeable({
    onSwiped: () => {
      if (!multiSelect && !isRelatedTasks) {
        setIsSwiping(true);
        clearTimeout(swipeTimeout);
        swipeTimeout = setTimeout(() => {
          setIsSwiping(false);
        }, 100);
      }
    },
    onSwipedLeft: () => {
      if (!multiSelect && !isRelatedTasks) {
        if (swipeDirection === "right") {
          setSwipeDirection("");
        } else {
          setSwipeDirection("left");
        }
      }
    },
    onSwipedRight: () => {
      if (!multiSelect && !isRelatedTasks) {
        if (swipeDirection === "left") {
          setSwipeDirection("");
        } else {
          setSwipeDirection("right");
        }
      }
    },
    trackMouse: true,
  });

  return (
    <div className={styles["swipeable-task-card"]} {...handlers}>
      <div
        className={
          swipeDirection === "left"
            ? styles["swipe-left"]
            : swipeDirection === "right"
            ? isMyTasks && isAddonTask
              ? styles["swipe-big-right"]
              : styles["swipe-right"]
            : styles["swipe-none"]
        }
      >
        <TaskCard
          task={task}
          item={item}
          multiSelect={multiSelect}
          isSwiping={isSwiping}
          type={type}
          cardMode={cardMode}
        />
      </div>
      <MoreButton
        swipeDirection={swipeDirection}
        setSwipeDirection={setSwipeDirection}
        task={task}
      />
      {isMyTasks ? (
        <CompleteButtons
          swipeDirection={swipeDirection}
          setSwipeDirection={setSwipeDirection}
          isAddonTask={isAddonTask}
          task={task}
        />
      ) : (
        <PickupTaskButton
          swipeDirection={swipeDirection}
          setSwipeDirection={setSwipeDirection}
          task={task}
        />
      )}
    </div>
  );
};
